import React, { useState } from "react";
import {
  Link,
  Typography,
  Button,
  IconButton,
  FormGroup,
  FormControlLabel,
  Checkbox,
  Input,
  TextField,
  InputAdornment,
  Select,
  Grid,
  Box,
  Modal,
  MenuItem,
  Tooltip as TooltipData,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import moment from "moment";
import InfiniteScroll from "react-infinite-scroll-component";
import Loader from "../../Loader";
import CandidateService from "../../../Services/Enterprise/Candidate.service";
import rehireIcon from "../../../assets/images/rehire-icon.svg";
import { ReactComponent as LocationIcon } from "../../../assets/images/location-new-icon-gray.svg";
import DateFnsUtils from "@date-io/date-fns";
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import { buildStyles, CircularProgressbar } from "react-circular-progressbar";
import ConfirmDialog from "../../../Components/shared/ConfirmDialog";
import InfoIcon from "@mui/icons-material/Info";
import noCandidate from "../../../assets/images/NoJobsFound.svg";
import { formatDate, getDurationPeriodForDropDown, getDurationValue, getFormattedLocation } from "../../../helpers/helper-data";
import MatchingScoreBreakdownModal from "./MatchingScoreBreakdownModal";
import { functiondisable, functionenable } from "../../../helpers/helper";
import * as Constants from "../../../helpers/constants";
import docIcon from "../../../assets/images/extension-icon/doc-icon.png";
import zipIcon from "../../../assets/images/extension-icon/zip.png";
import Resumepdf from "../../../assets/images/extension-icon/pdf-icon.png";
import DeleteSignedDoc from "../../../assets/images/extension-icon/delete-signed-doc.svg";
import { danger, success } from "../../../helpers/message-box";
import ApiCalls from "../../../api/customAxios";
import { useNavigate, useParams } from "react-router";
import { ReactComponent as PriorityIcon } from "../../../assets/images/priority-pool-icon.svg";
import { ReactComponent as PriorityIconGreen } from "../../../assets/images/starRename.svg";
import { ReactComponent as PrivateIcon } from "../../../assets/images/private-pool-icon.svg";
import { checkIsImage } from "../../../helpers/helper-data";
import playIcon from "../../../assets/images/playIcon.svg";
import { ReactComponent as JobReferenceNumberIcon } from "../../../assets/images/jobRefNumberIcon.svg";
import copy from "copy-to-clipboard";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import { Tooltip, TooltipTrigger, TooltipContent } from 'react-tippy';
import ClearIcon from "@mui/icons-material/Clear";
import ExclusiveWorkPermissionModal from "../../../Components/shared/ExclusiveWorkPermissionModal";
import { useSelector } from "react-redux";
import axios from "axios";
import OfferLetterRehire from "../OfferLetter/GenerateOfferRehire";
import { useCallback } from "react";
import { getCredentialingtoken } from "../../../helpers/credentialingToken";
import ShortlistCandidateDrawer from "../../EnterprisePortal/Project/ShortlistCandidateDrawer";
const label = { inputProps: { "aria-label": "Checkbox demo" } };

const Rehire = React.memo(function Rehire(props) {
    const appOrigin = useSelector((state) => state.location.origin);
    const params = useParams();
    let navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(false);
    const [pageNumber, setPageNumber] = useState(0);
    const [openHireAndRehire, setOpenHireAndRehire] = useState(false);
    const handleOpenHireAndRehire = () => {
      document.body.style.overflow = "hidden";
      document.documentElement.style.overflow = "hidden";
        setOpenHireAndRehire(true);
        setPageNumber(0);
    };
    const handleCloseHireAndRehire = (e) => {
        e?.stopPropagation();
        document.body.style.overflow = "";
        document.documentElement.style.overflow = "";
        setOpenHireAndRehire(false);
        setPageNumber(0);
    };
  const [jobs, setJobs] = useState({ allChecked: false, list: [] });
  const [values, setValues] = useState(moment().add(2, "d").toDate());
  const [jobStartDate, setJobStartDate] = useState(null);
  const [jobCompletionDate, setJobCompletionDate] = useState(null);
  const [payRate, setPayRate] = useState("");
  const [endDateErr, setEndDateErr] = useState(null);
  const [payRateErr, setPayRateErr] = useState(null);
  const [hideStartDateClearIcon, setHideStartDateClearIcon] = useState(true);
  const [hideEndDateClearIcon, setHideEndDateClearIcon] = useState(true);
  const [documentFile, setdocumentFile] = useState();
  const [documentIndex, setdocumentIndex] = useState();
  const [currency, setCurrency] = useState("USD");
  const [viewOfferDrawer, setViewOfferDrawer] = useState(false);
  const [openDocumentIndex, setOpenDocumentIndex] = useState(false);
  const [openOffer, setOpenOffer] = useState(false);
  const [openOfferForSingle, setOpenOfferForSingle] = useState(false);
  const [loadMore, setLoadMore] = useState(true);
  const [videoSrc, setVideoSrc] = useState(null);
  const [candidateName, setCandidateName] = useState("");
  const [openVideoPreview, setOpenVideoPreview] = useState(false);
  const [openExclusiveWorkPermissionModal, setOpenExclusiveWorkPermissionModal] = useState(false);
  const [selectedExclusiveJobDetails, setSelectedExclusiveJobDetails] = useState(null);
  const [sendOfferEmailChecked, setSendOfferEmailChecked] = useState(true);
  const [selectedJobsIdOffer, setSelectedJobsIdOffer] = useState(null);
  const handleEndDate = (date, duration) => {
    let valueOFDuration = getDurationValue(duration);
    let durationTime =  getDurationPeriodForDropDown(duration);
      const updatedDate = moment(date).add(valueOFDuration, durationTime.toLowerCase()).subtract(1, 'day').toDate();
      return updatedDate;
  };
  const handleOpenOffer = () => {
    handleCloseHireAndRehire();
    setdocumentFile('');
    setValues(moment().add(2, "d").toDate());
    setEndDateErr(null);
    setPayRateErr(null);
    let selectedJobs = jobs.list.filter((o) => o.isChecked);
    if (selectedJobs.length === 1) {
      if (selectedJobs[0].allowedToWorkInOtherJobs === "N" && props.user?.occupiedCandidate) {
        setSelectedExclusiveJobDetails(selectedJobs[0]);
        setOpenExclusiveWorkPermissionModal(true);
        return;
      } else {
        setSelectedJobsIdOffer(selectedJobs[0]);
        setPayRate(selectedJobs[0]?.hourlyRate);
        setCurrency(selectedJobs[0]?.currency || "USD");
    setJobStartDate(selectedJobs[0]?.jobStartDate);
    setJobCompletionDate(formatDate(
      new Date(
        JSON.parse(
          JSON.stringify(selectedJobs[0]?.jobEndDate).replace("23:59:59", "00:00:00")
        )
      )
    ));
    setOpenOffer(true);
      }
    } else {
    setOpenOffer(true);
    }
  };
  const handleCloseOffer = () => {
    setOpenOffer(false);
    setdocumentFile('');
    setValues(moment().add(2, "d").toDate());
    setJobStartDate(null);
    setJobCompletionDate(null);
    setPayRate("");
    setEndDateErr(null);
    setSelectedJobsIdOffer(null);
    setPayRateErr(null);
  };
  const candidateInfo = props.user;
  const hireAndRehire = (e) => {
    e.stopPropagation();
    props.setIsLoading ? props.setIsLoading(true) : setIsLoading(true);
    const page = 0;
    let candidateId = props.user.candidateId ? props.user.candidateId : (props.user.id ? props.user.id : params.candidate_id);
    CandidateService.candidateOfferJobs(candidateId, page)
    .then((response) => {
      if (response.length > 0) {
        response.forEach((obj) => obj["isChecked"] = false);
    }
    if (response.length < 10) {
      setLoadMore(false);
    } else {
      setLoadMore(true);
    }
      setJobs({ allChecked: false, list: response });
      handleOpenHireAndRehire();
      props.setIsLoading ? props.setIsLoading(false) : setIsLoading(false);
    })
    .catch((res) => {
      props.setIsLoading ? props.setIsLoading(false) : setIsLoading(false);
    });
  };
  const fetchMoreData = () => {
    let pgNo = pageNumber + 1;
    setPageNumber(pgNo);
    let candidateId = props.user.candidateId ? props.user.candidateId : (props.user.id ? props.user.id : params.candidate_id);
    CandidateService.candidateOfferJobs(candidateId, pgNo)
        .then((response) => {
        if (response.length > 0) {
            response.forEach((obj) => obj["isChecked"] = false);
        }
        if (response.length < 10) {
        setLoadMore(false);
        } else {
        setLoadMore(true);
        }
        setJobs({ allChecked: false, list: jobs.list.concat(response) });
        props.setIsLoading ? props.setIsLoading(false) : setIsLoading(false);
    })
    .catch((res) => {
      props.setIsLoading ? props.setIsLoading(false) : setIsLoading(false);
    });
  };
  const handleChange = (e) => {
    let checkedCount = jobs.list.filter((o) => o.isChecked).length == 1;
    let itemName = e.target.name;
    let checked = e.target.checked;
    setJobs((prevState) => {
        let { list, allChecked } = prevState;
        if (itemName === "checkAll") {
            allChecked = checked;
            list = list.map((item) => ({ ...item, isChecked: checked }));
        } else {
          if (checkedCount) {
            list = list.map((item) =>
          item.id === itemName ? { ...item, isChecked: false } : item
        );
        if (checked) {
          danger("Candidate can be offered only one job at a time.");
          }
          } else {
            list = list.map((item) =>
                item.id === itemName ? { ...item, isChecked: checked } : item
            );
          }
            allChecked = list.every((item) => item.isChecked);
        }
        const listLength = list.filter((o) => o.isChecked).length;
        return { list, allChecked };
    });
};

  function ValidateSingleInput(event) {
    var validFileExtensions = ["pdf", "doc", "docx", "zip"];
    var file = event.target.files[0];
    if (file) {
      if (file.name.length <= 80) {
        var extension = file.name.split(".").pop();
        if (validFileExtensions.includes(extension)) {
          setdocumentFile({ filename: file.name, extension: extension, file: file });
        } else {
          danger(
            "Please upload only DOC, DOCX, PDF and ZIP file formats!"
          );
          event.target.value = "";
        }
      } else {
        danger(
          "Filename is too long!"
        );
        event.target.value = "";
      }
    }
    return true;
  }

  const handleDeleteDocumentWithIndex = () => {
    setOpenDocumentIndex(true);
  };
  const handleCheckboxChange = (event) => {
    setSendOfferEmailChecked(event.target.checked);
  };
  const toggleCheckbox = () => {
    setSendOfferEmailChecked((prevChecked) => !prevChecked);
  };
  const deleteDocumentFromIndex = () => {
    setdocumentFile();
    setdocumentIndex();
    setOpenDocumentIndex(false);
    success("Document is deleted successfully.");
  };
  const handleSkipAndSendOffer = () => {
    if (jobStartDate != null) {
      if (new Date(values).setHours(0, 0, 0, 0) > new Date(jobStartDate).setHours(0, 0, 0, 0)) {
      danger("Offer expiry date cannot be greater than job start date.");
      return;
    };
  };
    let selectedJobs = jobs.list.filter((o) => o.isChecked);
      const selectedJobsIds = selectedJobs.map((o) => o.id);
    if (process.env.REACT_APP_INTEGRATE_CREDENTIALING === "Y" && selectedJobsIds[0]?.workflowUniqueIdentifier) {
      callcredentaling(selectedJobsIds[0]);
    } else {
      createOfferHandlerUpload(selectedJobsIds[0]);
    }
  };
  const callcredentaling = async (job) => {
    try {
      const data = await getCredentialingtoken();
      let firstName = "";
      let lastName  = "";
      if (candidateApplicationData?.candidateName) {
        const fullName = candidateApplicationData.candidateName.split(" ");
        firstName = fullName[0];
        lastName = fullName[1];
      } else {
        firstName = candidateApplicationData?.firstName ? candidateApplicationData?.firstName : "";
        lastName = candidateApplicationData?.lastName != null ? candidateApplicationData?.lastName : "";
      }
      if (data) {
        const payload = {
          "workflowId": parseInt(job?.workflowUniqueIdentifier),
          "externalRefId": job.id,
          "candidateDetail": {
            "firstName": firstName,
            "lastName": lastName ? lastName : "",
            "displayName": firstName + " " + (lastName ? lastName : ""),
            "emailId": "",
            "phoneNo": 0,
            "authType": "link",
            "password": "link"
          }
        };
        const response = await axios.post(`${process.env.REACT_APP_CREDENTIALING_MDM_SERVICE_URL}/credentialing/v1/api/assign/candidate/workflow`, payload, {
          headers: {
            Authorization: `Bearer ${data}`,
          },
        });
         createOfferHandlerUpload(job, (response.data?.payload?.invitationCode));
      } else {
        // Handle the case where data is not available or falsy
        console.error("Credentialing token data is empty or falsy.");
        props.setIsLoading ? props.setIsLoading(false) : setIsLoading(false);
      }
    } catch (error) {
      // Handle errors from getCredentialingtoken
      console.error("Error while fetching credentialing token:", error);
      props.setIsLoading ? props.setIsLoading(false) : setIsLoading(false);
    }
  };
  const createOfferHandlerUpload = async (job, invitationCode) => {
    props.setIsLoading ? props.setIsLoading(true) : setIsLoading(true);
    var formData = new FormData();
    if (process.env.REACT_APP_INTEGRATE_CREDENTIALING === "Y") {
      formData.append(
            "request-body",
            new Blob(
              [
                JSON.stringify({
                  jobId: job,
                  candidateId: candidateInfo?.candidateId ? candidateInfo?.candidateId : candidateInfo?.id,
                  workflowUniqueIdentifier: (invitationCode ? invitationCode : null),
                  offerExpiryDate: values,
                  jobStartDate: jobStartDate,
                  jobCompletionDate: jobCompletionDate,
                  payRate: payRate ? parseFloat(payRate) : null,
                  currency: currency,
                }),
              ],
              {
                type: "application/json",
              }
            )
          );
    } else {
      formData.append(
        "request-body",
        new Blob(
          [
            JSON.stringify({
              jobId: job,
              candidateId: candidateInfo?.candidateId ? candidateInfo?.candidateId : candidateInfo?.id,
              offerExpiryDate: values,
              jobStartDate: jobStartDate,
              jobCompletionDate: jobCompletionDate,
              payRate: payRate ? parseFloat(payRate) : null,
              currency: currency,
            }),
          ],
          {
            type: "application/json",
          }
        )
      );
    }
    await CandidateService.createOffer(formData, sendOfferEmailChecked)
    .then((response) => {
    success("Candidate is offered");
    getCandidatesByStatus();
    setOpenOffer(false);
    props.setIsLoading ? props.setIsLoading(false) : setIsLoading(false);
    })
    .catch(function (error) {
      if (error.statuscode == 500) {
        danger("Oops! Something went wrong. Please try again later.");
    } else {
        danger("Something went Wrong");
    }
    setOpenOffer(false);
    props.setIsLoading ? props.setIsLoading(false) : setIsLoading(false);
    });
    };
  const createOfferHandler =  async (invitationCodes) => {
    if (jobStartDate != null) {
      if (new Date(values).setHours(0, 0, 0, 0) > new Date(jobStartDate).setHours(0, 0, 0, 0)) {
      danger("Offer expiry date cannot be greater than job start date.");
      return;
    };
  };
  setOpenOffer(false);
  setViewOfferDrawer(true);
    // setIsLoading(true);
    // const selectedJobs = jobs.list.filter((o) => o.isChecked);
    // const selectedJobsIds = selectedJobs.map((o) => o.id);
    // const selectedJobWorkflowObj = selectedJobs.map((o) => {
    //   return {
    //     "jobId": o.id,
    //     "workflowUniqueIdentifier": null
    //   };
    // });
    // var formData = new FormData();
    // formData.append("offer-document", documentFile ? documentFile.file : '');
    // if (process.env.REACT_APP_INTEGRATE_CREDENTIALING === "Y") {
    //   formData.append('request-body', new Blob([JSON.stringify({
    //     jobIds: selectedJobsIds,
    //     offerExpiryDate: values,
    //     jobStartDate: jobStartDate,
    //     jobCompletionDate: jobCompletionDate,
    //     payRate: Number(payRate),
    //     jobWorkflow: (invitationCodes ? invitationCodes : []),
    //   })], {
    //       type: "application/json"
    //   }));
    // } else {
    //   formData.append('request-body', new Blob([JSON.stringify({
    //     jobIds: selectedJobsIds,
    //     offerExpiryDate: values,
    //     jobStartDate: jobStartDate,
    //     jobCompletionDate: jobCompletionDate,
    //     payRate: Number(payRate),
    //     jobWorkflow: selectedJobWorkflowObj,
    //   })], {
    //       type: "application/json"
    //   }));
    // }
    // let candidateId = props.user.candidateId ? props.user.candidateId : (props.user.id ? props.user.id : params.candidate_id);
    // await CandidateService.createOfferMultipleJobs(candidateId, formData)
    // .then((response) => {
    //     if (props.view === "Job Details" && selectedJobsIds.includes(params.job_id)) {
    //         let updated_candidates = props.candidates?.list?.filter((_candidate) => (_candidate.id ? (_candidate.id !== candidateId) : (_candidate.candidateId !== candidateId)));
    //         props.setCandidates({ allChecked: false, list: updated_candidates });
    //         ApiCalls.get(`enterprises/pool/candidates/statistics/${params.job_id}`)
    //         .then((res) => {
    //             props.setJobStatistics(res);
    //         })
    //         .catch((err) => {
    //         });
    //     }
    //     if (props.view === "Candidate Details" && selectedJobsIds.includes(params.job_id)) {
    //         navigate(
    //             "/enterprise/jobs/" +
    //             params.job_id +
    //             "/matching-candidates?tab=offered"
    //           );
    //     }
    //     success("Candidate is offered.");
    //     setIsLoading(false);
    //     handleCloseOffer();
    // })
    // .catch(function (error) {
    //   setIsLoading(false);
    // });
  };
  const getCandidatesByStatus = () => {
    let selectedJobs = jobs?.list ? jobs.list.filter((o) => o.isChecked) : [];
    const selectedJobsIds = selectedJobs.map((o) => o.id);
    if (props.view === "Job Details" && selectedJobsIds.includes(params.job_id)) {
      const status_values = {
        "matching-candidate": null,
        "applied": "A",
        "shortlisted": "S",
        "interviews": "I",
        "offered": "F",
        "onboarded": "O",
        "completed-cancelled": "B",
      };
      if (status_values[props.status]) {
        props.getCandidatesByStatus(status_values[props.status]);
      } else {
        props.getMatchingCandidates(params.job_id);
      }
    }
    if (props.view === "Candidate Details" && selectedJobsIds.includes(params.job_id)) {
      navigate(
        "/enterprise/jobs/" +
        params.job_id +
        "/matching-candidates?tab=offered"
      );
    }
    props.setIsLoading ? props.setIsLoading(false) : setIsLoading(false);
  };
  const [candidatePopUp, setCandidatePopUp] = useState();
  const [anchorElement, setAnchorElement] = React.useState(null);
  const open3 = Boolean(anchorElement);
  const id2 = open3 ? "simple-popover-list" : undefined;
  const handleOpenMatchingModal = (event, job) => {
    event.stopPropagation();
    setCandidatePopUp({ firstName: (props.user?.firstName ? props.user?.firstName : props.user?.candidateName?.split(" ")[0]), lastName: (props.user?.lastName ? props.user?.lastName : (props.user?.candidateName?.split(" ").length > 1 ? props.user?.candidateName?.split(" ")[1] : "")), profileTitle: props.user?.profileTitle, mediaFileSigned: (props.user?.mediaFileSigned ? props.user?.mediaFileSigned : props.user?.profileImageUrlSigned), matchingScore: job.matchingScore, matchingPercentage: job.matchingPercentage });
    setAnchorElement(true);
    functiondisable();
  };
  const handleCloseMatchingModal = useCallback((e) => {
    e.stopPropagation();
    setAnchorElement(null);
    functionenable();
  }, []);
//   const handleInputChange = (event) => {
//     if (event.target.value.length > 13 &&) {
//         event.target.value = event.target.value.slice(0, 13);
//     }
// };
  const jobDetailPage = (jobId) => {
    window.open(`/enterprise/jobs/${jobId}/matching-candidates`, "_blank");
  };
  const handleOpenVideoPreview = (e, profileVideo, candidateName) => {
    e.stopPropagation();
    setVideoSrc(profileVideo);
    setCandidateName(candidateName);
    setOpenVideoPreview(true);
  };
  const handleChangeStartDate = (date) => {
    setJobStartDate(date);
    let endDate = selectedJobsIdOffer?.duration ? handleEndDate(date, selectedJobsIdOffer?.duration) : jobCompletionDate;
    setJobCompletionDate(endDate);
    let startTo = new Date(endDate);
    let startFrom = new Date(formatDate(date));
    if (jobCompletionDate) {
      if (startFrom > startTo) {
        setEndDateErr("Job end date cannot be lesser than job start date");
      } else {
        setEndDateErr(null);
      }
    } else if (!date) {
      setEndDateErr(null);
    }
  };
  const handleChangeEndDate = (date) => {
    setJobCompletionDate(date);
    if (!date) {
      setEndDateErr(null);
      return;
    }
    let today = new Date();
    let startFrom = new Date(jobStartDate);
    let startTo = new Date(formatDate(date));
    if (jobStartDate) {
      if (startFrom > startTo) {
        setEndDateErr("Job end date cannot be lesser than job start date");
      } else {
        setEndDateErr(null);
      }
    } else {
      if (today > startTo) {
        setEndDateErr("Job end date cannot be lesser than today's date");
      } else {
        setEndDateErr(null);
      }
    }
  };
  const handleChangePayRate = (event) => {
    const inputValue = event.target.value;
    if (inputValue.length > 14) {
      return;
    }
    if (inputValue.trimStart() === "") {
      setPayRate(inputValue.trim());
      setPayRateErr(null);
    } else if (!inputValue.match(/^(\d{1,10}|\d{1,10}\.\d{1,3}|\d{1,9}\.\d{1,3}|\.\d{1,3})$/)) {
      setPayRateErr("Please enter a valid pay rate");
      setPayRate(inputValue);
    } else {
      setPayRate((inputValue || inputValue == 0) ? inputValue : null);
      setPayRateErr(null);
    }
  };
  // const handleChangePayRate = (event) => {
  //   const inputValue = event.target.value;

  //   // Check if current input length is greater than 13
  //   if (inputValue.length > 13) {
  //     // Do not update state or display any error
  //     return;
  //   }

  //   // Validate against the regex pattern
  //   if (!inputValue.match(/^(\d{1,}(\.\d{1,3})?|\.\d{1,3})?$/)) {
  //     setPayRateErr("Please enter a valid pay rate");
  //   } else {
  //     setPayRate(inputValue); // Update the state with the valid input value
  //     setPayRateErr(null);
  //   }
  // };
  const copyToClipboard = (event, jobReferenceNumber) => {
    event.stopPropagation();
    event.preventDefault();
    copy(jobReferenceNumber);
    success("Reference number is copied.");
  };
  const getExclusiveEnterprise = (id) => {
    const currentEnterprise = localStorage.getItem("childEnterpriseId") ? localStorage.getItem("childEnterpriseId") : localStorage.getItem("enterpriseId");
    const enterprisesList = JSON.parse(localStorage.getItem("enterprisesList"));
    const childEnterprisesList = JSON.parse(localStorage.getItem("mspEnterprsieData"))?.childEnterprises;
    const childEnterprise = childEnterprisesList?.find((ent) => ent.id == id);
    const enterprise = enterprisesList?.find((ent) => ent.id == id);
    return currentEnterprise != id ? (enterprise ? "To " + enterprise?.establishmentName : childEnterprise ? "To " + childEnterprise?.establishmentName : "") : "";
  };
  return (
    <>
        {isLoading && <Loader />}
        {props.view !== "All Leads" && (props.user?.availableToRehire === "Y") &&
            <Typography className="d-flex rehire-pool" tabIndex={0}>
            {/* <img
                src={rehireIcon}
                className="cursor-hover"
                onClick={hireAndRehire}
                onKeyPress={hireAndRehire}
            /> */}
            <span
                className="f-14 font-weight-500 pt-5 pb-5 pl-10 pr-10 cursor-hover secondary-bg-color border-radius-4 rehire-pool-align"
                onClick={hireAndRehire}
                onKeyPress={hireAndRehire}
            >
                Rehire
            </span>
            </Typography>
        }
        <Modal
        // open={openHireAndRehire}
        onClose={handleCloseHireAndRehire}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        onBackdropClick={(e)=>e.stopPropagation()}
    >
        <Box className="modal-content" onClick={(e)=>e.stopPropagation()}>
        <Grid item className="d-flex justify-between align-center">
            <Typography className="f-18 font-weight-700 heading-text-color p-5">
            Rehire
            </Typography>
            <Typography className="icon-button cursor-hover gray7">
            <CloseIcon
                onClick={handleCloseHireAndRehire}
                aria-label="Close icon" tabIndex={0} />
            </Typography>
        </Grid>
        <Grid className="d-flex mt-10 pb-30">
            <Grid style={{ width: `${localStorage.getItem("childEnterpriseId") && (localStorage.getItem("mspEnterpriseId") === candidateInfo?.sourceEnterpriseId) ? "110px": "81px"}` }}>
              <Grid className="p-relative">
                <Grid
                  item
                  lg={12}
                  md={12}
                  xs={12}
                  className="justify-center d-flex"
                >
                  {(candidateInfo.mediaFile === "****" ||
                    candidateInfo.mediaFileSigned === null ||
                    candidateInfo.mediaFileSigned === "") && (
                    <Typography
                      className="default-img-border justify-center align-center d-flex primary-shadow-color bg-input f-16 font-weight-700 text-capitalize"
                      width="45px"
                      height="45px"
                      left="2px"
                      aria-label="Default image"
                    >
                      {candidateInfo.firstName.trim().substring(0, 1)}
                    </Typography>
                  )}
                  {(candidateInfo.profileImageUrl === "****" ||
                    candidateInfo.profileImageUrlSigned === null ||
                    candidateInfo.profileImageUrlSigned === "") && (
                    <Typography
                      className="default-img-border justify-center align-center d-flex primary-shadow-color bg-input f-16 font-weight-700 text-capitalize"
                      width="45px"
                      height="45px"
                      left="2px"
                      aria-label="Default image"
                    >
                      {candidateInfo.candidateName.trim().substring(0, 1)}
                    </Typography>
                  )}
                  {candidateInfo.mediaFile !== "****" &&
                    candidateInfo.mediaFileSigned &&
                    candidateInfo.mediaFileSigned !== null &&
                    candidateInfo.mediaFileSigned !== "" &&
                    !checkIsImage(candidateInfo.mediaFileSigned) && (
                      <>
                        <video
                          className="default-img-border cursor-hover p-relative object-fit"
                          width="45px"
                          height="45px"
                          style={{ left: '2px' }}
                          aria-label="Default image"
                          src={candidateInfo.mediaFileSigned}
                          onClick={() => {
                            handleOpenVideoPreview(
                              candidateInfo.mediaFileSigned,
                              candidateInfo.firstName +
                                " " +
                                candidateInfo.lastName
                            );
                          }}
                          muted
                          onKeyPress={() => {
                            handleOpenVideoPreview(
                              candidateInfo.mediaFileSigned,
                              candidateInfo.firstName +
                                " " +
                                candidateInfo.lastName
                            );
                          }}
                          tabIndex={0}
                        />
                        <img
                          onClick={() => {
                            handleOpenVideoPreview(
                              candidateInfo.mediaFileSigned,
                              candidateInfo.firstName +
                                " " +
                                candidateInfo.lastName
                            );
                          }}
                          className="profile-image-overlay play-icon-profile cursor-hover"
                          src={playIcon}
                          style={{ top: "14px", left: "37px" }}
                          alt="img"
                          tabIndex={0}
                          onKeyPress={() => {
                            handleOpenVideoPreview(
                              candidateInfo.mediaFileSigned,
                              candidateInfo.firstName +
                                " " +
                                candidateInfo.lastName
                            );
                          }}
                        />
                      </>
                    )}
                  {candidateInfo.profileImageUrl !== "****" &&
                    candidateInfo.profileImageUrlSigned &&
                    candidateInfo.profileImageUrlSigned !== null &&
                    candidateInfo.profileImageUrlSigned !== "" &&
                    !checkIsImage(candidateInfo.profileImageUrlSigned) && (
                      <>
                        <video
                          className="default-img-border cursor-hover p-relative object-fit"
                          width="45px"
                          height="45px"
                          style={{ left: '2px' }}
                          aria-label="Default image"
                          src={candidateInfo.profileImageUrlSigned}
                          onClick={() => {
                            handleOpenVideoPreview(
                              candidateInfo.profileImageUrlSigned,
                              candidateInfo.candidateName
                            );
                          }}
                          muted
                          onKeyPress={() => {
                            handleOpenVideoPreview(
                              candidateInfo.profileImageUrlSigned,
                              candidateInfo.candidateName
                            );
                          }}
                          tabIndex={0}
                        />
                        <img
                          onClick={() => {
                            handleOpenVideoPreview(
                              candidateInfo.profileImageUrlSigned,
                              candidateInfo.candidateName
                            );
                          }}
                          className="profile-image-overlay play-icon-profile cursor-hover"
                          src={playIcon}
                          style={{ top: "14px", left: "37px" }}
                          alt="img"
                          tabIndex={0}
                          onKeyPress={() => {
                            handleOpenVideoPreview(
                              candidateInfo.profileImageUrlSigned,
                              candidateInfo.candidateName
                            );
                          }}
                        />
                      </>
                    )}
                  {candidateInfo.mediaFile !== "****" &&
                    candidateInfo.mediaFileSigned &&
                    candidateInfo.mediaFileSigned !== null &&
                    candidateInfo.mediaFileSigned !== "" &&
                    checkIsImage(candidateInfo.mediaFileSigned) && (
                      <img
                        className="default-img-border"
                        style={{ left: "2px" }}
                        src={candidateInfo.mediaFileSigned}
                        alt="img"
                        width="45px"
                        height="45px"
                      />
                    )}
                  {candidateInfo.profileImageUrl !== "****" &&
                    candidateInfo.profileImageUrlSigned &&
                    candidateInfo.profileImageUrlSigned !== null &&
                    candidateInfo.profileImageUrlSigned !== "" &&
                    checkIsImage(candidateInfo.profileImageUrlSigned) && (
                      <img
                        className="default-img-border"
                        style={{ left: "2px" }}
                        src={candidateInfo.profileImageUrlSigned}
                        alt="img"
                        width="45px"
                        height="45px"
                      />
                    )}
                </Grid>
                <Grid>
                  <TooltipData
                    title={
                      <div className="mt-15">
                        <Typography>
                          <div className="d-flex align-center">
                            <span className={`${localStorage.getItem("childEnterpriseId") && (localStorage.getItem("mspEnterpriseId") === candidateInfo?.sourceEnterpriseId) ? "mspExclusive" : "priorityStatus"} d-flex align-center justify-center`}>
                              <span className="d-flex align-center">
                                {localStorage.getItem("childEnterpriseId") && (localStorage.getItem("mspEnterpriseId") === candidateInfo?.sourceEnterpriseId) ? <PriorityIconGreen className="msp-svg" /> : <PriorityIcon />}
                              </span>
                              <span className="pl-2">
                                {localStorage.getItem("childEnterpriseId") && (localStorage.getItem("mspEnterpriseId") === candidateInfo?.sourceEnterpriseId) ? Constants.MSP_EXCLUSIVE : "Exclusive"}
                              </span>
                            </span>
                            <span className="f-12 c-white pl-5">{getExclusiveEnterprise(candidateInfo?.sourceEnterpriseId)}</span>
                          </div>
                        </Typography>
                        <Typography className="f-12 c-white mt-5">
                          {localStorage.getItem("childEnterpriseId") && (localStorage.getItem("mspEnterpriseId") === candidateInfo?.sourceEnterpriseId) ? Constants.MSP_EXCLUSIVE_Detail : "These are the candidates who have come to the platform through you and only you have the access to their profile"}
                        </Typography>
                        <br />
                      </div>
                    }
                    position="top"
                    trigger="mouseenter"
                    animation="fade"
                    arrow
                  >
                  <Typography className="mt-5 mb-5">
                    {candidateInfo?.talentPool === "Z" && (
                      <span
                        className={`${localStorage.getItem("childEnterpriseId") && (localStorage.getItem("mspEnterpriseId") === candidateInfo?.sourceEnterpriseId) ? "mspExclusive": "priorityStatus"} d-flex align-center justify-center p-absolute`}
                        style={{
                          bottom: "-20px",
                          left: "-1px",
                        }}
                      >
                        <span className="d-flex align-center">
                          {localStorage.getItem("childEnterpriseId") && (localStorage.getItem("mspEnterpriseId") === candidateInfo?.sourceEnterpriseId) ? <PriorityIconGreen className="msp-svg" /> :  <PriorityIcon />}
                        </span>
                        <span className="pl-5">{localStorage.getItem("childEnterpriseId") && (localStorage.getItem("mspEnterpriseId") === candidateInfo?.sourceEnterpriseId) ? Constants.MSP_EXCLUSIVE :"Exclusive"}</span>
                      </span>
                    )}
                  </Typography>
                  </TooltipData>
                  <TooltipData
                    title={
                      <div className="mt-15">
                        <Typography
                          style={{
                            width: "110px",
                          }}
                        >
                          <span className="myCandidate-Ongoing d-flex align-center justify-center">
                            <span className="d-flex align-center">
                              <PrivateIcon />
                            </span>
                            <span className="pl-5">
                              Qualified
                            </span>
                          </span>
                        </Typography>
                        <Typography className="f-12 c-white mt-5">
                          These are the
                          candidates with whom
                          you have interacted on
                          our platform
                        </Typography>
                        <br />
                      </div>
                    }
                    placement="top"
                    trigger="mouseenter"
                    animation="fade"
                    arrow
                  >
                  <Typography className="mt-5 mb-5">
                    {candidateInfo?.talentPool === "P" && (
                      <span
                        className="myCandidate-Ongoing d-flex align-center justify-centercenter p-absolute"
                        style={{
                          bottom: "-20px",
                          left: "0px",
                        }}
                      >
                        <span className="d-flex align-center">
                          <PrivateIcon />
                        </span>
                        <span className="pl-5">Qualified</span>
                      </span>
                    )}
                  </Typography>
                  </TooltipData>
                </Grid>
              </Grid>
            </Grid>
            <Grid className="ml-5 mt-2">
              <Typography className="main-text-color f-14 font-weight-600">
                {candidateInfo.firstName
                  ? candidateInfo.firstName + " " + candidateInfo.lastName
                  : candidateInfo.candidateName}
              </Typography>
              {candidateInfo?.city && candidateInfo?.country && (
              <Typography className="gray7 f-12 font-weight-400 mt-5 d-flex align-center">
                <LocationIcon
                  width="15px"
                  className="mr-5 gray7 f-12 font-weight-400 icon-margin"
                />
                <span>
                  {candidateInfo.city}
                  {candidateInfo?.city ? "," : ""} {candidateInfo.country}
                </span>
              </Typography>
              )}
            </Grid>
          </Grid>
            <Grid item className="mt-20">
                    <Grid item>
                    {/* {jobs.list.length > 0 && <Typography className="d-flex mb-20">
                            <FormGroup>
                                <FormControlLabel
                                    className="f-16 font-weight-400 select-all-pool align-center mt-2"
                                    control={
                                        <Checkbox
                                            name="checkAll"
                                            checked={jobs.allChecked}
                                            onChange={handleChange}
                                            sx={{
                                                color: "#98A2B3",
                                                paddingTop: '0px',
                                                paddingBottom: '0px',
                                                "&.Mui-checked": {
                                                    color: getComputedStyle(document.documentElement).getPropertyValue('--primary-color'),
                                                    paddingTop: '0px',
                                                    paddingBottom: '0px',
                                                },
                                            }}
                                            tabIndex={0}
                                            className="mr-0"
                                        />
                                    }
                                    label="Select All"
                                />
                            </FormGroup>
                        </Typography>} */}
                        <Grid item>
                        <InfiniteScroll
                        dataLength={jobs.list.length}
                        next={() => fetchMoreData()}
                        hasMore={loadMore}
                        loader={<Loader />}
                        inverse={false}
                        height={300}
                    //  scrollableTarget="scrollableDiv"
                    // style={{ height: "910px" }}
                    >
                            {jobs.list.length > 0 ? jobs.list.map((job, index) => {
                                return (
                                        <Typography className="border-bottom-gray4" key={index}>
                                            <Typography className="d-flex mt-5 mb-5 pt-5 pb-5 d-flex align-center justify-between pr-20">
                                                <Typography className="d-flex">
                                                {/* <Typography> */}
                                                    <FormGroup>
                                                        <FormControlLabel
                                                            key={index}
                                                            className="f-16 font-weight-400 select-all-pool align-center mr-0"
                                                            control={
                                                                <Checkbox
                                                                    key={job.id}
                                                                    name={job.id}
                                                                    checked={job.isChecked}
                                                                    onChange={handleChange}
                                                                    sx={{
                                                                        color: "#98A2B3",
                                                                        paddingTop: '3px',
                                                                        "&.Mui-checked": {
                                                                            color: getComputedStyle(document.documentElement).getPropertyValue('--primary-color'),
                                                                            paddingTop: '3px',
                                                                        },
                                                                    }}
                                                                />
                                                            }
                                                            label=""
                                                        />
                                                    </FormGroup>
                                                    <Typography>
                                                        <Typography onClick={() => jobDetailPage(job.id)} className="main-text-color cursor-hover f-15 font-weight-500">
                                                        {job?.jobTitle}
                                                        </Typography>
                                                        {job?.city && job?.country && (
                                                        <Box className="f-12 align-center gray7 d-flex candidate-info-align mt-2">
                                                            <Box className="d-flex align-center mr-5">
                                                                <LocationIcon width="15px" />
                                                            </Box>
                                                            <Box className="d-flex align-center f-12 gray7">
                                                                {job.city?.trim()}
                                                                {(job?.city?.trim() && job?.country?.trim()) ? ", " : ""}{job.country}
                                                            </Box>
                                                        </Box>
                                                        )}
                                                        <Box className="font-weight-400 d-flex align-center">
                                                        <Box className="d-flex align-center mr-5 ml-2">
                                                          <JobReferenceNumberIcon width="12px" height="12px" className="svg-icons-gray7" />
                                                        </Box>
                                                        <Box className="d-flex align-center gray7 f-12 display-flex align-items-center">
                                                          Job Reference #: {job?.jobReferenceNumber}
                                                          <Link
                                                            onClick={(e) => copyToClipboard(e, job?.jobReferenceNumber)}
                                                            onKeyPress={(e) => copyToClipboard(e, job?.jobReferenceNumber)}
                                                            className="cursor-hover display-flex p-3"
                                                            tabIndex={0}
                                                          >
                                                            <ContentCopyIcon
                                                              style={{ width: "13px", height: '13px', margin: "0" }}
                                                              className="gray7"
                                                            />
                                                          </Link>
                                                        </Box>
                                                      </Box>
                                                    </Typography>
                                                </Typography>
                                                <Typography className="d-flex justify-between">
                                                {/* <Typography className="d-flex align-center"> */}
                                                    <Typography className="d-flex justify-end cursor-hover" onClick={(e)=>{
                handleOpenMatchingModal(e, job);
                }}>
                                                    <Tooltip role="tooltip" title="Matching score" position="bottom"
                                                     trigger="mouseenter"
                                                     animation="fade"
                                                     arrow>
                    <Typography
                        className="justify-end d-flex sort-filter-center p-5 align-center matching-score-align"
                        style={{ height: "65px", width: "65px" }}
                        aria-describedby={id2}
                    >
                        <CircularProgressbar
                        aria-label="Circular Progress"
                        strokeWidth={12}
                        value={
                            job.matchingScore
                            ? job.matchingScore
                            : 0
                        }
                        // text={`${Math.round(
                        //     job.matchingScore
                        //     ? job.matchingScore
                        //     : 0
                        // )}%`}
                        styles={buildStyles({
                            textColor: "#027A48",
                            pathColor: "#027A48",
                            trailColor: "#adc6ce",
                            textSize: "25px",
                        })}
                        />
                        <span className="d-flex align-center success-color f-14 font-weight-500 ml-5">
                          {`${Math.round(
                            job.matchingScore
                            ? job.matchingScore
                              : 0
                          )}%`}
                        </span>
                    </Typography>
                    </Tooltip>
                    </Typography>
                                                </Typography>
                                            </Typography>
                                        </Typography>
                                );
                            }):
                            <Grid container item spacing={1} lg={12} md={12} xs={12} className="mt-5">
                            <Grid item lg={4} md={4} xs={12} className="m-auto text-center">
                                <img src={noCandidate} alt="img" width="200px" height="200px"/>
                                <Box className="mt-5 f-16 font-weight-600">No matched job found</Box>
                            </Grid>
                        </Grid>}
                        </InfiniteScroll>
                        </Grid>
                    </Grid>
                </Grid>
                    <Grid item id="modal-footer" className="justify-center d-flex mt-20">

                    {jobs.list.length > 0  && <Button
                            type="submit"
                            className="btn primary-button ml-10"
                            tabIndex={0}
                            aria-label="Accept"
                            disableRipple="true"
                            onClick={handleOpenOffer} onKeyPress={handleOpenOffer}
                            disabled={jobs.list.filter((o) => o.isChecked).length !== 1}>
                            Offer
                        </Button>}
                    </Grid>
        </Box>
    </Modal>
    {openHireAndRehire &&
      <ShortlistCandidateDrawer
        candidateProfileDrawer={openHireAndRehire}
        setCandidateProfileDrawer={setOpenHireAndRehire}
        setCandidateListId={props.setCandidateListId}
        candidate_id={props?.user.candidateId ? props.user.candidateId : props?.user?.id}
        optionValue={props.optionValue}
        setIsLoading={props.setIsLoading || setIsLoading}
        isFromRehire={true}
        // candidate_id={candidateListId}
        setUserList={props.setUserList}
        viewType={props.viewType}
        user={props.user2}
        drawerTitle={"Rehire Candidate"}
        />
      }

    {/* Offer Reason */}
    {/* <Modal
    open={openOffer}
    onClose={handleCloseOffer}
    aria-labelledby="modal-modal-title"
    aria-describedby="modal-modal-description"
    onBackdropClick={(e)=>e.stopPropagation()}
    >
    <Box className="modal-content inputcontrasts" onClick={(e)=>e.stopPropagation()}>
    <Typography className="align-left">
    <Typography id="modal-title" className="d-flex align-between">
    <Typography variant="h5" className="f-24 font-weight-600 heading-text-color">
        Offer
    </Typography>
    <Typography align="right" className="cursor-hover gray7 icon-button">
        <CloseIcon onClick={handleCloseOffer} onKeyPress={handleCloseOffer} aria-label="Close icon" tabIndex={0} />
    </Typography>
    </Typography>
    <Typography id="modal-description">
    <Grid container spacing={1}>
      <Grid item lg={6} md={6} xs={12}>
      <Box className="offerPayRateAlign">
        <p
          className="f-14 gray9 mb-0 font-weight-500 mt-0"
          align="left"
        >
          Expiry Date<span className="text-black">*</span>
        </p>
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <DatePicker
            className="form-control input-group mb-0 borderRadius-l inputcontrasts position-issue payRatePaddingBottom"
            value={values}
            onChange={(newValue) => {
              setValues(newValue);
            }}
            disablePast
            format="MMM dd, yyyy"
            emptyLabel="DD/MM/YYYY"
            disableFuture={false}
          />
        </MuiPickersUtilsProvider>
      </Box>
      </Grid>
      <Grid item lg={6} md={6} xs={12}>
        <Box className="offerPayRateAlign">
          <p
            className="f-14 gray9 mb-0 font-weight-500 mt-0"
            align="left"
          >
            Pay Rate
          </p>
          <Typography
            className={`d-flex align-center form-control input-group justify-between ${
              payRateErr ? "is-invalid-payRate" : ""
            }`}
          >
          <TextField
            type="text"
            id="maxPayrate"
            className={`w-100 mt-0 mb-0 borderRadius-l inputcontrasts position-issue inputcontrasts payRatePaddingBottom`}
            margin="normal"
            placeholder="Enter pay rate"
            value={payRate}
            onChange={(e) => handleChangePayRate(e)}
          />
          <Typography className="f-16 font-weight-600 gray11">
            /
          </Typography>
          <Typography className="pl-5 f-16 font-weight-600 gray11">
            hour
          </Typography>
          </Typography>
          <span className="danger-color error-msg">
            {" "}
            {payRateErr}
          </span>
        </Box>
      </Grid>
      <Grid item lg={6} md={6} xs={12}>
        <Box className="galaxy-width-input">
          <p
            className="f-14 gray9 mb-0 font-weight-500 mt-0"
            align="left"
          >
            Job Start Date
          </p>
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <DatePicker
              className="form-control input-group mb-0 borderRadius-l inputcontrasts position-issue"
              value={jobStartDate}
              onChange={(date) => handleChangeStartDate(date)}
              disablePast
              format="MMM dd, yyyy"
              placeholder="Job start date"
              id="start-date"
              inputProps={{ role: "textbox" }}
              TextFieldComponent={(params) => (
                <div
                  style={{ position: "relative" }}
                  onMouseEnter={() => setHideStartDateClearIcon(false)}
                  onMouseLeave={() => setHideStartDateClearIcon(true)}
                >
                  <Input {...params} readOnly />
                  {params.value && !hideStartDateClearIcon && (
                    <IconButton
                      style={{
                        position: "absolute",
                        top: "80%",
                        right: "6px",
                        transform: "translateY(-80%)",
                      }}
                      onClick={() => handleChangeStartDate(null)}
                    >
                      <ClearIcon
                        style={{ fontSize: "5px" }}
                        aria-label="Clear icon"
                        className="Clearicon-issue"
                      />
                    </IconButton>
                  )}
                </div>
              )}
            />
          </MuiPickersUtilsProvider>
        </Box>
      </Grid>
      <Grid item lg={6} md={6} xs={12}>
        <Box className="galaxy-width-input">
          <p
            className="f-14 gray9 mb-0 font-weight-500 mt-0"
            align="left"
          >
            Job End Date
          </p>
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <DatePicker
              id="refreshEndDate"
              className={`form-control input-group mb-0 borderRadius-l inputcontrasts position-issue ${
                endDateErr ? "is-invalid" : ""
              }`}
              value={jobCompletionDate}
              onChange={(date) => handleChangeEndDate(date)}
              format="MMM dd, yyyy"
              placeholder="Job end date"
              disablePast
              minDate={
                new Date(
                  new Date(
                    jobStartDate
                  ).getTime() + 86400000
                )
              }
              error={false}
              helperText={null}
              inputProps={{ role: "textbox" }}
              TextFieldComponent={(params) => (
                <div
                  style={{ position: "relative" }}
                  onMouseEnter={() => setHideEndDateClearIcon(false)}
                  onMouseLeave={() => setHideEndDateClearIcon(true)}
                >
                  <Input {...params} readOnly />
                  {params.value && !hideEndDateClearIcon && (
                    <IconButton
                      style={{
                        position: "absolute",
                        top: "80%",
                        right: "6px",
                        transform: "translateY(-80%)",
                      }}
                      onClick={() => handleChangeEndDate(null)}
                    >
                      <ClearIcon
                        style={{ fontSize: "5px" }}
                        aria-label="Clear icon"
                        className="Clearicon-issue"
                      />
                    </IconButton>
                  )}
                </div>
              )}
            />
          </MuiPickersUtilsProvider>
          <span className="danger-color error-msg">
            {" "}
            {endDateErr}
          </span>
        </Box>
      </Grid>
    </Grid>
    <Grid
        item
        lg={12}
        md={12}
        xs={12}
        className="mt-20 mb-10"
    >
        <Grid item className="justify-between pb-5 border-bottom-1 d-flex">
        <span className="f-14  gray9 font-weight-400">Document
            <Tooltip
                position="right"
                title="You can upload pdf, doc, docx or zip file"
                trigger="mouseenter"
                animation="fade"
                arrow
            >
                <IconButton aria-label="Info icon">
                <InfoIcon aria-label="Info icon" />
                </IconButton>
            </Tooltip>
        </span>
        {!documentFile &&
            <Link
            className="link-color f-14 text-decoration-none cursor-hover m-r-10"
            variant="contained"
            component="label"
            style={{ marginTop: '6px' }}
            tabIndex={0}
            >
            {" "}
            <input type="file" onChange={ValidateSingleInput} hidden />
            Attach File +
            </Link>
        }
        </Grid>
        <Grid item className="justify-between mt-10 d-flex">
        <Grid item className="d-flex workDocument align-center">
            {documentFile && documentFile.extension === "pdf" && (
            <img
                src={Resumepdf}
                alt="img"
                className="m-r-10 file-extension-image wth-50"
                width="40px"
                height="40px"
                style={{ objectFit: 'cover' }}
            />
            )}
            {documentFile && documentFile.extension === "doc" && (
            <img
                src={docIcon}
                alt="img"
                className="m-r-10 file-extension-image wth-50"
                width="40px"
                height="40px"
                style={{ objectFit: 'cover' }}
            />
            )}
            {documentFile && documentFile.extension === "zip" && (
            <img
                src={zipIcon}
                alt="img"
                className="m-r-10 file-extension-image wth-50"
                width="40px"
                height="40px"
                style={{ objectFit: 'cover' }}
            />
            )}
            {documentFile && documentFile.extension === "docx" && (
            <img
                src={docIcon}
                alt="img"
                className="m-r-10 file-extension-image wth-50"
                width="40px"
                height="40px"
                style={{ objectFit: 'cover' }}
            />
            )}
            {documentFile && <p className="textEllipse w-500">{documentFile.filename}</p>}
        </Grid>
        {documentFile && <Grid item className="d-flex gray7 f-13 align-center d-flex justify-center">
            <span
                onClick={() => handleDeleteDocumentWithIndex()}
                style={{ cursor: "pointer" }}
                tabIndex={0}
                onKeyPress={() => handleDeleteDocumentWithIndex()}
            ><img
                className="sub-text-font m-r-10"
                src={DeleteSignedDoc}
                alt="delete icon"
                width="18px"
                height="18px"
                tabIndex={0}
                />
            </span>
        </Grid>}
        </Grid>
    </Grid>
    </Typography>
    <Typography className="justify-center d-flex mt-20">
    <Button
        onClick={()=>{
          if (process.env.REACT_APP_INTEGRATE_CREDENTIALING === "Y") {
            handleAssignWorkFlow();
          } else {
            createOfferHandler();
          }
        }}
        className="btn primary-button"
        disableRipple="true"
        disabled={endDateErr || payRateErr}
    >
        Offer
    </Button>
    </Typography>
    </Typography>
    </Box>
    </Modal> */}
    <Modal
        open={openOffer}
        onClose={handleCloseOffer}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box className="modal-content inputcontrasts">
          <Typography className="align-left">
            <Typography id="modal-title" className="d-flex align-between">
              <Typography variant="h5" className="f-20 font-weight-600 heading-text-color">
                Offer
                <Typography className="f-14 font-weight-500 m-t-5">
                You can generate an offer letter or send the offer directly.
                </Typography>
              </Typography>
              <Typography
                align="right"
                className="cursor-hover gray7 icon-button"
              >
                <CloseIcon
                  onClick={handleCloseOffer}
                  onKeyPress={handleCloseOffer}
                  aria-label="Close icon"
                  tabIndex={0}
                />
              </Typography>
            </Typography>
            <Typography id="modal-description">
              <Grid container spacing={1}>
                <Grid item lg={6} md={6} xs={12}>
                  <Box className="offerPayRateAlign">
                    <p
                      className="f-14 gray9 mb-0 font-weight-500 mt-0"
                      align="left"
                    >
                      Expiry Date<span className="text-black">*</span>
                    </p>
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                      <DatePicker
                        className="form-control input-group mb-0 borderRadius-l inputcontrasts position-issue payRatePaddingBottom"
                        value={values}
                        onChange={(newValue) => {
                          setValues(newValue);
                        }}
                        disablePast
                        format="MMM dd, yyyy"
                        placeholder="Offer expiry date"
                        disableFuture={false}
                      />
                    </MuiPickersUtilsProvider>
                  </Box>
                </Grid>
                <Grid item lg={6} md={6} xs={12}>
                  <Box className="offerPayRateAlign">
                    <p
                      className="f-14 gray9 mb-0 font-weight-500 mt-0"
                      align="left"
                    >
                      Pay Rate
                    </p>
<Grid container spacing={1} lg={12} md={12} xs={12} className='m-0'>
                    <Grid item lg={3} md={3} xs={4} className='pl-0 pt-7'>
                    <Typography
                      className="d-flex align-center"
                                >
                                    <Box className="p-0 w-100 payrate-closure">
                                        <Select
                                            // disabled={value}
                                            inputProps={{
                                                name: "curreny",
                                                id: "uncontrolled-native",
                                            }}
                                            className="mb-0 w-100 inputcontrasts form-control input-group discipline-input pay-rate-input pb-7"
                                            name="curreny"
                                            value={currency}
                                            onChange={(e) => {
                                                setCurrency( e.target.value);
                                            }}
                                            displayEmpty
                                        >
                                            <MenuItem value="USD" className='selectBox'>USD</MenuItem>
                                            <MenuItem value="GBP" className='selectBox'>GBP</MenuItem>
                                            <MenuItem value="EUR" className='selectBox'>EUR</MenuItem>
                                            <MenuItem value="CHF" className='selectBox'>CHF</MenuItem>
                                            <MenuItem value="CZK" className='selectBox'>CZK</MenuItem>
                                            <MenuItem value="INR" className='selectBox'>INR</MenuItem>
                                        </Select>
                                    </Box>
                                </Typography>
                            </Grid>
                            <Grid item lg={9} md={9} xs={8} className="pt-7">
                    <Typography
                      className={`d-flex align-center payrate-closure ${payRateErr ? "is-invalid-payRate" : ""
                      }`}
                    >
                    <Input
                      type="text"
                      name="hourlyRateNumber"
                                        id="hourlyRateNumber"
                      className="mb-0 inputcontrasts form-control input-group w-100 pb-6"
                      margin="normal"
                      placeholder="Enter pay rate"
// disabled={value}
                      value={payRate}
                      onChange={(e) => handleChangePayRate(e)}
                    // onInput={handleInputChange}
                                        endAdornment={
                                            <InputAdornment position="end">
                    <Typography className="f-16 font-weight-600 gray11">/ hour</Typography>
                    </InputAdornment>
                                        }
                                    />
                    </Typography>
                    <span className="danger-color error-msg">
                      {" "}
                      {payRateErr}
                    </span>
</Grid>
                    </Grid>
                  </Box>
                </Grid>
                <Grid item lg={6} md={6} xs={12}>
                  <Box className="galaxy-width-input">
                    <p
                      className="f-14 gray9 mb-0 font-weight-500 mt-0"
                      align="left"
                    >
                      Job Start Date<span className="text-black">*</span>
                    </p>
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                      <DatePicker
                        className="form-control input-group mb-0 borderRadius-l inputcontrasts position-issue"
                        value={jobStartDate}
                        onChange={(date) => handleChangeStartDate(date)}
                        disablePast
                        format="MMM dd, yyyy"
                        placeholder="Job start date"
                        id="start-date"
                        inputProps={{ role: "textbox" }}
minDate={
                          values  ? new Date(values) : null
                        }
                        TextFieldComponent={(params) => (
                          <div
                            style={{ position: "relative" }}
                            onMouseEnter={() =>
                              setHideStartDateClearIcon(false)
                            }
                            onMouseLeave={() => setHideStartDateClearIcon(true)}
                          >
                            <Input {...params} readOnly />
                            {params.value && !hideStartDateClearIcon && (
                              <IconButton
                                style={{
                                  position: "absolute",
                                  top: "80%",
                                  right: "6px",
                                  transform: "translateY(-80%)",
                                }}
                                onClick={() => handleChangeStartDate(null)}
                              >
                                <ClearIcon
                                  style={{ fontSize: "5px" }}
                                  aria-label="Clear icon"
                                  className="Clearicon-issue"
                                />
                              </IconButton>
                            )}
                          </div>
                        )}
                      />
                    </MuiPickersUtilsProvider>
                  </Box>
                </Grid>
                <Grid item lg={6} md={6} xs={12}>
                  <Box className="galaxy-width-input">
                    <p
                      className="f-14 gray9 mb-0 font-weight-500 mt-0"
                      align="left"
                    >
                      Job End Date
                    </p>
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                      <DatePicker
                        id="refreshEndDate"
                        className={`form-control input-group mb-0 borderRadius-l inputcontrasts position-issue ${endDateErr ? "is-invalid" : ""
                        }`}
                        value={jobCompletionDate}
                        onChange={(date) => handleChangeEndDate(date)}
                        format="MMM dd, yyyy"
                        placeholder="Job end date"
                        disablePast
                        minDate={
                          new Date(new Date(jobStartDate).getTime() + 86400000)
                        }
                        error={false}
                        helperText={null}
                        inputProps={{ role: "textbox" }}
                        TextFieldComponent={(params) => (
                          <div
                            style={{ position: "relative" }}
                            onMouseEnter={() => setHideEndDateClearIcon(false)}
                            onMouseLeave={() => setHideEndDateClearIcon(true)}
                          >
                            <Input {...params} readOnly />
                            {params.value && !hideEndDateClearIcon && (
                              <IconButton
                                style={{
                                  position: "absolute",
                                  top: "80%",
                                  right: "6px",
                                  transform: "translateY(-80%)",
                                }}
                                onClick={() => handleChangeEndDate(null)}
                              >
                                <ClearIcon
                                  style={{ fontSize: "5px" }}
                                  aria-label="Clear icon"
                                  className="Clearicon-issue"
                                />
                              </IconButton>
                            )}
                          </div>
                        )}
                      />
                    </MuiPickersUtilsProvider>
                    <span className="danger-color error-msg">
                      {" "}
                      {endDateErr}
                    </span>
                  </Box>
                </Grid>
              </Grid>
              <Box className="galaxy-width-input mt-10">
            <Typography className="mt-2 display-flex align-center offer-letter-div">
                  <Checkbox
                    {...label}
                    checked={sendOfferEmailChecked}
                    disableRipple={true}
                    sx={{
                      paddingLeft: "0px",
                      color: "#98A2B3",
                      "&.Mui-checked": {
                        color: getComputedStyle(
                          document.documentElement
                        ).getPropertyValue(
                          "--primary-color"
                        ),
                      },
                    }}
                  onClick={handleCheckboxChange}
                  onKeyPress={handleCheckboxChange}
                  />
                <span
                  className="cursor-hover f-14 font-weight-400"
                  style={{ marginTop: "-2px" }}
                  onClick={toggleCheckbox}
                  onKeyPress={toggleCheckbox}
                >
                  By unchecking this box, the offer email will not be sent to the candidate.
                </span>
              </Typography>
              </Box>
            </Typography>
            <Typography className="justify-center d-flex mt-20">
              <Button
                onClick={() => {
                  createOfferHandler();
                }}
                className="btn secondary-button"
                disableRipple="true"
                disabled={endDateErr || payRateErr || values == null || jobStartDate == null}
              >
                Prepare Offer Letter
              </Button>
              <Button
                onClick={handleSkipAndSendOffer}
                className="btn primary-button ml-15"
                disableRipple="true"
                disabled={endDateErr || payRateErr || values == null || jobStartDate == null}
              >
                {sendOfferEmailChecked ? "Send Offer Email" : "Send Offer"}
              </Button>
            </Typography>
          </Typography>
        </Box>
      </Modal>
    <ConfirmDialog
        open={openDocumentIndex}
        setOpen={setOpenDocumentIndex}
        dialogTitle="Delete Document "
        description={"Are you sure?"}
        handleConfirmYes={deleteDocumentFromIndex}
    />
    <MatchingScoreBreakdownModal
        open3={open3}
        handleClosePopover={handleCloseMatchingModal}
        candidatePopUp={candidatePopUp}
    />
    <ExclusiveWorkPermissionModal
        openExclusiveWorkPermissionModal={openExclusiveWorkPermissionModal}
        setOpenExclusiveWorkPermissionModal={setOpenExclusiveWorkPermissionModal}
        action={"offer"}
        jobDetails={selectedExclusiveJobDetails}
    />
    <OfferLetterRehire
        setIsLoading={props.setIsLoading ? props.setIsLoading : setIsLoading}
        setViewOfferDrawer={setViewOfferDrawer}
        viewOfferDrawer={viewOfferDrawer}
        jobs={jobs}
        candidateApplicationData={candidateInfo}
        jobStartDate={jobStartDate}
        jobCompletionDate={jobCompletionDate}
        payRate={payRate}
        expiryDate={values}
        currency={currency}
        getCandidatesByStatus={getCandidatesByStatus}
        sendOfferEmailChecked={sendOfferEmailChecked}
      />
    </>
  );
});
Rehire.displayName = 'Rehire';

export default Rehire;
