import AccessService from "../Services/Enterprise/Access.service";
import KeycloakService from "../keycloak";
import { saveCookie, extractValueFromCookie } from "./helper-data";
import Constants from "./constants";

export const onAuthSuccessCallback = (callback) => {
  if (KeycloakService.userInfo()?.email != null) {
  AccessService.checkIfEnterpriseExists({ email: KeycloakService.userInfo()?.email || null })
  .then(function (res) {
    const allAccessValid = res?.every((item) => {
      return item.isAccessValid === false;
    });
    //logout when all enterprises have isAccessValid false in the list
    if (allAccessValid) {
      logout('ACCESS_EXPIRED');
    } else {
    //showing the enterprise which has isAccessValid true from list
    const currentUser = res?.length > 0 ? res?.find((item) => {
      return item.isAccessValid === true;
    }) : [];
    const queryParams = new URLSearchParams(window.location.search);
    let cookieConsentEnterprise =  extractValueFromCookie("cookieConsentEnterprise", document.cookie);
    if (!localStorage.getItem("enterpriseId")) {
      //The last selected enterprise should be the default enterprise on next login
      const lastLoggedInEnterprise = res?.length > 0 ? res?.find((item) => {
        return item.enterpriseId === extractValueFromCookie("enterprise_id", document.cookie);
      }) : [];
      if (process.env.REACT_APP_KEYCLOAK_REALM == "dsaas") {
        //below if condition will redirect user to alias url once he logged in
        if (lastLoggedInEnterprise?.customBaseUrl != null && window.location.origin != lastLoggedInEnterprise?.customBaseUrl) {
          window.location.href = `${lastLoggedInEnterprise?.customBaseUrl}/enterprise/jobList`;
          return;
        } else if (queryParams.get("enterpriseId")) {
          //saving cookie into custom url when we switch to another enterprise (multi entity change)
          let id = atob(queryParams.get("enterpriseId"));
          localStorage.setItem("enterpriseId", id);
          saveCookie("enterprise_id", id, 365);
          // changing the enterprise and saving the cookie if not saved
          if (!cookieConsentEnterprise) {
            saveCookie("cookieConsentEnterprise", "accepted", 365);
          }
          // window.location.href = "/enterprise/jobList";
        } else if (lastLoggedInEnterprise?.enterpriseId == undefined && currentUser?.customBaseUrl != null && window.location.origin != currentUser?.customBaseUrl) {
          // loggedIn for the first time and checking if cookies are present go to if condition otherwise else
          if (cookieConsentEnterprise === "accepted") {
            window.location.href = `${currentUser?.customBaseUrl}/enterprise?cookieConsentEnterprise=true`;
            return;
          } else {
            window.location.href = `${currentUser?.customBaseUrl}/enterprise/jobList`;
            return;
          }
        } else {
          // first time loggedIn user will come here and saving cookie into custom url
          if (queryParams.get("cookieConsentEnterprise")) {
            if (!cookieConsentEnterprise) {
              saveCookie("cookieConsentEnterprise", "accepted", 365);
            }
          }
          console.log("no custom url");
        }
      }
      localStorage.setItem("name", lastLoggedInEnterprise ? lastLoggedInEnterprise?.enterpriseCandidateUserName : currentUser?.enterpriseCandidateUserName);
      localStorage.setItem("enterpriseId", lastLoggedInEnterprise ? lastLoggedInEnterprise?.enterpriseId : currentUser?.enterpriseId);
    }
    if (currentUser == undefined) {
      logout('UNAUTHORIZED_ACCESS');
    } else if (!currentUser?.isCandidateEnterpriseUser) {
      logout('UNAUTHORIZED_ACCESS');
    } else if (currentUser?.isAccessValid === false) {
      logout('ACCESS_EXPIRED');
    } else {
      callback();
    }
  }
  })
  .catch((err) => {
    console.error("Something went wrong! Please try again later.");
    callback();
  });
} else {
  callback();
}
};
export const signIn = (username, password, appOrigin) => {
  return new Promise((resolve, reject) => {
    const clientMetadata = {
      origin: process.env.REACT_APP_ORIGIN,
      clientMetadata:
        appOrigin === "N"
          ? "NEURODIVERSITY_ENTERPRISE_SELF_VIEW"
          : appOrigin === "H"
          ? "HEALTHCARE_ENTERPRISE_SELF_VIEW"
          : "GENERAL_ENTERPRISE_SELF_VIEW",
    };
    Auth.signIn(username, password, clientMetadata)
      .then((res) => resolve(res))
      .catch((err) => reject(err));
  });
};

export const signUp = ({
  enterpriseName,
  username,
  password,
  name,
  isUserInvited,
  inviteUserLocation,
  profileTitle,
  ipAddress,
  mspToken,
  appOrigin,
}) => {
  console.log(ipAddress);
  return new Promise((resolve, reject) => {
    const clientMetadata = {
      origin: process.env.REACT_APP_ORIGIN,
      clientMetadata:
        appOrigin === "N"
          ? "NEURODIVERSITY_ENTERPRISE_SELF_VIEW"
          : appOrigin === "H"
          ? "HEALTHCARE_ENTERPRISE_SELF_VIEW"
          : "GENERAL_ENTERPRISE_SELF_VIEW",
    };
    Auth.signUp({
      username,
      password,
      attributes: {
        name,
        "custom:isEnterprise": "Y",
        "custom:enterpriseName": enterpriseName,
        "custom:profile_title": profileTitle,
        "custom:isUserInvited": isUserInvited,
        "custom:location": inviteUserLocation,
        "custom:ipAddress": ipAddress,
        ...(mspToken != null && { "custom:mspToken": mspToken }),
      },
      clientMetadata,
    })
      .then((res) => resolve(res))
      .catch((err) => reject(err));
  });
};

export const confirmSignup = (username, otp) => {
  return new Promise((resolve, reject) => {
    Auth.confirmSignUp(username, otp)
      .then((res) => resolve(res))
      .catch((err) => reject(err));
    });
};

// Send confirmation code to user's email
export const forgotPassword = (username, appOrigin) => {
  const clientMetadata = {
    origin: process.env.REACT_APP_ORIGIN,
    clientMetadata:
      appOrigin === "N"
        ? "NEURODIVERSITY_ENTERPRISE_SELF_VIEW"
        : appOrigin === "H"
        ? "HEALTHCARE_ENTERPRISE_SELF_VIEW"
        : "GENERAL_ENTERPRISE_SELF_VIEW",
  };
  return new Promise((resolve, reject) => {
    Auth.forgotPassword(username, clientMetadata)
      .then((data) => resolve(data))
      .catch((err) => reject(err));
  });
};
export const changeUserPassword = (oldPassword, newPassword) => {
  return new Promise((resolve, reject) => {
    Auth.currentAuthenticatedUser().then((user) => {
      Auth.changePassword(user, oldPassword, newPassword)
        .then((res) => resolve(res))
        .catch((err) => reject(err));
    });
  });
};
// Collect confirmation code and new password, then
export const forgotPasswordSubmit = (username, code, password) => {
  return new Promise((resolve, reject) => {
    Auth.forgotPasswordSubmit(username, code, password)
      .then((data) => resolve(data))
      .catch((err) => reject(err));
    });
};

export const resendSignup = (username, appOrigin) => {
  const clientMetadata = {
    origin: process.env.REACT_APP_ORIGIN,
    clientMetadata:
      appOrigin === "N"
        ? "NEURODIVERSITY_ENTERPRISE_SELF_VIEW"
        : appOrigin === "H"
        ? "HEALTHCARE_ENTERPRISE_SELF_VIEW"
        : "GENERAL_ENTERPRISE_SELF_VIEW",
  };
  return new Promise((resolve, reject) => {
    Auth.resendSignUp(username, clientMetadata)
      .then((data) => resolve(data))
      .catch((err) => reject(err));
  });
};

export const forceChangePassword = (username, tempPassword, newPassword) => {
  return new Promise((resolve, reject) => {
    Auth.signIn(username, tempPassword).then((user) => {
      if (user.challengeName === "NEW_PASSWORD_REQUIRED") {
        Auth.completeNewPassword(user, newPassword)
          .then((user) => {
            resolve(user);
          })
          .catch((err) => reject(err));
      }
    });
  });
};

export const isSessionValid = async () => {
  return KeycloakService.isLoggedIn();
};

export const logout = async (reason = '') => {
  if (typeof reason !== 'string') {
    reason = '';
  }

  try {
    if (KeycloakService.getToken()) {
      localStorage.clear();
      await KeycloakService.doLogout({
        redirectUri: addDataToKeycloakRedirectURI(`${window.location.origin}/signin${reason ? `?kc_logout_reason=${reason}` : ''}`)
      });
    }
  } catch {
    return;
  }
};

export const getFromLocalStorage = (key) => {
  return localStorage.getItem(key);
};

export const getEnterpriseLogo = () => {
  const eLogo = getFromLocalStorage("enterpriseLogo");
  const enterpriseLogo = eLogo
    ? eLogo !== "undefined" && eLogo !== undefined
      ? eLogo
      : Constants.DEFAULT_LOGO
    : Constants.DEFAULT_LOGO;
  return enterpriseLogo;
};

export const functiondisable = () => {
  // To get the scroll position of current webpage
  let TopScroll = window.pageYOffset || document.documentElement.scrollTop;
  let LeftScroll = window.pageXOffset || document.documentElement.scrollLeft;
  // if scroll happens, set it to the previous value
  window.onscroll = function () {
    window.scrollTo(LeftScroll, TopScroll);
  };
};
const getCookie = (name) => {
  const value = `; ${document.cookie}`;
  const parts = value.split(`; ${name}=`);
  if (parts.length === 2) return parts.pop().split(';').shift();
};
export const addDataToKeycloakRedirectURI = (redirectUri = window.location.href) => {
  const url = new URL(redirectUri);
  // const cookieConsent = getCookie('cookieConsentEnterprise');
  //   if (!cookieConsent) {
  //       url.searchParams.append("cookieConsentEnterprise", false);
  //   } else {
  //       url.searchParams.append("cookieConsentEnterprise", true);
  //   }
    return url.toString();
};
export const checkForCookiePopUp = (location) => {
  //if pages are cookiesPolicy, termsOfUse or privacyPolicy we are hiding cookiePopup
  if (location.pathname.includes("/cookiepolicy") || location.pathname.includes("/termsofuse") || location.pathname.includes("/privacypolicy") || location.pathname.includes("/ukprivacypolicy")) {
    return false;
     //if page is LaunchPage, we are checking if cookie is not Present then we are showing cookiePopup
  } else if (location.pathname == "/" && !extractValueFromCookie('cookieConsentEnterprise', "accepted") && !KeycloakService.isLoggedIn()) {
    return true;
    //if page is not public page, we are checking if cookie is Present or not then we are not showing Cookie Popup
  } else if (!KeycloakService.isLoggedIn() && !extractValueFromCookie('cookieConsentEnterprise', "accepted") && (location.pathname.includes("/enterprise") || location.pathname.includes("/signin") || location.pathname.includes("/signup"))) {
    return false;
    //if page is public page, we are checking if cookie is Present or not then we are showing Cookie Popup
  } else if (!KeycloakService.isLoggedIn() && !extractValueFromCookie('cookieConsentEnterprise', "accepted") && !location.pathname.includes("/enterprise")) {
    return true;
    //if page is not public page, we are checking if cookie is Present or not then we are showing Cookie Popup
  } else if (KeycloakService.isLoggedIn() && !extractValueFromCookie('cookieConsentEnterprise', "accepted")) {
    return true;
  }
};

export const functionenable = () => {
  window.onscroll = function () {};
};

export const navigateToSSOSignIn = () => {
  window.open(
    `https://${process.env.REACT_APP_AWS_USER_POOL_DOMAIN}/login?redirect_uri=${process.env.REACT_APP_AWS_USER_POOL_REDIRECT_SIGN_IN}&response_type=code&client_id=${process.env.REACT_APP_AWS_USER_POOL_WEB_CLIENT_ID}&identity_provider=COGNITO&scope=phone+email+openid+profile+aws.cognito.signin.user.admin`,
    "_self"
  );
};

export const navigateToSSOSignUp = () => {
  window.open(
    `https://${process.env.REACT_APP_AWS_USER_POOL_DOMAIN}/signup?redirect_uri=${process.env.REACT_APP_AWS_USER_POOL_REDIRECT_SIGN_IN}&response_type=code&client_id=${process.env.REACT_APP_AWS_USER_POOL_WEB_CLIENT_ID}&identity_provider=COGNITO&scope=phone+email+openid+profile+aws.cognito.signin.user.admin`,
    "_self"
  );
};
