import React, { useEffect, useState, useRef } from "react";
import {
  Box,
  Breadcrumbs,
  Button,
  Grid,
  IconButton,
  Link,
  Popover,
  Typography,
  Drawer,
  Modal,
  Rating,
  Checkbox,
  Tab,
  Stack,
  Popper,
  Grow,
  Paper,
  ClickAwayListener,
  MenuList,
  MenuItem,
  Tooltip,
  FormControlLabel,
  Radio,
  Input,
  Select,
  TextField,
  InputAdornment,
} from "@mui/material";
import ClearIcon from "@mui/icons-material/Clear";
import WorkOutlineIcon from "@mui/icons-material/WorkOutline";
import MailOutlineIcon from "@mui/icons-material/MailOutline";
import cellIcon from "../../../assets/images/cellIcon.svg";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import bgImage from "../../../assets/images/matchingBg.svg";
import VerifiedIcon from "@mui/icons-material/Verified";
import microsoftLogo from "../../../assets/images/microsoftLogo.svg";
import { ReactComponent as SearchIcon } from "../../../assets/images/new-search-gray-icon.svg";
import DateFnsUtils from "@date-io/date-fns";
import CandidateService from "../../../Services/Enterprise/Candidate.service";
import OfferLetter from "../OfferLetter/GenerateOfferLetter";
import Loader from "../../Loader";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import AccessService from "../../../Services/Enterprise/Access.service";
import moment from "moment";
import Skills from "../../EnterprisePortal/Project/_JobCandidateDetailsPartials/Skills";
import ActionButtons from "../../EnterprisePortal/Project/_JobCandidateDetailsPartials/ActionButtons";
import Education from "../../EnterprisePortal/Project/_JobCandidateDetailsPartials/Education";
import { ReactComponent as ReferralIcon } from "../../../assets/images/ReferralIcon.svg";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import Resumepdf from "../../../assets/images/extension-icon/pdf-icon.png";
import docIcon from "../../../assets/images/extension-icon/doc-icon.png";
import { buildStyles, CircularProgressbar } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import hourlyRateImage from "../../../assets/images/hourlyRate-profile.svg";
import overallExp from "../../../assets/images/overallExp-profile.svg";
import viewAssessment from "../../../assets/images/view-assessment.svg";
import CloseIcon from "@mui/icons-material/Close";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CancelRoundedIcon from "@mui/icons-material/CancelRounded";
import { functiondisable, functionenable } from "../../../helpers/helper";
import * as Constants from "../../../helpers/constants";
import RatingsAndReviews from "./_JobCandidateDetailsPartials/RatingsAndReviews";
import starEmpty from "../../../assets/images/star-icon-empty.svg";
import starFilled from "../../../assets/images/star-icon-filled.svg";
import workLogo from "../../../assets/images/workExp.svg";
import GroupOutlinedIcon from "@mui/icons-material/GroupOutlined";
import CandidateProfileVideoPreview from "../../../Components/shared/CandidateProfileVideoPreview";
import { checkIsImage, formatDate, getCandidateShiftTimings, replaceKey, handleSpaces, getDurationValue, getDurationPeriodForDropDown } from "../../../helpers/helper-data";
import playIcon from "../../../assets/images/playIcon.svg";
import licensure from "../../../assets/images/licensure.svg";
import references from "../../../assets/images/references.svg";
import linkIcon from "../../../assets/images/link-icon.svg";
import ChecklistForm from "./Checklist/ChecklistForm";
import ApiCalls from "../../../api/customAxios";
import checklistIndustry from "../../../assets/images/checklist-new-icon.svg";
import { success, danger } from "../../../helpers/message-box";
import { Helmet } from "react-helmet";
import hourlyRateIcon from "../../../assets/images/payRate-new-icon.svg";
import overExpIcon from "../../../assets/images/exp-new-icon.svg";
import downloadIcon from "../../../assets/images/download-icon.svg";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import availabilityIcon from "../../../assets/images/pool-availability.svg";
import InfoIcon from "@mui/icons-material/Info";
import WorkplaceIcon from "../../../assets/images/workplace.svg";
import WorkTypeIcon from "../../../assets/images/worktype.svg";
import Certification from "./Certification";
import ReferenceName from "./ReferenceName";
import ratingIcon from "../../../assets/images/rating-profile-icon.svg";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import { ReactComponent as PrivateIcon } from "../../../assets/images/private-pool-icon.svg";
import { ReactComponent as PriorityIcon } from "../../../assets/images/priority-pool-icon.svg";
import { ReactComponent as PriorityIconGreen } from "../../../assets/images/starRename.svg";
import { ReactComponent as NewBriefCase } from "../../../assets/images/new-brief-case.svg";
import { ReactComponent as NewLocation } from "../../../assets/images/new-location.svg";
import { ReactComponent as OccupiedIcon } from "../../../assets/images/occupied-calendar.svg";
import { ReactComponent as BacktoJob } from "../../../assets/images/back-to-job.svg";
import { ReactComponent as DetailIcon } from "../../../assets/images/detail-screen.svg";
import TimelineCandidate from "./_JobCandidateDetailsPartials/TimelineCandidate";
import Notes from "./_JobCandidateDetailsPartials/Notes";
import disciplineIcon from "../../../assets/images/education-new-icon.svg";
import specializationIcon from "../../../assets/images/specializationIcon.svg";
import shiftPreferenceIcon from "../../../assets/images/shift-preferance-img.svg";
import jobPreferenceIcon from "../../../assets/images/job-preferance-icon.svg";
import startDateIcon from "../../../assets/images/hc-start-date-icon.svg";
import { useSelector } from "react-redux";
import nueroIcon from "../../../assets/images/nuero-icon.svg";
// import { Tooltip, TooltipTrigger, TooltipContent } from "react-tippy";
import AvailabilityStatusLabels from "../../../Components/shared/AvailabilityStatusLabels";
import KeycloakService from "../../../keycloak";
import ProjectLabelEnhancement from "./_JobCandidateDetailsPartials/ProjectLabelEnhancement";
import UploadResumeModal from "./UploadResumeModal";
import FileUploadModal from "./FileUploadModal";
import DocumentListing from "./DocumentListing";
import { ReactComponent as AddSkills } from "../../../assets/images/addSkills.svg";
import CandidateListActionButton from "./CandidateListActionButton";
import InfiniteScroll from "react-infinite-scroll-component";
import EditTalentActionButton from "../TalentPool/EditTalentActionButton";
import MessageDialogModel from "../../../Components/shared/MessageDialogModel";
import MyCandidateListActionButton from "../MyCandidate/CandidateListActionButton";
import axios from "axios";
import { ReactComponent as ActionIcon } from "../../../assets/images/action-cta-icon.svg";
import TooltipShared from "../../../Components/shared/TooltipShared";
// import GdprRestrictDownloadModal from "../../../Components/shared/GdprRestrictDownloadModal";
import {  FormLabel, FormGroup } from '@mui/material';
import CandidateServiceClass from '../../../Services/Enterprise/Candidate.service';
import { ReactComponent as LocationIcon } from "../../../assets/images/location-new-icon-gray.svg";
import { ReactComponent as JobReferenceNumberIcon } from "../../../assets/images/jobRefNumberIcon.svg";
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import copy from "copy-to-clipboard";
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import noCandidate from "../../../assets/images/NoJobsFound.svg";
import ScreeningAnswerDrawer from "../JobSearch/ScreeningAnswerDrawer";
import { getCredentialingtoken } from "../../../helpers/credentialingToken";
import missingDetails from "../JobSearch/missingDetails";

const label = { inputProps: { "aria-label": "Checkbox demo" } };

export default function ShortlistCandidateDrawer(props) {
  const divRef = useRef(null);
  const inputRef = React.useRef();
  const appOrigin = useSelector((state) => state.location.origin);
  const enterpriseDetails = useSelector((state) => state.enterprise.enterprise);
  const [specializationString, setSpecializationString] = useState("");
  const anchorRef = useRef(null);
  const [openStatus, setOpenStatus] = useState(false);
  const [values, setValues] = useState(moment().add(2, "d").toDate());
  const [currency, setCurrency] = useState("USD");
  const [jobStartDate, setJobStartDate] = useState(null);
  const [duration, setDuration] = useState(null);
  const [jobCompletionDate, setJobCompletionDate] = useState(null);
  const [payRate, setPayRate] = useState("");
  const [workflowUniqueIdentifier, setWorkflowUniqueIdentifier] = useState("");
  const [endDateErr, setEndDateErr] = useState(null);
  const [payRateErr, setPayRateErr] = useState(null);
  const [hideStartDateClearIcon, setHideStartDateClearIcon] = useState(true);
  const [hideEndDateClearIcon, setHideEndDateClearIcon] = useState(true);
  const [anchorEl2, setAnchorEl2] = useState(null);
  const [anchorEl3, setAnchorEl3] = useState(null);
  const [anchorEl4, setAnchorEl4] = useState(null);
  const [keysArray, setKeysArray] = useState([]);
  const [confirmSendSms, setConfirmSendSms] = React.useState(false);
  const [openGDPRDownloadRestrictPopup, setOpenGDPRDownloadRestrictPopup] = useState(false);
  const [mandatoryFieldLengthCheck, setMandatoryFieldLengthCheck] = useState(0);
  const [openFillRequiredFields, setOpenFillRequiredFields] = useState(false);
  const handleCloseFillRequiredFields = () => {
    setOpenFillRequiredFields(false);
    setMandatoryFieldLengthCheck(0);
  };
  const [locationData, setLocationData] = useState([]);
  const [requiredFields, setRequiredFields] = useState({});
  const [profileDetailsGroup, setprofileDetailsGroup] = useState([]);
  const [profileDetails, setProfileDetails] = useState([]);
  const [educationData, setEducationData] = useState([]);

  const handleOpenJobType = (event) => {
    setAnchorEl2(event.currentTarget);
    functiondisable();
  };

  const handleCloseJobType = () => {
    setAnchorEl2(null);
    functionenable();
  };
  const sortByGroupOrder = (group, values) => {
    return values.filter((value) => group.includes(value)).sort((a, b) => {
      return group.indexOf(a) - group.indexOf(b);
    });
  };
  const fieldMapping = {
    dateOfBirth: "Date of Birth",
    mobileNumber: "Phone Number from Settings",
    address: "Address",
    city: "Location",
    nationality: "Nationality",
    state: "Location",
    country: "Location",
    zipCode: "Zip Code",
    payRate: "Pay Rate",
    availableDate: "Available Date",
    gender: "Gender",
    ethnicity: "Ethnicity",
    militaryStatus: "Military Status",
    wageExpectations: "Wage Expectations",
    shifts: "What shift are you willing to work?",
    totalExperience: "Total Experience",
    workPlace: "WorkPlace",
    workType: "Work Type",
    knownAs: "Known as",
    profileSummary: "Profile Summary",
    employerName: "Employer Name",
    nameOfUniversity: "Education",
    grade: "Education",
    certifiedWhen: "Certification Date",
    currency: "Currency",
    resume: "Resume",
    modeOfEducation: "Education",
    levelOfEducation: "Education",
    nameOfDegree: "Education",
    institutionName: "Education"
  };

  const openJobType = Boolean(anchorEl2);
  const baseURL = window.location.origin;
  const [valueTabs, setValueTabs] = React.useState("RecommendedJobs");
  const [selectedJob, setSelectedJob] = React.useState("");
  const [loadMoreJobs, setLoadMoreJobs] = useState(true);
  const [recommendedJobs, setRecommendedJobs] = React.useState([]);
  const [lastTabName, setLastTabName] = useState("RecommendedJobs");
  const [hasFetched, setHasFetched] = useState(false);
  const [recommendedJobCount, setRecommendedJobCount] = useState(0);
  const [allJobCount, setAllJobCount] = useState(0);
  const [isSearched, setIsSearched] = useState(false);
  const fetchRecommendedJobs = (tabname, pageNumber=0, searchedValue="", isFromClearSearch = false) => {
    setIsLoading(true);
    const candidateId = props.candidate_id;
    const value = tabname ? (tabname == "allJobs" ? true : false) : false;
    CandidateServiceClass.candidateRecommendedJobs(candidateId, pageNumber, value, searchedValue).then((response) => {
      if (response?.length < 10) {
        setLoadMoreJobs(false);
      } else {
        setLoadMoreJobs(true);
      }
      const jobsArray = response?.jobs || [];
      const jobsCount = response?.count;
      const jobsOptions = Array.isArray(jobsArray) ? jobsArray.map((job) => ({
        id: job.id,
        name: job.jobTitle,
        jobDescriptionText: job.jobDescriptionText,
        city: job.city,
        state: job.state,
        country: job.country,
        jobReferenceNumber: job.jobReferenceNumber,
        isChecked: false,
        status: job.status,
        validTill: job.validTill,
      })) : [];
      if (isFromClearSearch) {
        setRecommendedJobs(jobsOptions);
      } else if (tabname === lastTabName) {
        setRecommendedJobs(jobsCount === 0 ? jobsOptions : [...recommendedJobs, ...jobsOptions]);
      } else {
        // If tab has changed, reset recommended jobs to only include the new jobsOptions
        setRecommendedJobs(jobsOptions);
      }
      setLastTabName(tabname);
      // setRecommendedJobs(jobsCount === 0 ? jobsOptions : [...recommendedJobs, ...jobsOptions]);
      console.log(recommendedJobs);
      console.log(jobsOptions);
      setIsLoading(false);
    });
  };
  const fetchRecommendedJobsForRehire = (tabname, pageNumber=0, searchedValue="", isFromClearSearch = false) => {
    setIsLoading(true);
    const candidateId = props.candidate_id;
    const value = tabname ? (tabname == "allJobs" ? true : false) : false;
    CandidateServiceClass.candidateRecommendedJobsForRehire(candidateId, pageNumber, value, searchedValue).then((response) => {
      if (response?.length < 10) {
        setLoadMoreJobs(false);
      } else {
        setLoadMoreJobs(true);
      }
      const jobsArray = response?.jobs || [];
      const jobsCount = response?.count;
      const jobsOptions = Array.isArray(jobsArray) ? jobsArray.map((job) => ({
        id: job.id,
        name: job.jobTitle,
        jobDescriptionText: job.jobDescriptionText,
        city: job.city,
        state: job.state,
        country: job.country,
        jobReferenceNumber: job.jobReferenceNumber,
        isChecked: false,
        status: job.status,
        validTill: job.validTill,
      })) : [];
      if (isFromClearSearch) {
        setRecommendedJobs(jobsOptions);
      } else if (tabname === lastTabName) {
        setRecommendedJobs(jobsCount === 0 ? jobsOptions : [...recommendedJobs, ...jobsOptions]);
      } else {
        setRecommendedJobs(jobsOptions);
      }
      setLastTabName(tabname);
      // setRecommendedJobs(jobsCount === 0 ? jobsOptions : [...recommendedJobs, ...jobsOptions]);
      console.log(recommendedJobs);
      console.log(jobsOptions);
      if (value == true) {
        CandidateServiceClass.candidateRecommendedJobsForRehire(candidateId, pageNumber, false, searchedValue).then((response) => {
        setRecommendedJobCount(response.count);
        });
      } else {
        CandidateServiceClass.candidateRecommendedJobsForRehire(candidateId, pageNumber, true, searchedValue).then((response) => {
        setAllJobCount(response.count);
        });
      }
      setTimeout(() => {
      value == true ? setAllJobCount(response.count) : setRecommendedJobCount(response.count);
      }, 500);
      setIsLoading(false);
    });
  };
  const fetchMoreJobs = () => {
    // if (searchedValue !== "") {
    //   return;
    // }
    const nextPage = pageNumber + 1;
    setPageNumber(nextPage);
    props?.isFromRehire ? fetchRecommendedJobsForRehire(valueTabs, nextPage, searchedValue) : fetchRecommendedJobs(valueTabs, nextPage, searchedValue);
  };
  const handleEnterSearchKey = (e) => {
    setSearchedValue(e.target.value);
    if (
        e.key === "Enter" &&
        e.target.value !== ""
    ) {
        setIsSearched(true);
        e.preventDefault();
        props?.isFromRehire ? fetchSearchedRehireJobs(valueTabs, 0, e.target.value) : fetchSearchedJobs(valueTabs, 0, e.target.value);
    }
    if (
        e.type === "input" &&
        e.target.value === "" &&
        previouslySearchedKeyword !== ""
    ) {
        setIsSearched(true);
        e.preventDefault();
        props?.isFromRehire ? fetchSearchedRehireJobs(valueTabs, 0, e.target.value) : fetchSearchedJobs(valueTabs, 0, e.target.value);
    }
};
  const fetchSearchedJobs = (tabname, pageNumber=0, searchValue="") => {
    setIsLoading(true);
    setPreviouslySearchedKeyword(searchValue);
    const candidateId = props.candidate_id;
    const value = tabname ? (tabname == "allJobs" ? true : false) : false;
    CandidateServiceClass.candidateRecommendedJobs(candidateId, pageNumber, value, searchValue).then((response) => {
      if (response?.length < 10) {
        setLoadMoreJobs(false);
      } else {
        setLoadMoreJobs(true);
      }
      const jobsArray = response?.jobs || [];
      const jobsCount = response?.count;
      const jobsOptions = Array.isArray(jobsArray) ? jobsArray.map((job) => ({
        id: job.id,
        name: job.jobTitle,
        jobDescriptionText: job.jobDescriptionText,
        city: job.city,
        state: job.state,
        country: job.country,
        jobReferenceNumber: job.jobReferenceNumber,
        isChecked: false,
        status: job.status,
        validTill: job.validTill,
      })) : [];
      // setRecommendedJobs([...recommendedJobs, ...jobsOptions]);
      setRecommendedJobs(jobsCount === 0 ? [] : jobsOptions);
      // if (tabname === lastTabName) {
      //   setRecommendedJobs(jobsCount === 0 ? jobsOptions : [...recommendedJobs, ...jobsOptions]);
      // } else {
      //   // If tab has changed, reset recommended jobs to only include the new jobsOptions
      //   setRecommendedJobs(jobsOptions);
      // }
      // setLastTabName(tabname);
      console.log(recommendedJobs);
      console.log(jobsOptions);
      if (value == true) {
        CandidateServiceClass.candidateRecommendedJobs(candidateId, pageNumber, false, searchValue).then((response) => {
        setRecommendedJobCount(response.count);
        });
      } else {
        CandidateServiceClass.candidateRecommendedJobs(candidateId, pageNumber, true, searchValue).then((response) => {
        setAllJobCount(response.count);
        });
      }
      setTimeout(() => {
      value == true ? setAllJobCount(response.count) : setRecommendedJobCount(response.count);
      }, 500);
      setIsLoading(false);
    });
  };
  const fetchSearchedRehireJobs = (tabname, pageNumber=0, searchValue="") => {
    setIsLoading(true);
    setPreviouslySearchedKeyword(searchValue);
    const candidateId = props.candidate_id;
    const value = tabname ? (tabname == "allJobs" ? true : false) : false;
    CandidateServiceClass.candidateRecommendedJobsForRehire(candidateId, pageNumber, value, searchedValue).then((response) => {
      if (response?.length < 10) {
        setLoadMoreJobs(false);
      } else {
        setLoadMoreJobs(true);
      }
      const jobsArray = response?.jobs || [];
      const jobsCount = response?.count;
      const jobsOptions = Array.isArray(jobsArray) ? jobsArray.map((job) => ({
        id: job.id,
        name: job.jobTitle,
        jobDescriptionText: job.jobDescriptionText,
        city: job.city,
        state: job.state,
        country: job.country,
        jobReferenceNumber: job.jobReferenceNumber,
        isChecked: false,
        status: job.status,
        validTill: job.validTill,
      })) : [];
      // setRecommendedJobs([...recommendedJobs, ...jobsOptions]);
      setRecommendedJobs(jobsCount === 0 ? [] : jobsOptions);
      // if (tabname === lastTabName) {
      //   setRecommendedJobs(jobsCount === 0 ? jobsOptions : [...recommendedJobs, ...jobsOptions]);
      // } else {
      //   // If tab has changed, reset recommended jobs to only include the new jobsOptions
      //   setRecommendedJobs(jobsOptions);
      // }
      // setLastTabName(tabname);
      console.log(recommendedJobs);
      console.log(jobsOptions);
      if (value == true) {
        CandidateServiceClass.candidateRecommendedJobsForRehire(candidateId, pageNumber, false, searchValue).then((response) => {
        setRecommendedJobCount(response.count);
        });
      } else {
        CandidateServiceClass.candidateRecommendedJobsForRehire(candidateId, pageNumber, true, searchValue).then((response) => {
        setAllJobCount(response.count);
        });
      }
      setTimeout(() => {
      value == true ? setAllJobCount(response.count) : setRecommendedJobCount(response.count);
      }, 500);
      setIsLoading(false);
    });
  };
  const handleChangeTabs = (event, newValueTabs) => {
    setValueTabs(newValueTabs);
    setSelectedValues({
      profile: true,
      checkList: [],
      resumes: [],
    });
    setEnterpriseSelectedFiles([]);
    setEnterpriseSelectedResumes([]);
    setAllDocuments([]);
    setPageNumber(0);
    setResumeDocuments([]);
    setOtherDocuments([]);
    setSelectedJob("");
    setRecommendedJobs([]);
    props?.isFromRehire ? fetchRecommendedJobsForRehire(newValueTabs, 0, searchedValue) : fetchRecommendedJobs(newValueTabs, 0, searchedValue);
  };
  const handleJobChange = (event) => {
    setSelectedJob(event.target.value);
  };
  useEffect(() => {
    if (valueTabs === "RecommendedJobs" && !hasFetched && props.isFromRehire) {
      fetchRecommendedJobsForRehire("RecommendedJobs");
      setHasFetched(true);
    }
    if (valueTabs === "RecommendedJobs" && !hasFetched) {
      fetchRecommendedJobs("RecommendedJobs");
      setHasFetched(true);
    }
  }, [valueTabs, hasFetched]);
  const [value, setValue] = useState(4);
  let navigate = useNavigate();
  const isAuthenticated = KeycloakService.isLoggedIn();
  const { state } = useLocation();
  const LicenseStatus = {
    A: "Current License",
    I: "Inactive License",
    P: "License Applied",
  };
  const LicenseType = {
    L: "License is limited",
    U: "License is unlimited",
  };
  let candidateApplicationD;
  if (state) {
    candidateApplicationD = state.candidateApplicationData;
  } else {
    candidateApplicationD = undefined;
  }
  // const { candidateApplicationData } = state;

  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const [openAssessment, setOpenAssessment] = React.useState(false);
  const handleOpenAssessment = () => setOpenAssessment(true);
  const handleCloseAssessment = () => setOpenAssessment(false);

  const [openOffer, setOpenOffer] = useState(false);
  const [showMore, setShowMore] = useState(false);
  const [viewOfferDrawer, setViewOfferDrawer] = useState(false);
  const [showMoreWorkSummary, setShowMoreWorkSummary] = useState([]);

  const [isLoading, setIsLoading] = useState(false);
  const [questions, setQuestions] = useState([]);
  const [profileEssentials, setProfileEssentials] = useState([]);
  const [openScreeningQuestionDrawer, setOpenScreeningQuestionDrawer] = useState(false);
  const handleOpenDrawer = () => {
    setOpenScreeningQuestionDrawer(true);
    setIsDrawerVisible(false);
  };
  const [searchedValue, setSearchedValue] = useState("");
  const [previouslySearchedKeyword, setPreviouslySearchedKeyword] = useState("");
  const [job, setJob] = React.useState({});
  const [candidate, setCandidate] = React.useState({});
  const [candidateApplicationData, setCandidateApplication] = useState(
    candidateApplicationD === null ? undefined : candidateApplicationD
  );
  const [candidateChecklist, setCandidateChecklist] = useState([]);
  const [selectedCandidateChecklist, setSelectedCandidateChecklist] = useState(
    []
  );
  const [showCandidateChecklistOverlay, setShowCandidateChecklistOverlay] =
    useState(false);
  const [shortlistingRemarks, setShortlistingRemarks] = useState("");
  const [sendOfferEmailChecked, setSendOfferEmailChecked] = useState(true);
  const [answers, setAnswers] = useState([]);
  const params = useParams();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const [openRatingAndReview, setRatingAndReview] = useState(true);
  const ratingAndReviewsRef = React.useRef(null);
  const [message, setMessage] = useState("");

  const [videoSrc, setVideoSrc] = useState(null);
  const [openVideoPreview, setOpenVideoPreview] = useState(false);
  const [candidateName, setCandidateName] = useState(null);
  const [downloadPopup, setDownloadPopup] = useState(false);
  const [selectedValues, setSelectedValues] = useState({
    profile: true,
    checkList: [],
    resumes: [],
  });
  const [openAddResumeModal, setOpenAddResumeModal] = useState(false);
  const [openAddFileModal, setOpenAddFileModal] = useState(false);
  const [resumeDocuments, setResumeDocuments] = useState([]);
  const [otherDocuments, setOtherDocuments] = useState([]);
  const [enterpriseSelectedResumes, setEnterpriseSelectedResumes] = useState([]);
  const [enterpriseSelectedFiles, setEnterpriseSelectedFiles] = useState([]);
  const [loadMore, setLoadMore] = useState(true);
  const [isDrawerVisible, setIsDrawerVisible] = useState(true);
  const [allDocuments, setAllDocuments] = useState([]);
  const [pageNumber, setPageNumber] = useState(0);
  const size = 10;
  // const [searchParams, setSearchParams] = useSearchParams();
  const intervalRef = useRef(null);
  const resumeRef = useRef(null);
  const handleOpenVideoPreview = (profileVideo, candidateName) => {
    setVideoSrc(profileVideo);
    setCandidateName(candidateName);
    setOpenVideoPreview(true);
  };
  const openRatingAndReviewSection = () => {
    setRatingAndReview(true);
    // setRatingAndReview(!openRatingAndReview);
    if (openRatingAndReview) {
      ratingAndReviewsRef?.current?.scrollIntoView();
    }
  };
  const translateLicenseStatus = (status) => {
    return LicenseStatus[status] || status;
  };
  const translateLicenseType = (type) => {
    return LicenseType[type] || type;
  };
  const handleGotoJobsList = () => {
    navigate("/enterprise/JobList");
  };

  const handleGotoJob = () => {
    navigate(
      "/enterprise/jobs/" +
        props.job_id +
        "/matching-candidates?tab=" +
        props.status
    );
  };
  // useEffect(() => {
  //   //for capturing status
  //   let data = {
  //     candidateId: props.candidate_id,
  //     isProfileViewed: true,
  //   };
  //   CandidateService.getStatus(data).then((res) => {});
  // }, []);
  useEffect(() => {
    const bodyElement = document.body;
    const htmlElement = document.documentElement;
    if (props.candidateProfileDrawer) {
      bodyElement.classList.add('overflow-hidden');
      htmlElement.classList.add('overflow-hidden');
    } else {
      bodyElement.classList.remove('overflow-hidden'); // Ensure class is removed
      htmlElement.classList.remove('overflow-hidden'); // Ensure class is removed
    }
    return () => {
      bodyElement.classList.remove('overflow-hidden');
      htmlElement.classList.remove('overflow-hidden');
    };
  }, [props.candidateProfileDrawer]);
  useEffect(() => {
    setValueTabs(props.optionValue ? props.optionValue : props.toProfile ? "RecommendedJobs" : "RecommendedJobs");
  }, [props.candidateProfileDrawer, props.optionValue, props.isFromTalentConsole, props.toProfile]);
  useEffect(() => {
    if (searchedValue === null || searchedValue === '') {
      setIsSearched(false);
      setAllJobCount(0);
      setRecommendedJobCount(0);
    }
  }, [searchedValue]);
  const fetchData = async () => {
    setIsLoading(true);
    try {
      const response = await (props?.isFromMyCandidates
        ? ApiCalls.get(
            `enterprises/user/my-candidates/details/${
              props.candidate_id
            }?enterpriseCandidateId=${localStorage.getItem(
              "candidateId"
            )}&enterpriseId=${localStorage.getItem("childEnterpriseId") ? localStorage.getItem("childEnterpriseId") : localStorage.getItem("enterpriseId")}`
          )
        : props.job_id
        ? CandidateService.getCandidateAllDetails(props.candidate_id, props.job_id, false)
        : CandidateService.getCandidateDetails(props.candidate_id, props.jobId));
      setCandidate(response);
      let specialization = [];
      response?.skills?.map((skill, index) => {
        specialization.push(skill.skills.keyword);
      });
      setSpecializationString(specialization.join(", "));
      setCandidateApplication(response);
      if (isAuthenticated && response?.candidateSkillChecklistResponse?.length > 0) {
        setCandidateChecklist([...response?.candidateSkillChecklistResponse]);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };
  // useEffect(() => {
  //   fetchData();
  // }, []);
  useEffect(() => {
    if (props.candidateProfileDrawer) {
      fetchData();
    }
  }, [props.candidateProfileDrawer, props.candidate_id]);

  function workDuration(workedFrom, workedTo) {
    var currentDate = moment().format("YYYY-MM-DD");
    var start = moment(workedFrom, "YYYY-MM-DD");
    var end = moment(
      workedTo === "1900-01-01" ? currentDate : workedTo,
      "YYYY-MM-DD"
    );
    var years = end.diff(start, "years");
    var months = end.diff(start, "months");
    var output = "";
    if (years > 0 && months === 0) {
      output =
        years === 0 ? "" : `${years} ${years === 1 ? "Year " : "Years "}`;
    }
    if (years === 0 && months > 0) {
      output =
        months === 0
          ? "1 month"
          : `${months} ${months === 1 ? "month " : "months "}`;
    }
    if (years === 0 && months === 0) output = "1 month";
    if (years > 0 && months % years === 0) {
      output = `${years} ${years === 1 ? "Year " : "Years "}`;
    }
    if (years > 0 && months % 12 > 0) {
      output =
        `${years} ${years === 1 ? "Year " : "Years "}` +
        `${months % 12} ${months % 12 === 1 ? "month " : "months "}`;
    }

    return output;
  }
  const goToAssessment = () => {
    navigate("/enterprise/assessment");
  };

  const [candidatePopUp, setCandidatePopUp] = useState();
  const [preferredExp, setPreferredExp] = useState(null);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open3 = Boolean(anchorEl);
  const id = open3 ? "simple-popover" : undefined;

  const handleClick = (event, candidateApplicationD) => {
    setCandidatePopUp(candidateApplicationD);
    setAnchorEl(event.currentTarget);
    functiondisable();
  };

  const handleClosePopover = () => {
    setAnchorEl(null);
    functionenable();
    setSelectedJob("");
    document.body.style.overflow = "";
    document.documentElement.style.overflow = "";
    props.setCandidateProfileDrawer(false);
    setSearchedValue("");
    props?.isFromRehire ? fetchRecommendedJobsForRehire(valueTabs, 0, "", true) : fetchRecommendedJobs(valueTabs, 0, "", true);
    setTimeout(function () {
      setIsDrawerVisible(true);
    }, 500);
    // props.setCandidateListId("");
  };

  const getFormattedSkills = (skills) => {
    let s = skills.split(",");
    if (s.length > 1) {
      let skl = "";
      s.map((o, i) => {
        skl += o + ", ";
        if ((i + 1) % 2 === 0) {
          skl += "\n  ";
        }
      });

      return skl.trim().slice(0, -1);
    }

    return skills;
  };

  // if (!candidateApplicationData) {
  //   return <Loader />;
  // }

  const ratingChanged = (newRating) => {};
  const ProflieStatusCapture = () => {
    //for capturing status
    let data = {
      candidateId: props.candidate_id,
      isProfileDownloaded: true,
    };
    CandidateService.getStatus(data).then((res) => {});
  };
  const ResumeStatusCapture = () => {
    //for capturing status
    let data = {
      candidateId: props.candidate_id,
      isResumeDownloaded: true,
    };
    CandidateService.getStatus(data).then((res) => {});
  };
  function updatedstatus(e) {
    ApiCalls.get(`enterprises/pool/candidates/statistics/${params.job_id}`)
      .then((res) => {
        props.setJobStatistics(res);
      })
      .catch((err) => {});
  }
  const DownloadProfile = () => {
    if ((candidate.firstName === "****" &&
      (candidate.lastName !== null ||
      candidate.lastName !== "" ||
      candidate.lastName === "****"))) {
      setOpenGDPRDownloadRestrictPopup(true);
      return;
    }
    let obj = {
      profile: selectedValues.profile,
      checkList: selectedValues.checkList,
      enterpriseId: enterpriseDetails.id
    };
    if (selectedValues.profile || selectedValues.checkList.length > 0) {
      CandidateService.getPdfs(props.candidate_id, obj)
        .then((response) => {
          const data = [...response, ...selectedValues.resumes, ...enterpriseSelectedFiles, ...enterpriseSelectedResumes];
          downloadPdf(data);
          success("File downloaded successfully.");
        })
        .catch((error) => {
          danger("Something went wrong.");
        });
    }
    if (!selectedValues.profile && !selectedValues.checkList.length) {
      ResumeStatusCapture();
      const data = [...selectedValues.resumes, ...enterpriseSelectedFiles, ...enterpriseSelectedResumes];
      downloadPdf(data);
    }
    if (
      selectedValues.profile &&
      !selectedValues.resumes.length &&
      !selectedValues.checkList.length
    ) {
      ProflieStatusCapture();
    }
    if (
      (selectedValues.profile &&
        selectedValues.resumes.length &&
        selectedValues.checkList.length) ||
      (selectedValues.profile && selectedValues.resumes.length)
    ) {
      let data = {
        candidateId: props.candidate_id,
        isResumeDownloaded: true,
        isProfileDownloaded: true,
      };
      CandidateService.getStatus(data).then((res) => {});
    }
    setDownloadPopup(false);
    setSelectedValues({ profile: true, checkList: [], resumes: [] });
    setEnterpriseSelectedFiles([]);
    setEnterpriseSelectedResumes([]);
  };

  const handleSelectCheklist = (e, value) => {
    let check = e.target.checked;
    if (check) {
      selectedValues.checkList.push(value);
      setSelectedValues({
        ...selectedValues,
        checkList: [...selectedValues.checkList],
      });
    } else {
      var idsList = selectedValues.checkList.filter((compare) => {
        return compare != value;
      });
      setSelectedValues({ ...selectedValues, checkList: [...idsList] });
    }
  };
  const handleSelectResumes = (e, value) => {
    let check = e.target.checked;
    if (check) {
      selectedValues.resumes.push({
        name: value.title,
        urlSigned: value.urlSigned,
        id: value.resumeId,
      });
      setSelectedValues({
        ...selectedValues,
        resumes: [...selectedValues.resumes],
      });
    } else {
      var idsList = selectedValues.resumes.filter((compare) => {
        return compare.id != value.resumeId;
      });
      setSelectedValues({ ...selectedValues, resumes: [...idsList] });
    }
  };
  const handleSelectCheklist1 = (value) => {
    if (selectedValues.checkList && selectedValues.checkList.length > 0) {
      let list = selectedValues.checkList.filter((list) => list == value);
      if (list.length > 0) {
        var idsList = selectedValues.checkList.filter((compare) => {
          return compare != value;
        });
        setSelectedValues({ ...selectedValues, checkList: [...idsList] });
      } else {
        setSelectedValues({
          ...selectedValues,
          checkList: [...selectedValues.checkList, value],
        });
      }
    } else {
      setSelectedValues({
        ...selectedValues,
        checkList: [...selectedValues.checkList, value],
      });
    }
  };
  const handleSelectResumes1 = (value) => {
    if (selectedValues.resumes && selectedValues.resumes.length > 0) {
      let list = selectedValues.resumes.filter(
        (list) => list.id == value.resumeId
      );
      if (list.length > 0) {
        var idsList = selectedValues.resumes.filter((compare) => {
          return compare.id != value.resumeId;
        });
        setSelectedValues({ ...selectedValues, resumes: [...idsList] });
      } else {
        let x = {
          name: value.title,
          urlSigned: value.urlSigned,
          id: value.resumeId,
        };
        setSelectedValues({
          ...selectedValues,
          resumes: [...selectedValues.resumes, x],
        });
      }
    } else {
      let y = {
        name: value.title,
        urlSigned: value.urlSigned,
        id: value.resumeId,
      };
      setSelectedValues({
        ...selectedValues,
        resumes: [...selectedValues.resumes, y],
      });
    }
  };
  const downloadPdf = (data) => {
    function download_next(i) {
      if (i >= data.length) {
        return;
      }
      var a = document.createElement("a");
      a.href = data[i].urlSigned;
      if ("download" in a) {
        a.download = data[i].urlSigned;
      }
      (document.body || document.documentElement).appendChild(a);
      if (a.click) {
        a.click();
      } else {
        window.open(data[i].urlSigned);
      }
      a.parentNode.removeChild(a);
      setTimeout(function () {
        download_next(i + 1);
      }, 1500);
    }
    download_next(0);
  };
  const goToCandidatePage = () => {
    if (location.pathname.includes("my-candidate")) {
      navigate("/enterprise/my-candidate");
    } else {
      navigate(
        isAuthenticated
          ? "/enterprise/CandidateList" + location.search
          : "/CandidateList" + location.search
      );
    }
  };
  const clearSearch = () => {
    setSearchedValue("");
    setIsSearched(false);
    setAllJobCount(0);
    setRecommendedJobCount(0);
    props?.isFromRehire ? fetchRecommendedJobsForRehire(valueTabs, 0, "", true) : fetchRecommendedJobs(valueTabs, 0, "", true);
    setPreviouslySearchedKeyword("");
    // setPage(1);
};
  const gotToAddCandidate = () => {
    navigate("/enterprise/talent-pool-candidate-list" + location.search);
  };
  const copyToClipboard = (event, jobReferenceNumber) => {
    event.stopPropagation();
    event.preventDefault();
    copy(jobReferenceNumber);
    success("Reference number is copied.");
  };

  const handleSkipAndSendOffer = () => {
    if (jobStartDate != null) {
      if (new Date(values).setHours(0, 0, 0, 0) > new Date(jobStartDate).setHours(0, 0, 0, 0)) {
      danger("Offer expiry date cannot be greater than job start date.");
      return;
    };
  };
    if (process.env.REACT_APP_INTEGRATE_CREDENTIALING === "Y" && workflowUniqueIdentifier) {
      callcredentaling();
    } else {
      createOfferHandlerUpload();
    }
  };
  const createOfferHandler = async () => {
    if (jobStartDate != null) {
        if (new Date(values).setHours(0, 0, 0, 0) > new Date(jobStartDate).setHours(0, 0, 0, 0)) {
        danger("Offer expiry date cannot be greater than job start date.");
        return;
      };
    };
    setOpenOffer(false);
    setViewOfferDrawer(true);
  };
  const createOfferHandlerUpload = async (invitationCode) => {
    // props.setIsLoading(true);
    const formData = new FormData();
    const requestBody = {
      jobId: selectedJob,
      candidateId: props.candidate_id ? props.candidate_id : props.candidate.candidateId,
      workflowUniqueIdentifier: invitationCode || null,
      offerExpiryDate: values,
      jobStartDate: jobStartDate,
      jobCompletionDate: jobCompletionDate,
      payRate: payRate ? parseFloat(payRate) : null,
      currency: currency,
    };
    formData.append(
      "request-body",
      new Blob([JSON.stringify(requestBody)], { type: "application/json" })
    );
    try {
      const response = await CandidateService.createOffer(formData, sendOfferEmailChecked);
      setOpenOffer(false);
      console.log("API Response:", response);
      success("Candidate is offered");
      handleClosePopover();
      // await getCandidatesByStatus(props.status);
    } catch (error) {
      // Handle errors
      console.error("Error:", error);
      if (error.statuscode === 500) {
        danger("Oops! Something went wrong. Please try again later.");
      } else {
        danger("Something went wrong");
      }
    } finally {
      props.setIsLoading(false);
    }
  };
  const callcredentaling = async () => {
    try {
      const data = await getCredentialingtoken();
      const firstName = candidate.firstName;
      const lastName = candidate.lastName;
      if (data) {
        const payload = {
          "workflowId": parseInt(workflowUniqueIdentifier),
          "externalRefId": selectedJob,
          "candidateDetail": {
            "firstName": firstName,
            "lastName": lastName ? lastName : "",
            "displayName": firstName + " " + (lastName ? lastName : ""),
            "emailId": "",
            "phoneNo": 0,
            "authType": "link",
            "password": "link"
          }
        };
        const response = await axios.post(`${process.env.REACT_APP_CREDENTIALING_MDM_SERVICE_URL}/credentialing/v1/api/assign/candidate/workflow`, payload, {
          headers: {
            Authorization: `Bearer ${data}`,
          },
        });
         createOfferHandlerUpload((response.data?.payload?.invitationCode));
      } else {
        // Handle the case where data is not available or falsy
        console.error("Credentialing token data is empty or falsy.");
        props.setIsLoading(false);
      }
    } catch (error) {
      // Handle errors from getCredentialingtoken
      console.error("Error while fetching credentialing token:", error);
      props.setIsLoading(false);
    }
  };
  const handleCheckboxChange = (event) => {
    setSendOfferEmailChecked(event.target.checked);
  };
  const toggleCheckbox = () => {
    setSendOfferEmailChecked((prevChecked) => !prevChecked);
  };
  const gotToTalentPoolView = () => {
    navigate(
      `/enterprise/talent-pool/${
        searchParams.get("talentPoolId")
          ? searchParams.get("talentPoolId")
          : props.id
      }`
    );
  };
  const goToTalentPoolListing = () => {
    navigate(`/enterprise/talent-pool-management`);
  };
  const handleChangeReview = (newValue) => {
    setValueTabs(newValue);
  };
  const getFormattedWorkplace = (workPlace) => {
    let work_place = workPlace?.split(",");
    if (work_place.length === 0) {
      return "-";
    }
    let formatted_work_place = [];
    if (work_place.includes("R")) {
      formatted_work_place.push("Remote");
    }
    if (work_place.includes("O")) {
      formatted_work_place.push("Onsite");
    }
    if (work_place.includes("H")) {
      formatted_work_place.push("Hybrid");
    }
    return formatted_work_place.join(", ");
  };
  const getFormattedWorkType = (workType, viewAll) => {
    let work_type = workType?.replace(/\s|\[|\]/g, "")
      ? workType.replace(/\s|\[|\]/g, "").split(",")
      : [];
    if (work_type.length === 0) {
      return "-";
    }
    let formatted_work_type = [];
    if (work_type.includes("FT")) {
      formatted_work_type.push("Full Time");
    }
    if (work_type.includes("PT")) {
      formatted_work_type.push("Part Time");
    }
    if (work_type.includes("FL")) {
      formatted_work_type.push("Contingent / Contract");
    }
    if (work_type.includes("IN")) {
      formatted_work_type.push("Internship");
    }
    if (work_type.includes("OT")) {
      formatted_work_type.push("Other");
    }
    if (!viewAll && formatted_work_type.length > 1) {
      formatted_work_type = formatted_work_type.slice(0, 1);
    }
    return formatted_work_type.join(", ");
  };
  // const getCandidatesByStatus=(tabStatus) => {
  //   if (tabStatus === "shortlisted") {
  //           props.getCandidatesByStatus("S", true);
  //         } else if (tabStatus === "offered")  {
  //           props.getCandidatesByStatus("F", true);
  //         } else {
  //           props.getCandidatesByStatus("A", true);
  //         }
  // };
  let date = moment().format("YYYY-MM-DD");
  const getExclusiveEnterprise = (id) => {
    const currentEnterprise = localStorage.getItem("childEnterpriseId") ? localStorage.getItem("childEnterpriseId") : localStorage.getItem("enterpriseId");
    const enterprisesList = JSON.parse(localStorage.getItem("enterprisesList"));
    const childEnterprisesList = JSON.parse(localStorage.getItem("mspEnterprsieData"))?.childEnterprises;
    const childEnterprise = childEnterprisesList?.find((ent) => ent.id == id);
    const enterprise = enterprisesList?.find((ent) => ent.id == id);
    return currentEnterprise != id ? (enterprise ? "To " + enterprise?.establishmentName : childEnterprise ? "To " + childEnterprise?.establishmentName : "") : "";
  };
  const goToProjectTab = (e) => {
    e.stopPropagation();
    e.preventDefault();
    setValueTabs("ProjectDetails");
    setSelectedValues({
      profile: true,
      checkList: [],
      resumes: [],
    });
  };
  const Group1 = ["Profile Summary", "Pay Rate", "WorkPlace", "Work Type", "Resume", "What shift are you willing to work?"];
    const Group2 = [
        "Address",
        "Zip Code",
        "Location",
        "Nationality",
        "Known as",
        "Date of Birth",
        "Ethnicity",
        "Wage Expectations",
        "Military Status",
        "Gender",
        "Total Experience",
        "Phone Number from Settings",
    ];
    const handleSubmit = async () => {
      let essentials = false;
      setIsLoading(true);
      if (candidate?.talentPool === "Z" && (localStorage.getItem("enterpriseId") === candidate?.sourceEnterpriseId)) {
        await ApiCalls.get(process.env.REACT_APP_API_URL +`candidates/missingFields/${props.candidate_id
          ? props.candidate_id
          : props.candidate.candidateId
        }?forExternal=true&jobId=${selectedJob}`
      ).then((res) => {
          let response = [];
          response = JSON.parse(res);
          response = response.filter((i) => {
            return i != "availableDate";
          });
          if (response?.length) {
            let formatted_fields = [];
            let locationFields = [];
            let educationFields = [];
            let locationAdded = false;
            let educationAdded = false;
            response.forEach((field) => {
              const mappedField = fieldMapping[field];
              if (mappedField) {
                if (mappedField === "Location") {
                  locationFields.push(field);
                  if (!locationAdded) {
                    formatted_fields.push(mappedField);
                    locationAdded = true;
                  }
                } else if (mappedField === "Education") {
                  educationFields.push(field);
                  if (!educationAdded) {
                    formatted_fields.push(mappedField);
                    educationAdded = true;
                  }
                } else {
                  formatted_fields.push(mappedField);
                }
              }
            });
            let modifiedResponse = [...response];
              replaceKey('shifts', ['shiftEndTime', 'shiftStartTime', 'shiftTimezone'], modifiedResponse);
              replaceKey('payRate', ['ratePerHour'], modifiedResponse);
              replaceKey('wageExpectations', ['wageExpectationCategory'], modifiedResponse);
              replaceKey('profileSummary', ['brief'], modifiedResponse);
              setKeysArray(modifiedResponse);
              formatted_fields = formatted_fields.filter((i) => i && i.length);
              if (!formatted_fields.length) {
                setOpenFillRequiredFields(false);
                props?.setShowJobDetail(true);
                sendDate();
                return;
              }
              let missingFieldsStr = "";
              const formattedGroup2Fields = sortByGroupOrder(Group2, formatted_fields);
              const formattedGroup1Fields = sortByGroupOrder(Group1, formatted_fields);
              setProfileDetails(formattedGroup2Fields);
              setprofileDetailsGroup(formattedGroup1Fields);
              setLocationData(locationFields);
              setEducationData(educationFields);
              if (formatted_fields?.length === 1) {
                missingFieldsStr =
                  formatted_fields[0];
                setMandatoryFieldLengthCheck(1);
              } else {
                missingFieldsStr =
                  formatted_fields
                    .slice(0, formatted_fields?.length - 1)
                    .join(", ") +
                  " and " +
                  formatted_fields[formatted_fields.length - 1];
                  setMandatoryFieldLengthCheck(2);
              }
              setRequiredFields(missingFieldsStr);
              setOpenFillRequiredFields(true);
            } else {
             // setOpenApplyJobDrawer(true);
              // sendDate();
            }
            if (response?.length) {
            let essentialResponse = JSON.parse(res);
            // essentialResponse = essentialResponse.filter((item) => item !== "resume");
            if (essentialResponse?.length) {
              setProfileEssentials(essentialResponse);
              essentials = true;
              handleOpenDrawer();
            }
          }
          setIsLoading(false);
        });
      }
      AccessService.getJobDetails(selectedJob).then((resp) => {
        const questions = JSON.parse(resp.questions) || [];
        const defaultQuestionsArray = JSON.parse(resp.enterprisePublicDto.defaultScreeningQuestions) || [];
        const questionsArray = [...defaultQuestionsArray, ...questions];
        if (questionsArray && questionsArray.length > 0) {
          setQuestions(questionsArray);
          handleOpenDrawer();
          setIsLoading(false);
      } else {
        setQuestions([]);
        setIsLoading(false);
        if (!essentials) {
          handleShortlist();  // Call handleShortlist only if questions is empty and profileEssentials is empty
        }
      }
      }).catch((error) => {
        console.error("Error occured:", error);
        setIsLoading(false);
      });
      // setIsLoading(false);
      // if (questions?.length === 0 && profileEssentials?.length === 0) {
      //   handleShortlist();
      // }
    };
  const handleOpenOffer = (resp) => {
    setIsLoading(true);
    setPayRate(resp?.hourlyRate);
    setJobStartDate(resp?.validFrom);
    setCurrency(resp?.currency);
    setDuration(resp?.duration);
    setWorkflowUniqueIdentifier(resp?.workflowUniqueIdentifier);
    setJobCompletionDate(formatDate(
      new Date(
        JSON.parse(
          JSON.stringify(resp?.validTill).replace("23:59:59", "00:00:00")
        )
      )
    ));
    setOpen(false);
    // setOpenOffer(true);
    openRehireDrawer();
    setIsLoading(false);
  };
  const handleEndDate = (date, duration) => {
    let valueOFDuration = getDurationValue(duration);
    let durationTime =  getDurationPeriodForDropDown(duration);
      const updatedDate = moment(date).add(valueOFDuration, durationTime.toLowerCase()).subtract(1, 'day').toDate();
      return updatedDate;
  };
  const handleChangeStartDate = (date) => {
   setJobStartDate(date);
   let endDate = duration ?  handleEndDate(date, duration) : jobCompletionDate;
   setJobCompletionDate(endDate);
   let startTo = new Date(endDate);
    let startFrom = new Date(formatDate(date));
    if (jobCompletionDate) {
      if (startFrom > startTo) {
        setEndDateErr("Job end date cannot be lesser than job start date");
      } else {
        setEndDateErr(null);
      }
    } else if (!date) {
      setEndDateErr(null);
    }
  };
  const handleChangeEndDate = (date) => {
    setJobCompletionDate(date);
    if (!date) {
      setEndDateErr(null);
      return;
    }
    let today = new Date();
    let startFrom = new Date(jobStartDate);
    let startTo = new Date(formatDate(date));
    if (jobStartDate) {
      if (startFrom > startTo) {
        setEndDateErr("Job end date cannot be lesser than job start date");
      } else {
        setEndDateErr(null);
      }
    } else {
      if (today > startTo) {
        setEndDateErr("Job end date cannot be lesser than today's date");
      } else {
        setEndDateErr(null);
      }
    }
  };
  const handleCloseOffer = () => {
        // setdocumentFile("");
        setJobStartDate(null);
        setJobCompletionDate(null);
        setPayRate("");
        setEndDateErr(null);
        setPayRateErr(null);
        setIsDrawerVisible(true);
        setOpenOffer(false);
        setValues(moment().add(2, "d").toDate());
      };
  const handleShortlist = () => {
    let payload = {
      "jobId": selectedJob,
      "candidateId": props.candidate_id,
      "shortlistingRemarks": null,
      "answers": null,
    };
    CandidateServiceClass.shortList(payload).then((response) => {
      if (response) {
        success("Candidate shortlisted successfully.");
        setSelectedJob("");
        handleClosePopover(false);
      } else {
        // alert("Error occured");
      }
    }).catch(function (error) {
      if (error) {
        if (error.statusCode == 500 && (error.errorMessage != "Number of shortlisted candidates exceeded the limit")) {
          danger("Oops! Something went wrong. Please try again later.");
        } else {
          danger(error.errorMessage);
        }
      }
    });
  };
  const handleOffer = () => {
    AccessService.getJobDetails(selectedJob).then((resp) => {
        handleOpenOffer(resp);
    }).catch((error) => {
      console.error("Error occured:", error);
    });
  };
  const openRehireDrawer = () => {
    setOpenOffer(true);
    setIsDrawerVisible(false);
  };
  const addNewResumeDocument = () => {
    setOpenAddResumeModal(true);
  };
  const addNewFileDocument = () => {
    setOpenAddFileModal(true);
  };
  const fetchMoreDocumentsOthers = () => {
    let newPageNo = pageNumber + 1;
    setPageNumber(newPageNo);
    const jobIdParam = props.job_id ? props.job_id : undefined;
      setIsLoading(true);
      AccessService.getResumeDocuments(newPageNo, size,  localStorage.getItem("childEnterpriseId") ? localStorage.getItem("childEnterpriseId") : localStorage.getItem("enterpriseId"), props.candidate_id, jobIdParam).then((resp) => {
      if (resp?.length < 10) {
        setLoadMore(false);
      } else {
        setLoadMore(true);
      }
      if (resp?.length > 0) {
        let newResumeDocuments = [];
        let newOtherDocuments = [];
        setAllDocuments([...allDocuments, resp]);
        resp.forEach((data) => {
          if (data.documentCategory === "R") {
            newResumeDocuments.push(data);
          } else {
            newOtherDocuments.push(data);
          }
        });
        setResumeDocuments([...resumeDocuments, ...newResumeDocuments]);
        setOtherDocuments([...otherDocuments, ...newOtherDocuments]);
      }
      setIsLoading(false);
    }).catch((error) => {
      console.error("Error fetching documents:", error);
      setIsLoading(false);
    });
  };
  const handleChangePayRate = (event) => {
    const inputValue = event.target.value;
    if (inputValue.length > 14) {
      return;
    }
    if (inputValue.trimStart() === "") {
      setPayRate(inputValue.trim());
      setPayRateErr(null);
    } else if (!inputValue.match(/^(\d{1,10}|\d{1,10}\.\d{1,3}|\d{1,9}\.\d{1,3}|\.\d{1,3})$/)) {
      setPayRateErr("Please enter a valid pay rate");
      setPayRate(inputValue);
    } else {
      setPayRate((inputValue || inputValue == 0) ? inputValue : null);
      setPayRateErr(null);
    }
  };
  const handleToggle = () => {
    setOpenStatus((prevOpen) => !prevOpen);
  };
  const handleCloseStatus = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }
    setOpenStatus(false);
  };
  function handleListKeyDown(event) {
    if (event.key === "Tab") {
      event.preventDefault();
      setOpenStatus(false);
    } else if (event.key === "Escape") {
      setOpenStatus(false);
    }
  }
  const goToEditCandidate = (e, candidate) => {
    e.preventDefault();
    setOpenStatus(false);
    var candidate_id = candidate.candidateId ? candidate.candidateId : candidate.id ? candidate.id : props.candidate_id;
    candidate_id = window.btoa(candidate_id);
    window.open(
      `/enterprise/edit-candidate-details/${candidate_id}`,
      "_blank"
    );
  };
  const wageExpectations = {
    HR: "Hourly",
    DA: "Daily",
    WK: "Weekly",
    FN: "Fortnightly",
    MN: "Monthly",
    QA: "Quarterly",
    HY: "Half-Yearly",
    AL: "Annually",
    EP: "End of Project",
  };
  return (
    <>
      <Helmet>
        {appOrigin === "N" ? (
          <title>Candidate Detail | {Constants.APP_NAME_NEURODIVERSIFY}</title>
        ) : appOrigin === "H" ? (
          <title>Candidate Detail | {Constants.APP_NAME_DIRECT_HEALTH}</title>
        ) : (
          <title>Candidate Detail | {Constants.APP_NAME_GENERAL}</title>
        )}
        <meta name="description" content={`Candidate Detail | ${Constants.APP_NAME_GENERAL}`} />
        <link
          rel="simplify"
          href={process.env.REACT_APP_AWS_USER_POOL_REDIRECT_SIGN_IN}
        />
      </Helmet>
      <Drawer
        disableEnforceFocus
        transitionDuration={{ enter: 500, exit: 500 }}
        variant="temporary"
        anchor="right"
        open={props.candidateProfileDrawer}
        onClose={() => {
          props.setCandidateProfileDrawer(false);
          props.setCandidateListId("");
        }}
        style={{ height: "100vh", overflow: "auto", margin: "0px 4px" }}
        className={`job-detail-drawer ${isDrawerVisible ? '' : 'hide_side_drawer'}`}
        // className={`job-detail-drawer`}
      >
        <Grid
          item
          lg={12}
          md={12}
          xs={12}
          sx={{ p: 2 }}
          className="talent-pool-status drawer-align"
        >
          <Grid className="d-flex align-center justify-between">
            <Grid className="d-flex align-center">
              <Typography
                onClick={() => {
                  document.body.style.overflow = "";
                  document.documentElement.style.overflow = "";
                  setSelectedJob("");
                  setSearchedValue("");
                  props?.isFromRehire ? fetchRecommendedJobsForRehire(valueTabs, 0, "", true) : fetchRecommendedJobs(valueTabs, 0, "", true);
                  props.setCandidateProfileDrawer(false);
                  // props.setCandidateListId("");
                }}
                className="cursor-hover d-flex"
                style={{ color: "#98A2B3" }}
              >
                <ChevronLeftIcon className="f-30" />
              </Typography>
              <Typography className="heading-text-color f-20 font-weight-600 ml-20">
                {props.drawerTitle ? props.drawerTitle : "Candidates"}
              </Typography>
            </Grid>
            <Typography className="cursor-hover gray7 icon-button d-flex">
              <CloseIcon
                onClick={handleClosePopover}
                onKeyPress={handleClosePopover}
                aria-label="Close icon"
                tabIndex={0}
              />
            </Typography>
          </Grid>
          <Grid
            item
            lg={isAuthenticated ? 12 : 11.5}
            md={isAuthenticated ? 12 : 11.5}
            className={
              isAuthenticated ? "m-0 responsive-block" : "m-0 responsive-block"
            }
          >
            {isLoading && <Loader />}
            <Grid
              item
              lg={12}
              md={12}
              xs={12}
              className={isAuthenticated ? "pr-5 " : " "}
            >
              <Grid className="">
                <Grid className="w-100">
                  <Grid>
                    <Grid
                      item
                      lg={12}
                      md={12}
                      xs={12}
                      className="bg-white d-flex pl-20 pr-20 pt-20 pb-20 jobSearch notification-align"
                    >
                      <Grid
                        item
                        lg={9}
                        md={9}
                        xs={10}
                        className="d-flex galaxy-block"
                      >
                        <Grid item lg={12} md={12} xs={2} className="cross-title">
                          <Grid className="d-flex galaxy-block file-upload">
                            <Typography>
                              <Box className="justify-end d-flex">
                                <Grid item lg={12} md={12} xs={12} className="">
                                  <Grid
                                    item
                                    lg={12}
                                    md={12}
                                    xs={12}
                                    className="profile-pic justify-content-start"
                                  >
                                    {(candidate.mediaFile === "****" ||
                                      candidate.mediaFileSigned === null ||
                                      candidate.mediaFileSigned === "") && (
                                        <Typography
                                          className="default-img-border justify-center align-center d-flex primary-shadow-color bg-input f-30 font-weight-700 text-capitalize profileImage-crossSell"
                                          width="100px"
                                          height="100px"
                                          left="2px"
                                          aria-label="Default image"
                                        >
                                          <TooltipShared title={(candidate.firstName === "****" &&
                                            (candidate.lastName !== null || candidate.lastName !== "" || candidate.lastName === "****"))
                                            ? Constants.GDPR_TOOLTIP
                                            : ""} className="d-inline-flex">
                                          {candidate.firstName.trim().substring(0, 1)}
                                          </TooltipShared>
                                        </Typography>
                                      )}
                                    {candidate.mediaFile !== "****" &&
                                      candidate.mediaFileSigned &&
                                      candidate.mediaFileSigned !== null &&
                                      candidate.mediaFileSigned !== "" &&
                                      !checkIsImage(candidate.mediaFileSigned) && (
                                        <>
                                          <video
                                            className="profileImage-crossSell default-img-border cursor-hover p-relative"
                                            style={{ left: "2px" }}
                                            src={candidate.mediaFileSigned}
                                            onClick={() => {
                                              handleOpenVideoPreview(
                                                candidate.mediaFileSigned,
                                                candidate.firstName +
                                                " " +
                                                candidate.lastName
                                              );
                                            }}
                                            muted
                                            onKeyPress={() => {
                                              handleOpenVideoPreview(
                                                candidate.mediaFileSigned,
                                                candidate.firstName +
                                                " " +
                                                candidate.lastName
                                              );
                                            }}
                                            tabIndex={0}
                                          />
                                          <img
                                            onClick={() => {
                                              handleOpenVideoPreview(
                                                candidate.mediaFileSigned,
                                                candidate.firstName +
                                                " " +
                                                candidate.lastName
                                              );
                                            }}
                                            className="profileImage-overlay-crossSell play-icon-profile cursor-hover"
                                            src={playIcon}
                                            style={{ top: "105px", left: "80px" }}
                                            alt="img"
                                            tabIndex={0}
                                            onKeyPress={() => {
                                              handleOpenVideoPreview(
                                                candidate.mediaFileSigned,
                                                candidate.firstName +
                                                " " +
                                                candidate.lastName
                                              );
                                            }}
                                          />
                                        </>
                                      )}
                                    {candidate.mediaFile !== "****" &&
                                      candidate.mediaFileSigned &&
                                      candidate.mediaFileSigned !== null &&
                                      candidate.mediaFileSigned !== "" &&
                                      checkIsImage(candidate.mediaFileSigned) && (
                                        <img
                                          className="profileImage-crossSell default-img-border"
                                          style={{ left: "2px" }}
                                          src={candidate.mediaFileSigned}
                                          alt="img"
                                        />
                                      )}
                                  </Grid>
                                  <Grid className={`image-section ${candidate?.talentPool === "Z" && localStorage.getItem("childEnterpriseId") && (localStorage.getItem("mspEnterpriseId") === candidate?.sourceEnterpriseId) && "ml-11"}`}>
                                    <Tooltip
                                      title={
                                        <div className="mt-15">
                                          <Typography
                                          // style={{
                                          //   width: `${localStorage.getItem("childEnterpriseId") && (localStorage.getItem("mspEnterpriseId") === candidate?.sourceEnterpriseId) ? "110px" : "80px"}`,
                                          // }}
                                          >
                                            <div className="d-flex align-center">
                                              <span className={`${localStorage.getItem("childEnterpriseId") && (localStorage.getItem("mspEnterpriseId") === candidate?.sourceEnterpriseId) ? "mspExclusive" : "priorityStatus"} d-flex align-center justify-center`}>
                                                <span className="d-flex align-center">
                                                  {localStorage.getItem("childEnterpriseId") && (localStorage.getItem("mspEnterpriseId") === candidate?.sourceEnterpriseId) ? <PriorityIconGreen className="msp-svg" /> : <PriorityIcon />}
                                                </span>
                                                <span className="pl-2">
                                                  {localStorage.getItem("childEnterpriseId") && (localStorage.getItem("mspEnterpriseId") === candidate?.sourceEnterpriseId) ? Constants.MSP_EXCLUSIVE : "Exclusive"}
                                                </span>
                                              </span>
                                              <span className="f-12 c-white pl-5">{getExclusiveEnterprise(candidate?.sourceEnterpriseId)}</span>
                                            </div>
                                          </Typography>
                                          <Typography className="f-12 c-white mt-5">
                                            {localStorage.getItem("childEnterpriseId") && (localStorage.getItem("mspEnterpriseId") === candidate?.sourceEnterpriseId) ? Constants.MSP_EXCLUSIVE_Detail : "These are the candidates who have come to the platform through you and only you have the access to their profile"}
                                          </Typography>
                                          <br />
                                        </div>
                                      }
                                      position="top"
                                      trigger="mouseenter"
                                      animation="fade"
                                      arrow
                                    >
                                      <Typography className="mt-5 mb-5">
                                        {candidate?.talentPool === "Z" && (
                                          <span className={`${localStorage.getItem("childEnterpriseId") && (localStorage.getItem("mspEnterpriseId") === candidate?.sourceEnterpriseId) ? "priority-image-status-mspExclusive" : "priority-image-status"} d-flex align-center`}>
                                            <span className="d-flex align-center">
                                              {localStorage.getItem("childEnterpriseId") && (localStorage.getItem("mspEnterpriseId") === candidate?.sourceEnterpriseId) ? <PriorityIconGreen className="msp-svg" /> : <PriorityIcon />}
                                            </span>
                                            <span className="pl-5">{localStorage.getItem("childEnterpriseId") && (localStorage.getItem("mspEnterpriseId") === candidate?.sourceEnterpriseId) ? Constants.MSP_EXCLUSIVE : "Exclusive"}</span>
                                          </span>
                                        )}
                                      </Typography>
                                    </Tooltip>
                                    <Tooltip
                                      title={
                                        <div className="mt-15">
                                          <Typography
                                            style={{
                                              width: "110px",
                                            }}
                                          >
                                            <span className="myCandidate-Ongoing d-flex align-center justify-center">
                                              <span className="d-flex align-center">
                                                <PrivateIcon />
                                              </span>
                                              <span className="pl-5">
                                                Qualified
                                              </span>
                                            </span>
                                          </Typography>
                                          <Typography className="f-12 c-white mt-5">
                                            These are the
                                            candidates with whom
                                            you have interacted on
                                            our platform
                                          </Typography>
                                          <br />
                                        </div>
                                      }
                                      placement="top"
                                      trigger="mouseenter"
                                      animation="fade"
                                      arrow
                                    >
                                      <Typography className="mt-5 mb-5">
                                        {candidate?.talentPool === "P" && (
                                          <span className="private-image-status d-flex align-center">
                                            <span className="d-flex align-center">
                                              <PrivateIcon />
                                            </span>
                                            <span className="pl-5">Qualified</span>
                                          </span>
                                        )}
                                      </Typography>
                                    </Tooltip>
                                  </Grid>
                                </Grid>
                              </Box>
                            </Typography>
                            <Typography className="job-detail-align">
                              <Box className="pl-20 userName-align job-detail-align">
                                <Typography className="align-center">
                                  <Typography className="align-center d-flex font-weight-600 f-16 gray9 break-word launchTitle userName-align width-responsive-100 history-align status-align responsive-block galaxy-block">
                                    <Typography className="font-weight-600 f-16 main-text-color associate-status-align">
                                    <TooltipShared title={(candidate.firstName === "****" &&
                                      (candidate.lastName !== null || candidate.lastName !== "" || candidate.lastName === "****"))
                                      ? Constants.GDPR_TOOLTIP
                                      : ""} className="d-inline-flex">
                                      {candidate.firstName} {candidate.lastName}
                                    </TooltipShared>
                                    </Typography>
                                    {/* <Typography className="cursor-hover icon-button">
                                      <DetailIcon
                                        className="ml-10 success-color small-text-font cursor-hover svg-icons"
                                        onClick={(e) => viewCandidate()}
                                        tabIndex={0}
                                        onKeyPress={(e) => viewCandidate()}
                                      />
                                    </Typography> */}
                                    <Typography className="gray9 font-weight-400 f-14 align-center d-flex userName-align responsive-block pl-30 responsive-view-align matching-details-align">
                                      <AvailabilityStatusLabels
                                        candidate={candidate}
                                        isFromMatchingDrawer={true}
                                        // goToProjectTab={goToProjectTab}
                                      />
                                    </Typography>
                                  </Typography>
                                </Typography>
                                <Box className="matching-details-align">
                                  <Typography
                                    variant="span"
                                    className="gray9 pt-10 f-12 font-weight-500 d-flex userName-align justify-between responsive-block"
                                  >
                                    <Typography className="d-flex">
                                      <NewBriefCase className="min-w-14 mt-3" width="14px" height="14px" />
                                      <Typography className="gray7 f-14 font-weight-400 pl-5">
                                        {candidate.profileTitle}{" "}
                                      </Typography>
                                    </Typography>
                                    {candidate?.city && candidate?.country && (
                                      <Typography className="d-flex pl-20 responsive-view-align">
                                        <TooltipShared title={(candidate.firstName === "****" &&
                                          (candidate.lastName !== null || candidate.lastName !== "" || candidate.lastName === "****"))
                                          ? Constants.GDPR_TOOLTIP
                                          : ""} className="d-inline-flex">
                                        <NewLocation className="min-w-14 mt-3" width="14px" height="14px" />
                                        <Typography className="gray7 f-14 font-weight-400 pl-5">
                                          {/* {candidate?.city ? candidate?.city : "-"},{" "}
                                    {candidate?.country
                                      ? candidate?.country
                                      : "-"} */}
                                          {candidate.city}
                                          {candidate?.city ? "," : ""}{" "}
                                          {candidate.country}
                                        </Typography>
                                        {/* {candidate.address1?.length > 0 && (
                                <FiberManualRecordIcon className="px-5" aria-label="Dot icon" />
                              )}{" "}
                              {candidate.address1} */}
                                      </TooltipShared>
                                      </Typography>
                                    )}
                                  </Typography>
                                  {candidate?.lastUpdatedTimestamp ?
                                  <Typography className="d-flex align-center mt-8">
                                    <Typography className="gray7 f-14 font-weight-400">
                                      {`Last Updated: ${
                                        moment().diff(candidate?.lastUpdatedTimestamp, 'days') > 0
                                        ? moment().diff(candidate?.lastUpdatedTimestamp, 'days') > 100
                                        ? "100+ days ago"
                                        : moment().diff(candidate?.lastUpdatedTimestamp, 'days') + (moment().diff(candidate?.lastUpdatedTimestamp, 'days') === 1 ? " day " : " days ") + "ago"
                                        : "Today"}`}
                                    </Typography>
                                  </Typography>
                                  : ""}
                                </Box>
                              </Box>
                              {(props.talentPool || props?.referrals) && props.candidateReferrerName != null && <Typography className="matching-location-align atching-details-align matching-details-align skill-labl nav-user-img mt-13">
                                <Box className="gray7 font-weight-400 align-center d-flex candidate-info-align adding-skills">
                                  <Typography className="d-flex align-center">
                                    <span className="d-flex">
                                      <ReferralIcon width={"12px"} />
                                    </span>
                                    <span className="f-14 pl-5 text-decoration-none">
                                      Referred by
                                    </span>
                                    <span className="d-flex f-12 pl-5 gray-8 font-weight-600">
                                      {props.candidateReferrerName?.referrerName} {`(${props.candidateReferrerName.referrerEstablishmentName})`}
                                    </span>
                                  </Typography>
                                </Box>
                              </Typography>}
                            </Typography>
                          </Grid>
                          {(candidate?.referrerId != null) ? (
                            <Typography className="d-flex mt-20">
                              <Typography className="d-flex align-center">
                                <span className="d-flex gray7">
                                  <ReferralIcon width="14px" height="14px" />
                                </span>
                                <span className="gray7 f-14 font-weight-400 pl-5">
                                  Referred by
                                </span>
                                {(candidate?.referredFrom === "C" || candidate?.referredFrom == null) && (
                                  <span className="d-flex">
                                    <a
                                      className="link-color f-12 pl-5 font-weight-600 text-decoration-none font-family"
                                      href={`${baseURL}/enterprise/candidate-detail/${candidate?.referrerId}`}
                                      target="_blank"
                                      rel="noreferrer"
                                    >
                                      {candidate?.referrerName}
                                    </a>
                                  </span>)}
                                {candidate?.referredFrom === "E" && (
                                  <span
                                    className="d-flex gray8 f-12 pl-5 font-weight-600 text-decoration-none font-family"
                                  >
                                    {candidate?.referrerName} {`(${localStorage.getItem("enterpriseName")})`}
                                  </span>
                                )}
                              </Typography>
                            </Typography>
                          ) : null}
                        </Grid>
                      </Grid>
                      <Grid item lg={3} md={3} xs={12} className="d-flex justify-end pt-0">
                        <Typography className="d-flex descriptionSpace responsive-block score-align">
                          {props.status !== "" && (
                            <>
                              <Popover
                                id={id}
                                open={open3}
                                anchorEl={anchorEl}
                                onClose={handleClosePopover}
                                anchorOrigin={{
                                  vertical: "center",
                                  horizontal: "left",
                                }}
                                transformOrigin={{
                                  vertical: "center",
                                  horizontal: "right",
                                }}
                              >
                                <Grid style={{ width: "260px" }} sx={{ p: 1 }}>
                                  <Box className="align-between mb-5">
                                    <Grid item xs={12}>
                                      <Typography
                                        align="right"
                                        className="cursor-hover gray7 icon-button"
                                      >
                                        <CloseIcon
                                          onClick={handleClosePopover}
                                          onKeyPress={handleClosePopover}
                                          aria-label="Close icon"
                                          tabIndex={0}
                                        />
                                      </Typography>
                                    </Grid>
                                  </Box>
                                  <Box className="border-bottom-1">
                                    <Box className="align-between mb-5 ">
                                      <span className="gray9 f-14 font-weight-600 pl-7">
                                        Overall Score
                                      </span>
                                      <span
                                        className="f-14 success-color font-weight-600"
                                        style={{ paddingRight: "17px" }}
                                      >
                                        {candidatePopUp
                                          ? Math.round(
                                            candidatePopUp.matchingScore
                                              ? candidatePopUp.matchingScore
                                              : 0
                                          )
                                          : ""}
                                        %
                                      </span>
                                    </Box>
                                  </Box>

                                  <Grid
                                    item
                                    className="p-10"
                                    style={{
                                      maxHeight: "250px",
                                      overflow: "auto",
                                    }}
                                  >
                                    <Box className="mb-5 mt-10">
                                      <Typography className="align-between">
                                        <span className="gray9 f-14 font-weight-600">
                                          Skills
                                        </span>
                                        <span className="f-14 success-color text-lowercase font-weight-600">
                                          {candidatePopUp?.matchedFilters
                                            ?.skills?.score
                                            ? Math.round(
                                              candidatePopUp.matchedFilters
                                                .skills.score
                                            ) + "%"
                                            : ""}
                                        </span>
                                      </Typography>
                                      <Typography className="gray7 f-14 mt-5 text-lowercase ">
                                        <CheckCircleIcon
                                          className="success-color vertical-align-middle"
                                          aria-label="Matched icon"
                                        />
                                        <span className="pl-7 font-weight-600 text-capitalize">
                                          Matched
                                        </span>
                                        <br />
                                        <span
                                          style={{
                                            whiteSpace: "pre-line",
                                            width: "185px",
                                          }}
                                          className="gray9 pl-25 break-all d-flex"
                                        >
                                          {candidatePopUp?.matchedFilters.skills
                                            ?.keyword
                                            ? getFormattedSkills(
                                              candidatePopUp.matchedFilters
                                                .skills.keyword
                                            )
                                            : "-"}
                                          {/*  {(candidatePopUp?.matchedFilters?.skills)?getFormattedSkills(candidatePopUp.matchedFilters.skills.keyword):"-"}
                                                                {(candidatePopUp?.matchedFilters?.skills.keyword === "")&& "-"}*/}
                                        </span>
                                      </Typography>
                                      <Typography className="gray7 f-14 mt-5 text-lowercase">
                                        <CheckCircleIcon
                                          className="warn-color vertical-align-middle"
                                          aria-label="Matched icon"
                                        />
                                        <span className="pl-7 font-weight-600 text-capitalize">
                                          Partially Matched
                                        </span>
                                        <br />
                                        <span
                                          style={{
                                            whiteSpace: "pre-line",
                                            width: "185px",
                                          }}
                                          className="gray9 pl-25 break-all d-flex"
                                        >
                                          {candidatePopUp?.matchedFilters
                                            .partialSkills?.keyword
                                            ? getFormattedSkills(
                                              candidatePopUp.matchedFilters
                                                .partialSkills.keyword
                                            )
                                            : "-"}
                                        </span>
                                      </Typography>
                                      <Typography className="gray7 f-14 mt-5 text-lowercase">
                                        <CancelRoundedIcon
                                          className="danger-color vertical-align-middle"
                                          aria-label="Unmatched icon"
                                        />
                                        <span className="pl-7 font-weight-600 text-capitalize">
                                          Unmatched
                                        </span>
                                        <br />
                                        <span
                                          style={{
                                            whiteSpace: "pre-line",
                                            width: "185px",
                                          }}
                                          className="gray9 pl-25 break-all d-flex"
                                        >
                                          {candidatePopUp?.unmatchedFilters
                                            ?.skills
                                            ? getFormattedSkills(
                                              candidatePopUp.unmatchedFilters
                                                .skills
                                            )
                                            : "-"}
                                        </span>
                                      </Typography>
                                    </Box>

                                    <Box className="mb-5 mt-10">
                                      <Typography className="align-between">
                                        <span className="gray9 f-14 font-weight-600">
                                          Experience
                                        </span>
                                        <span className="f-14 success-color font-weight-600">
                                          {preferredExp
                                            ? ""
                                            : candidatePopUp?.matchedFilters
                                              ?.overallYearsOfExperience
                                              ?.score
                                              ? Math.round(
                                                candidatePopUp.matchedFilters
                                                  .overallYearsOfExperience
                                                  .score
                                              ) + "%"
                                              : candidatePopUp?.matchedFilters
                                                ?.partialYearsOfExperience
                                                ?.score
                                                ? Math.round(
                                                  candidatePopUp?.matchedFilters
                                                    ?.partialYearsOfExperience
                                                    ?.score
                                                ) + "%"
                                                : "0%"}
                                        </span>
                                      </Typography>
                                      <Typography>
                                        <span className="gray7 f-14 font-weight-600">
                                          {candidatePopUp?.matchedFilters
                                            ?.overallYearsOfExperience
                                            ? "Matched"
                                            : candidatePopUp?.matchedFilters
                                              ?.partialYearsOfExperience
                                              ? "Partially Matched"
                                              : "Unmatched"}
                                        </span>
                                      </Typography>
                                      <Typography>
                                        <Box className=" align-center mt-5">
                                          {candidatePopUp?.matchedFilters
                                            ?.overallYearsOfExperience ? (
                                            <CheckCircleIcon
                                              className="success-color vertical-align-middle"
                                              aria-label="Matched icon"
                                            />
                                          ) : candidatePopUp?.matchedFilters
                                            ?.partialYearsOfExperience ? (
                                            <CheckCircleIcon
                                              className="warn-color vertical-align-middle"
                                              aria-label="Matched icon"
                                            />
                                          ) : (
                                            <CancelRoundedIcon
                                              className="danger-color vertical-align-middle"
                                              aria-label="Unmatched icon"
                                            />
                                          )}
                                          <span className="f-14 gray7 pl-7 font-weight-600">
                                            Preferred Job Experience
                                          </span>
                                          <br />
                                          {job.overallExperienceMaximum >= 1 ? (
                                            <span className="f-14 gray9 pl-25">
                                              {job.overallExperienceMinimum}-
                                              {job.overallExperienceMaximum}{" "}
                                              Years
                                            </span>
                                          ) : (
                                            <span className="f-14 gray9 pl-25">
                                              {job.overallExperienceMinimum}-
                                              {job.overallExperienceMaximum}{" "}
                                              Year
                                            </span>
                                          )}
                                        </Box>
                                      </Typography>
                                      <Typography>
                                        <Box className=" align-center mt-5">
                                          {candidatePopUp?.matchedFilters
                                            ?.overallYearsOfExperience ? (
                                            <CheckCircleIcon
                                              className="success-color vertical-align-middle"
                                              aria-label="Matched icon"
                                            />
                                          ) : candidatePopUp?.matchedFilters
                                            ?.partialYearsOfExperience ? (
                                            <CheckCircleIcon
                                              className="warn-color vertical-align-middle"
                                              aria-label="Matched icon"
                                            />
                                          ) : (
                                            <CancelRoundedIcon
                                              className="danger-color vertical-align-middle"
                                              aria-label="Unmatched icon"
                                            />
                                          )}
                                          <span className="f-14 gray7 pl-7 font-weight-600">
                                            Candidate Experience
                                          </span>
                                          <br />
                                          <span className="f-14 gray9 pl-25">
                                            {candidatePopUp
                                              ? candidatePopUp.overallYearsOfExperience +
                                              (candidatePopUp.overallYearsOfExperience <=
                                                1.0
                                                ? " Year"
                                                : " Years")
                                              : ""}
                                          </span>
                                        </Box>
                                      </Typography>
                                    </Box>
                                    {(candidatePopUp?.matchedFilters
                                      ?.location ||
                                      candidatePopUp?.unmatchedFilters
                                        ?.location) && (
                                        <Box className="m-b-5 mt-10">
                                          <Typography className="align-between">
                                            <span className="gray9 f-14 font-weight-600">
                                              Location{" "}
                                            </span>
                                            <span className="f-14 success-color font-weight-600">
                                              {candidatePopUp?.matchedFilters
                                                ?.location?.score
                                                ? Math.round(
                                                  candidatePopUp.matchedFilters
                                                    .location.score
                                                ) + "%"
                                                : ""}
                                            </span>
                                          </Typography>
                                          {/* {(props.jobs?.matchedFilters?.location?.keyword) && <Typography className="gray9 f-14 mt-5"><CheckCircleIcon style={{verticalAlign: 'middle',marginRight:5}} className='success-color vertical-align-middle'/><span className='p-l-5'>{props.jobs.matchedFilters.location.keyword}</span></Typography>} */}
                                          {/* {(props.jobs?.unmatchedFilters?.location) && <Typography className="gray9 f-14 mt-5"><CancelRoundedIcon style={{verticalAlign: 'middle',marginRight:5}} className='success-color vertical-align-middle'/><span className='p-l-5'>{props.jobs.unmatchedFilters.location}</span></Typography>} */}
                                          {candidatePopUp?.matchedFilters
                                            ?.location?.score ? (
                                            <Box className=" align-center mt-5">
                                              <CheckCircleIcon
                                                style={{
                                                  verticalAlign: "middle",
                                                  marginRight: 5,
                                                }}
                                                className="success-color vertical-align-middle"
                                                aria-label="Matched icon"
                                              />
                                              <span className="gray9 f-14 pl-28">
                                                {candidatePopUp?.city
                                                  ? candidatePopUp?.country
                                                    ? candidatePopUp.city +
                                                    ", " +
                                                    candidatePopUp.country
                                                    : candidatePopUp.city
                                                  : candidatePopUp?.country
                                                    ? candidatePopUp.country
                                                    : "-"}
                                              </span>
                                            </Box>
                                          ) : (
                                            <>
                                              <Box className=" align-center mt-5">
                                                {candidatePopUp?.unmatchedFilters
                                                  ?.location ? (
                                                  <CancelRoundedIcon
                                                    style={{
                                                      verticalAlign: "middle",
                                                      marginRight: 5,
                                                    }}
                                                    className="danger-color vertical-align-middle"
                                                    aria-label="Unmatched icon"
                                                  />
                                                ) : (
                                                  <CheckCircleIcon
                                                    style={{
                                                      verticalAlign: "middle",
                                                      marginRight: 5,
                                                    }}
                                                    className="success-color vertical-align-middle"
                                                    aria-label="Matched icon"
                                                  />
                                                )}
                                                <span className="f-14 gray7 p-l-5 font-weight-600">
                                                  Job Location
                                                </span>
                                                <br />
                                                {candidatePopUp?.unmatchedFilters
                                                  ?.location && (
                                                    <Typography className="gray9 f-14 pl-28">
                                                      <span className="f-14 gray9 pl-25">
                                                        {candidatePopUp.unmatchedFilters.location
                                                          .split(",")
                                                          .join(", ")}
                                                      </span>
                                                    </Typography>
                                                  )}
                                              </Box>

                                              <Box className=" align-center mt-5">
                                                {candidatePopUp?.unmatchedFilters
                                                  ?.location ? (
                                                  <CancelRoundedIcon
                                                    style={{
                                                      verticalAlign: "middle",
                                                      marginRight: 5,
                                                    }}
                                                    className="danger-color vertical-align-middle"
                                                    aria-label="Unmatched icon"
                                                  />
                                                ) : (
                                                  <CheckCircleIcon
                                                    style={{
                                                      verticalAlign: "middle",
                                                      marginRight: 5,
                                                    }}
                                                    className="success-color vertical-align-middle"
                                                    aria-label="Matched icon"
                                                  />
                                                )}
                                                <span className="f-14 gray7 p-l-5 font-weight-600">
                                                  Candidate Location
                                                </span>
                                                <br />
                                                {
                                                  <Typography className="gray9 f-14 pl-28">
                                                    <span className="f-14 gray9 pl-25">
                                                      {candidatePopUp?.city
                                                        ? candidatePopUp?.country
                                                          ? candidatePopUp.city +
                                                          ", " +
                                                          candidatePopUp.country
                                                          : candidatePopUp.city
                                                        : candidatePopUp?.country
                                                          ? candidatePopUp.country
                                                          : "-"}
                                                    </span>
                                                  </Typography>
                                                }
                                              </Box>
                                            </>
                                          )}
                                        </Box>
                                      )}

                                    <Box className="mb-5 mt-10">
                                      <Typography className="align-between">
                                        <span className="gray9 f-14 font-weight-600">
                                          Profile Title
                                        </span>

                                        {candidatePopUp?.matchedFilters
                                          .title && (
                                            <span className="f-14 success-color font-weight-600">
                                              {preferredExp
                                                ? ""
                                                : candidatePopUp?.matchedFilters
                                                  ?.title?.score
                                                  ? Math.round(
                                                    candidatePopUp.matchedFilters
                                                      .title.score
                                                  ) + "%"
                                                  : "0%"}
                                            </span>
                                          )}
                                        {candidatePopUp?.unmatchedFilters
                                          .title && (
                                            <span className="f-14 success-color font-weight-600">
                                              {preferredExp
                                                ? ""
                                                : candidatePopUp?.matchedFilters
                                                  ?.title?.score
                                                  ? Math.round(
                                                    candidatePopUp.matchedFilters
                                                      .title.score
                                                  ) + "%"
                                                  : "0%"}
                                            </span>
                                          )}
                                      </Typography>

                                      {candidatePopUp?.matchedFilters.title && (
                                        <Box className=" align-center mt-5">
                                          <CheckCircleIcon
                                            style={{
                                              verticalAlign: "middle",
                                              marginRight: 5,
                                            }}
                                            className="success-color vertical-align-middle"
                                            aria-label="Matched icon"
                                          />
                                          <span className="gray9 f-14 pl-28">
                                            {
                                              candidatePopUp?.matchedFilters
                                                .title.keyword
                                            }
                                          </span>
                                        </Box>
                                      )}

                                      {candidatePopUp?.unmatchedFilters
                                        .title && (
                                          <Box className=" align-center mt-5">
                                            <CancelRoundedIcon
                                              style={{
                                                verticalAlign: "middle",
                                                marginRight: 5,
                                              }}
                                              className="danger-color vertical-align-middle"
                                              aria-label="Unmatched icon"
                                            />
                                            <span className="gray9 f-14 pl-28">
                                              {candidatePopUp?.profileTitle}
                                            </span>
                                          </Box>
                                        )}
                                    </Box>
                                  </Grid>
                                </Grid>
                              </Popover>
                            </>
                          )}
                          {/* <ActionButtons isLoading={isLoading} setIsLoading={setIsLoading} candidateApplicationData={candidateApplicationData} /> */}
                          <Grid>
                            {!props.isFromGlobalSearch && !props.isFromTalentPool && !props.isFromMyCandidates &&
                              (candidate?.talentPool === "Z" && localStorage.getItem("userType") !== "S") &&
                              <Typography className="responsive-contents">
                              <Stack direction="row" spacing={2}>
                                <div className="d-grid">
                                  <Button
                                    ref={anchorRef}
                                    aria-controls={
                                      openStatus
                                        ? "composition-menu"
                                        : undefined
                                    }
                                    aria-expanded={
                                      openStatus ? "true" : undefined
                                    }
                                    aria-haspopup="true"
                                    onClick={handleToggle}
                                    aria-label="Menu icon"
                                    disableRipple="true"
                                    role="button"
                                  >
                                  <Typography className="secondary-bg-color border-radius-4 p-7-10 d-flex">
                                    <ActionIcon width="16px" height="16px" className="svg-icons"/>
                                  </Typography>
                                  </Button>
                                  <Popper
                                    style={{ zIndex: "9" }}
                                    open={openStatus}
                                    anchorEl={anchorRef.current}
                                    role={undefined}
                                    placement="bottom-end"
                                    transition
                                    disablePortal
                                    className="options-align"
                                  >
                                    {({ TransitionProps, placement }) => (
                                      <Grow
                                        {...TransitionProps}
                                        style={{
                                          transformOrigin:
                                            placement === "bottom-start"
                                              ? "left top"
                                              : "left bottom",
                                        }}
                                      >
                                        <Paper>
                                          <ClickAwayListener
                                            onClickAway={handleCloseStatus}
                                            tabIndex={0}
                                            onKeyPress={handleCloseStatus}
                                          >
                                            <MenuList
                                              autoFocusItem={openStatus}
                                              id="composition-menu"
                                              aria-labelledby="composition-button"
                                              onKeyDown={handleListKeyDown}
                                            >
                                            <MenuItem
                                              onClick={(e) => goToEditCandidate(e, candidate)}
                                              onKeyPress={(e) => goToEditCandidate(e, candidate)}
                                            >
                                              <Typography className="gray9 f-12 font-weight-400">
                                                <span>Edit Candidate Details</span>
                                              </Typography>
                                            </MenuItem>
                                            </MenuList>
                                          </ClickAwayListener>
                                        </Paper>
                                      </Grow>
                                    )}
                                  </Popper>
                                </div>
                              </Stack>
                            </Typography>
                            }
                          </Grid>
                        </Typography>
                      </Grid>
                    </Grid>
                    <TextField
                    {...params}
                    type="text"
                    className="form-control input-group mb-0 inputcontrasts ml-50 w-webkit cross-list-align"
                    margin="normal"
                    placeholder="Search by job title, job reference number or external job id"
                    onChange={(e) => setSearchedValue(e.target.value)}
                    onKeyPress={(e) => {
                      handleSpaces(e);
                      handleEnterSearchKey(e);
                    }}
                    onInput={handleEnterSearchKey}
                    onKeyDown={handleEnterSearchKey}
                    value={searchedValue}
                    InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                      {searchedValue !== "" && (
                      <ClearIcon
                      onClick={() => {
                      // inputRef.current.value = "";
                      clearSearch();
                      }}
                      style={{ marginRight: "5px" }}
                      aria-label="Clear icon"
                      className="cursor-hover"
                      />
                      )}
                        <Typography
                        className="search-byId"
                        style={{ marginRight: "-6px" }}
                        >
                        <Grid
                        className="border-radius-6 d-flex align-center cursor-hover"
                        style={{ padding: "12px" }}
                        onClick={() => {
                        if (
                          searchedValue !== ""
                        ) {
                          props?.isFromRehire ? fetchSearchedRehireJobs(valueTabs, 0, searchedValue) : fetchSearchedJobs(valueTabs, 0, searchedValue);
                        }
                        }}
                        aria-label="Search icon"
                        disableRipple="true"
                        >
                        <SearchIcon
                        width="20px"
                        aria-label="search icon"
                        />
                        </Grid>
                        </Typography>
                        </InputAdornment>
                        ),
                      }}
                  />
                    <Box sx={{ width: "100%" }} className="">
                      <TabContext value={valueTabs}>
                        <Box className="bg-white profile-tabs matching-drawer-tab ml-50 cross-list-align">
                          <TabList
                            onChange={handleChangeTabs}
                            variant="scrollable"
                            scrollButtons="auto"
                            aria-label="scrollable auto tabs example"
                          >
                            <Tab
                              label={`Recommended Jobs${isSearched ? ` (${recommendedJobCount})` : ''}`}
                              value="RecommendedJobs"
                            />
                            <Tab
                              label={`All Jobs${isSearched ? ` (${allJobCount})` : ''}`}
                              value="allJobs"
                            />
                          </TabList>
                        </Box>
                        <Box className="ml-50 mb-10 cross-selling mt-10 cross-list-align">
                          {valueTabs === "RecommendedJobs" && (
                            <InfiniteScroll
                            dataLength={recommendedJobs.length}
                            next={() => fetchMoreJobs()}
                            hasMore={loadMoreJobs}
                            inverse={false}
                            height={183}
                            className="cross-sell-jobs"
                          >
                            <FormGroup>
                              {recommendedJobs?.length === 0 ? (
                                <Grid item lg={11} md={11} xs={12} className="m-auto text-center">
                                  <img src={noCandidate} className="mt-20" alt="img" width="200px" height="200px" />
                                  <FormLabel className="f-14 font-weight-500 gray9 ml-4 pt-7" component="legend">
                                    No jobs to shortlist this candidate
                                  </FormLabel>
                                </Grid>
                              ) : (
                                recommendedJobs.map((item) => (
                                  <>
                                    <Grid className="d-flex justify-between border-bottom-gray4 pb-10 pr-30">
                                      <FormControlLabel
                                        key={item.id}
                                        className={`small-text-font font-weight-500 align-start w-100 checkbox-card mt-10 cross-radio`}
                                        control={
                                          <Radio
                                            checked={selectedJob === item.id}
                                            onChange={handleJobChange}
                                            value={item.id}
                                            sx={{
                                              color: "#98A2B3",
                                              "&.Mui-checked": {
                                                color: getComputedStyle(document.documentElement).getPropertyValue("--primary-color"),
                                              },
                                            }}
                                          />
                                        }
                                        label={
                                          <div>
                                            <span className="f-14 font-weight-600 main-text-color cursor-hover">{item.name}</span>
                                            {item.city && item.country && (
                                              <Typography className="matching-details-align mt-2">
                                                <Box className="f-12 font-weight-400 gray7 align-center d-flex">
                                                  <LocationIcon width="15px" className="mr-5 gray7 f-12 font-weight-400 icon-margin" />
                                                  {item.city}, {item.country}
                                                </Box>
                                              </Typography>
                                            )}
                                            <Box className="font-weight-400 d-flex align-center mt-3">
                                              <Box className="d-flex align-center mr-5 ml-2">
                                                <JobReferenceNumberIcon width="12px" height="12px" className="svg-icons-gray7" />
                                              </Box>
                                              <Box className="d-flex align-center gray7 f-12 display-flex align-items-center">
                                                Job Reference #: {item.jobReferenceNumber}
                                                <Link
                                                  onClick={(e) => copyToClipboard(e, item.jobReferenceNumber)}
                                                  className="cursor-hover display-flex p-3"
                                                  tabIndex={0}
                                                >
                                                  <ContentCopyIcon style={{ width: "13px", height: '13px', margin: "0" }} className="gray7" />
                                                </Link>
                                              </Box>
                                            </Box>
                                          </div>
                                        }
                                      />
                                      <Typography className="mt-15">
                                        {/* {item.status == "O" && <span className="availableStatus font-weight-500 f-14">Open</span>} */}
                                        {item.status === "O" && (
                                          item.validTill
                                            ? formatDate(
                                                new Date(
                                                  JSON.parse(
                                                    JSON.stringify(item.validTill)
                                                  ).replace("23:59:59", "00:00:00")
                                                )
                                              ) < formatDate(date)
                                              ? <span className="danger-color declineStatus font-weight-500 f-14">Expired</span>
                                              : <span className="availableStatus font-weight-500 f-14">Open</span>
                                            : <span className="availableStatus font-weight-500 f-14">Open</span>
                                        )}
                                        {/* <span className="closedStatus font-weight-500 f-14">Closed</span>
                                        <span className="danger-color declineStatus font-weight-500 f-14">Dropped / Cancelled</span>
                                        <span className="pendingStatus font-weight-500 f-14">Pending</span> */}
                                        {item.status == "H" && <span className="danger-color declineStatus font-weight-500 f-14">Hold</span>}
                                      </Typography>
                                    </Grid>
                                  </>
                                ))
                              )}
                            </FormGroup>
                          </InfiniteScroll>
                          )}
                          {valueTabs === "allJobs" && (
                            <InfiniteScroll
                            dataLength={recommendedJobs.length}
                            next={() => fetchMoreJobs()}
                            hasMore={loadMoreJobs}
                            inverse={false}
                            height={183}
                            className="cross-sell-jobs"
                          >
                            <FormGroup>
                            {recommendedJobs.length === 0 ? (
                              <Grid item lg={11} md={11} xs={12} className="m-auto text-center">
                                <img src={noCandidate} className="mt-20" alt="img" width="200px" height="200px" />
                                <FormLabel className="f-14 font-weight-500 gray9 ml-4 pt-7" component="legend">
                                  No jobs to shortlist this candidate
                                </FormLabel>
                              </Grid>
                            ) : (
                              recommendedJobs.map((item) => (
                                <>
                                <Grid className="d-flex justify-between border-bottom-gray4 pr-30">
                                <FormControlLabel
                                  key={item.id}
                                  className={`small-text-font font-weight-500 align-start w-100 pb-10 checkbox-card mt-10 cross-radio`}
                                  control={
                                    <Radio
                                      checked={selectedJob === item.id}
                                      onChange={handleJobChange}
                                      value={item.id}
                                      sx={{
                                        color: "#98A2B3",
                                        "&.Mui-checked": {
                                          color: getComputedStyle(document.documentElement).getPropertyValue("--primary-color"),
                                        },
                                      }}
                                    />
                                  }
                                  label={
                                    <div>
                                      <span className="f-14 font-weight-600 main-text-color cursor-hover">{item.name}</span>
                                      {item.city && item.country && (
                                        <Typography className="matching-details-align mt-2">
                                          <Box className="f-12 font-weight-400 gray7 align-center d-flex">
                                            <LocationIcon width="15px" className="mr-5 gray7 f-12 font-weight-400 icon-margin" />
                                            {item.city}, {item.country}
                                          </Box>
                                        </Typography>
                                      )}
                                      <Box className="font-weight-400 d-flex align-center mt-3">
                                        <Box className="d-flex align-center mr-5 ml-2">
                                          <JobReferenceNumberIcon width="12px" height="12px" className="svg-icons-gray7" />
                                        </Box>
                                        <Box className="d-flex align-center gray7 f-12 display-flex align-items-center">
                                          Job Reference #: {item.jobReferenceNumber}
                                          <Link
                                            onClick={(e) => copyToClipboard(e, item.jobReferenceNumber)}
                                            className="cursor-hover display-flex p-3"
                                            tabIndex={0}
                                          >
                                            <ContentCopyIcon style={{ width: "13px", height: '13px', margin: "0" }} className="gray7" />
                                          </Link>
                                        </Box>
                                      </Box>
                                    </div>
                                  }
                                />
                                 <Typography className="mt-15">
                                     {/* {item.status == "O" && <span className="availableStatus font-weight-500 f-14">Open</span>} */}
                                     {/* {item.status == "O" && <span className="availableStatus font-weight-500 f-14">Open</span>} */}
                                     {item.status === "O" && (
                                      item.validTill
                                        ? formatDate(
                                            new Date(
                                              JSON.parse(
                                                JSON.stringify(item.validTill)
                                              ).replace("23:59:59", "00:00:00")
                                            )
                                          ) < formatDate(date)
                                          ? <span className="danger-color declineStatus font-weight-500 f-14">Expired</span>
                                          : <span className="availableStatus font-weight-500 f-14">Open</span>
                                        : <span className="availableStatus font-weight-500 f-14">Open</span>
                                    )}
                                     {/* <span className="closedStatus font-weight-500 f-14">Closed</span>
                                     <span className="danger-color declineStatus font-weight-500 f-14">Dropped / Cancelled</span>
                                     <span className="pendingStatus font-weight-500 f-14">Pending</span> */}
                                     {item.status == "H" && <span className="danger-color declineStatus font-weight-500 f-14">Hold</span>}
                                  </Typography>
                                </Grid>
                              </>
                              ))
                            )}
                          </FormGroup>
                          </InfiniteScroll>
                          )}
                        </Box>
                      </TabContext>
                      <Grid id="modal-footer"
                       className="d-flex justify-end align-center"
                       >
                        {recommendedJobs.length > 0 && (
                          <Typography className="d-flex align-right justify-end shortlist-btn pb-50">
                            <Button
                              onClick={() => {
                                props?.isFromRehire ? handleOffer() : handleSubmit();
                              }}
                              disabled={!selectedJob}
                              className="btn primary-button"
                              variant="contained"
                              disableRipple="true"
                              aria-label="button"
                            >
                              {props?.isFromRehire ? "Offer" : "Continue"}
                            </Button>
                          </Typography>
                        )}
                      </Grid>
                    </Box>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Modal
              open={openOffer}
              onClose={handleCloseOffer}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
            >
            <Box className="modal-content inputcontrasts">
              <Typography className="align-left">
                <Typography id="modal-title" className="d-flex align-between">
                  <Typography variant="h5" className="f-20 font-weight-600 heading-text-color">
                    Offer
                    <Typography className="f-14 font-weight-500 m-t-5">
                    You can generate an offer letter or send the offer directly.
                    </Typography>
                  </Typography>
                  <Typography
                    align="right"
                    className="cursor-hover gray7 icon-button"
                  >
                    <CloseIcon
                      onClick={handleCloseOffer}
                      onKeyPress={handleCloseOffer}
                      aria-label="Close icon"
                      tabIndex={0}
                    />
                  </Typography>
                </Typography>
                <Typography id="modal-description">
                  <Grid container spacing={1}>
                    <Grid item lg={6} md={6} xs={12}>
                      <Box className="offerPayRateAlign">
                        <p
                          className="f-14 gray9 mb-0 font-weight-500 mt-0"
                          align="left"
                        >
                          Expiry Date<span className="text-black">*</span>
                        </p>
                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                          <DatePicker
                            className="form-control input-group mb-0 borderRadius-l inputcontrasts position-issue payRatePaddingBottom"
                            value={values}
                            onChange={(newValue) => {
                              setValues(newValue);
                            }}
                            disablePast
                            format="MMM dd, yyyy"
                            placeholder="Offer expiry date"
                            disableFuture={false}
                          />
                        </MuiPickersUtilsProvider>
                      </Box>
                    </Grid>
                    <Grid item lg={6} md={6} xs={12}>
                      <Box className="offerPayRateAlign">
                        <p
                          className="f-14 gray9 mb-0 font-weight-500 mt-0"
                          align="left"
                        >
                          Pay Rate
                        </p>
    <Grid container spacing={1} lg={12} md={12} xs={12} className='m-0'>
                        <Grid item lg={3} md={3} xs={4} className='pl-0 pt-7'>
                        <Typography
                          className="d-flex align-center"
                                    >
                                        <Box className="p-0 w-100 payrate-closure">
                                            <Select
                                                // disabled={value}
                                                inputProps={{
                                                    name: "curreny",
                                                    id: "uncontrolled-native",
                                                }}
                                                className="mb-0 w-100 inputcontrasts form-control input-group discipline-input pay-rate-input pb-7"
                                                name="curreny"
                                                value={currency}
                                                onChange={(e) => {
                                                    setCurrency( e.target.value);
                                                }}
                                                displayEmpty
                                            >
                                                <MenuItem value="USD" className='selectBox'>USD</MenuItem>
                                                <MenuItem value="GBP" className='selectBox'>GBP</MenuItem>
                                                <MenuItem value="EUR" className='selectBox'>EUR</MenuItem>
                                                <MenuItem value="CHF" className='selectBox'>CHF</MenuItem>
                                                <MenuItem value="CZK" className='selectBox'>CZK</MenuItem>
                                                <MenuItem value="INR" className='selectBox'>INR</MenuItem>
                                            </Select>
                                        </Box>
                                    </Typography>
                                </Grid>
                                <Grid item lg={9} md={9} xs={8} className="pt-7">
                        <Typography
                          className={`d-flex align-center payrate-closure ${payRateErr ? "is-invalid-payRate" : ""
                            }`}
                        >
                          <Input
                                            type="text"
                                            name="hourlyRateNumber"
                                            id="hourlyRateNumber"
                                            className="mb-0 inputcontrasts form-control input-group w-100 pb-6"
                                            margin="normal"
                                            placeholder="Enter pay rate"
                                            // disabled={value}
                                            value={payRate}
                                            onChange={(e) => handleChangePayRate(e)}
                                            // onInput={handleInputChange}
                                            endAdornment={
                                                <InputAdornment position="end">
                                                    <Typography className="f-16 font-weight-600 gray11">/ hour</Typography>
                                                </InputAdornment>
                                            }
                                        />
                        </Typography>
                        <span className="danger-color error-msg">
                          {" "}
                          {payRateErr}
                        </span>
    </Grid>
                        </Grid>
                      </Box>
                    </Grid>
                    <Grid item lg={6} md={6} xs={12}>
                      <Box className="galaxy-width-input">
                        <p
                          className="f-14 gray9 mb-0 font-weight-500 mt-0"
                          align="left"
                        >
                          Job Start Date<span className="text-black">*</span>
                        </p>
                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                          <DatePicker
                            className="form-control input-group mb-0 borderRadius-l inputcontrasts position-issue"
                            value={jobStartDate}
                            onChange={(date) => handleChangeStartDate(date)}
                            disablePast
                            format="MMM dd, yyyy"
                            placeholder="Job start date"
                            id="start-date"
                            inputProps={{ role: "textbox" }}
                            minDate={
                              values ? new Date(values) : null
                            }
                            TextFieldComponent={(params) => (
                              <div
                                style={{ position: "relative" }}
                                onMouseEnter={() =>
                                  setHideStartDateClearIcon(false)
                                }
                                onMouseLeave={() => setHideStartDateClearIcon(true)}
                              >
                                <Input {...params} readOnly />
                                {params.value && !hideStartDateClearIcon && (
                                  <IconButton
                                    style={{
                                      position: "absolute",
                                      top: "80%",
                                      right: "6px",
                                      transform: "translateY(-80%)",
                                    }}
                                    onClick={() => handleChangeStartDate(null)}
                                  >
                                    <ClearIcon
                                      style={{ fontSize: "5px" }}
                                      aria-label="Clear icon"
                                      className="Clearicon-issue"
                                    />
                                  </IconButton>
                                )}
                              </div>
                            )}
                          />
                        </MuiPickersUtilsProvider>
                      </Box>
                    </Grid>
                    <Grid item lg={6} md={6} xs={12}>
                      <Box className="galaxy-width-input">
                        <p
                          className="f-14 gray9 mb-0 font-weight-500 mt-0"
                          align="left"
                        >
                          Job End Date
                        </p>
                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                          <DatePicker
                            id="refreshEndDate"
                            className={`form-control input-group mb-0 borderRadius-l inputcontrasts position-issue ${endDateErr ? "is-invalid" : ""
                              }`}
                            value={jobCompletionDate}
                            onChange={(date) => handleChangeEndDate(date)}
                            format="MMM dd, yyyy"
                            placeholder="Job end date"
                            disablePast
                            minDate={
                              new Date(new Date(jobStartDate).getTime() + 86400000)
                            }
                            error={false}
                            helperText={null}
                            inputProps={{ role: "textbox" }}
                            TextFieldComponent={(params) => (
                              <div
                                style={{ position: "relative" }}
                                onMouseEnter={() => setHideEndDateClearIcon(false)}
                                onMouseLeave={() => setHideEndDateClearIcon(true)}
                              >
                                <Input {...params} readOnly />
                                {params.value && !hideEndDateClearIcon && (
                                  <IconButton
                                    style={{
                                      position: "absolute",
                                      top: "80%",
                                      right: "6px",
                                      transform: "translateY(-80%)",
                                    }}
                                    onClick={() => handleChangeEndDate(null)}
                                  >
                                    <ClearIcon
                                      style={{ fontSize: "5px" }}
                                      aria-label="Clear icon"
                                      className="Clearicon-issue"
                                    />
                                  </IconButton>
                                )}
                              </div>
                            )}
                          />
                        </MuiPickersUtilsProvider>
                        <span className="danger-color error-msg">
                          {" "}
                          {endDateErr}
                        </span>
                      </Box>
                    </Grid>
                  </Grid>
                  <Box className="galaxy-width-input mt-10">
                <Typography className="mt-2 display-flex align-center offer-letter-div">
                      <Checkbox
                        // {...label}
                        checked={sendOfferEmailChecked}
                        disableRipple={true}
                        sx={{
                          paddingLeft: "0px",
                          color: "#98A2B3",
                          "&.Mui-checked": {
                            color: getComputedStyle(
                              document.documentElement
                            ).getPropertyValue(
                              "--primary-color"
                            ),
                          },
                        }}
                      onClick={handleCheckboxChange}
                      onKeyPress={handleCheckboxChange}
                      />
                    <span
                      className="cursor-hover f-14 font-weight-400"
                      style={{ marginTop: "-2px" }}
                      onClick={toggleCheckbox}
                      onKeyPress={toggleCheckbox}
                    >
                      By unchecking this box, the offer email will not be sent to the candidate.
                    </span>
                  </Typography>
                  </Box>
                </Typography>
                  <Typography className="justify-center d-flex mt-20">
                  <Button
                    onClick={() => {
                      createOfferHandler();
                    }}
                    className="btn secondary-button"
                    aria-label="button"
                    disableRipple="true"
                    disabled={endDateErr || payRateErr || values == null || jobStartDate == null}
                  >
                    Prepare Offer Letter
                  </Button>
                  <Button
                    onClick={handleSkipAndSendOffer}
                    className="btn primary-button ml-15"
                    aria-label="button"
                    disableRipple="true"
                    disabled={endDateErr || payRateErr || values == null || jobStartDate == null}
                  >
                    {sendOfferEmailChecked ? "Send Offer Email" : "Send Offer"}
                  </Button>
                  </Typography>
                </Typography>
            </Box>
          </Modal>
            <CandidateProfileVideoPreview
              open={openVideoPreview}
              setOpen={setOpenVideoPreview}
              videoSrc={videoSrc}
              candidateName={candidateName}
            />
            {openScreeningQuestionDrawer &&
            <ScreeningAnswerDrawer
             openAssessment={openScreeningQuestionDrawer}
             setOpenAssessment={setOpenScreeningQuestionDrawer}
             setIsDrawerVisible={setIsDrawerVisible}
             question={questions}
             profileEssentials={profileEssentials}
             fieldMapping={keysArray}
             jobId={selectedJob}
             candidateId={props.candidate_id}
             handleDropdownClick={props.handleDropdownClick}
             isFromTalentConsole={props.isFromTalentConsole}
             getCandidatesCall={props.getCandidatesCall}
             setIsLoading={setIsLoading}
             openConfirmation={true}
             handleClosePopover={handleClosePopover}
             profileDetails={profileDetails}
             profileDetailsGroup={profileDetailsGroup}
             candidate={candidate}
             fetchData={fetchData}
            />
            }
            <OfferLetter
              setIsLoading={props.setIsLoading}
              setViewOfferDrawer={setViewOfferDrawer}
              setResendOfferHeading={false}
              viewOfferDrawer={viewOfferDrawer}
              jobId={selectedJob}
              candidateApplicationData={candidateApplicationData}
              jobStartDate={jobStartDate}
              jobCompletionDate={jobCompletionDate}
              payRate={payRate}
              expiryDate={values}
              sendOfferEmailChecked={sendOfferEmailChecked}
              currency={currency}
              updatedstatus={updatedstatus}
              candidate={props.candidate_id}
              status={props.status}
              // getCandidatesByStatus={getCandidatesByStatus}
              isFromMatchingCandidates={true}
              isFromShortlistCandidates={true}
              handleClosePopover={handleClosePopover}
              // resendOfferHeading={resendOfferHeading}
              // setResendOfferHeading={setResendOfferHeading}
            />
          </Grid>
        </Grid>
      </Drawer>
    </>
  );
}
