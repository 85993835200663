import React, { useState, useCallback, useEffect } from "react";
import {
  Typography,
  Box,
  Button,
  TextField,
  Grid,
  Link,
  IconButton,
  Radio,
  RadioGroup,
  TextareaAutosize,
  Switch,
  Select,
  InputAdornment,
  Modal,
  Input,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Rating,
  Checkbox,
  MenuItem
} from "@mui/material";
import messageIcon from "../../../../assets/images/messageIcon.svg";
import messageIconFilled from "../../../../assets/images/message-filled.svg";
import jobEyeIcon from "../../../../assets/images/jobEyeIcon.svg";
import wishlist from "../../../../assets/images/jobWishlistIcon.svg";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import wishlistFilled from "../../../../assets/images/wishlistFilled.svg";
import CandidateService from "../../../../Services/Enterprise/Candidate.service";
import { toast } from "react-toastify";
import CloseIcon from "@mui/icons-material/Close";
import InfoIcon from "@mui/icons-material/Info";
import DateFnsUtils from "@date-io/date-fns";
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import ConfirmDialog from "../../../../Components/shared/ConfirmDialog";
import { ReactComponent as NotesIcon } from "../../../../assets/images/enterprise-notes.svg";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
// import { Checkbox, FormControlLabel, FormGroup } from "@material-ui/core";
import ApiCalls from "../../../../api/customAxios";
import moment from "moment";
import debounce from "lodash/debounce";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import AccessService from "../../../../Services/Enterprise/Access.service";
import starEmpty from "../../../../assets/images/star-icon-empty.svg";
import starFilled from "../../../../assets/images/star-icon-filled.svg";
import { danger, success } from "../../../../helpers/message-box";
import offerDetails from "../../../../assets/images/gender-info.svg";
import { formatDate, handleSpaces, checkDateRangeForOfferExpiry, getDurationValue, getDurationPeriodForDropDown } from "../../../../helpers/helper-data";
import deleteIcon from "../../../../assets/images/deleteIcon.svg";
import docIcon from "../../../../assets/images/extension-icon/doc-icon.png";
import jpegIcon from "../../../../assets/images/extension-icon/jpeg.png";
import jpgIcon from "../../../../assets/images/extension-icon/jpg.png";
import zipIcon from "../../../../assets/images/extension-icon/zip.png";
import DeleteSignedDoc from "../../../../assets/images/extension-icon/delete-signed-doc.svg";
import Resumepdf from "../../../../assets/images/extension-icon/pdf-icon.png";
import pngIcon from "../../../../assets/images/extension-icon/png.png";
import { ReactComponent as NoteIcon } from "../../../../assets/images/profile-note-icon.svg";
import Infoicon from "../../../../assets/images/all-leads-info-icon.svg";
import TalentPoolManagementServiceClass from "../../../../Services/Enterprise/TalentPoolManagement.service";
import { Tooltip, TooltipTrigger, TooltipContent } from "react-tippy";
import ClearIcon from "@mui/icons-material/Clear";
import { useSelector } from "react-redux";
import ExclusiveWorkPermissionModal from "../../../../Components/shared/ExclusiveWorkPermissionModal";
import axios from "axios";
import SubmitScreeningQuestionsModal from "../../../../Components/shared/SubmitScreeningQuestionsModal";
import OfferLetter from "../../OfferLetter/GenerateOfferLetter";
import { useModal } from "../../../../ModalContext";
import { getCredentialingtoken } from "../../../../helpers/credentialingToken";

const label = { inputProps: { "aria-label": "Checkbox demo" } };

function createData(name, calories, fat, carbs, protein) {
  return { name, calories, fat, carbs, protein };
}

const rows = [
  createData(
    1,
    "22 Jan, 2022",
    "Kevin James",
    "The candidate is good in technical but communications skills were okay",
    "Java developer"
  ),
  createData(
    2,
    "22 Jan, 2022",
    "Jacob Mathew",
    "This candidate has extensive work experience, but I’m not sure whether [he/she] would stay motivated in this role for long. It’d be worth discussing their career goals in the next interview.",
    "UI Designer"
  ),
  createData(
    3,
    "22 Jan, 2022",
    "Lisa Charlie",
    "The candidate is good in technical but communications skills were okay",
    "Front-end developer"
  ),
];

export default function Job(props) {
  const appOrigin = useSelector((state) => state.location.origin);
  let navigate = useNavigate();
  const { state } = useLocation();
  // const { candidateApplicationData } = state;
  let candidateApplicationData;
  if (state) {
    candidateApplicationData = state.candidateApplicationData;
  } else {
    candidateApplicationData = props.candidateApplicationData;
  }
  const job = props.job;
  const ratingsSkeleton = {
    rating_overall: null,
    ratingDeliveryQuality: null,
    ratingScheduleAdherence: null,
    ratingStickingToCommitment: null,
    ratingValueForMoney: null,
    ratingRemarksByEnterprise: null,
  };

  const params = useParams();
  const [showMore, setShowMore] = useState(false);
  const [values, setValues] = useState(moment().add(2, "d").toDate());
  const [jobStartDate, setJobStartDate] = useState(null);
  const [jobCompletionDate, setJobCompletionDate] = useState(null);
  const [payRate, setPayRate] = useState("");
  const [endDateErr, setEndDateErr] = useState(null);
  const [payRateErr, setPayRateErr] = useState(null);
  const [hideStartDateClearIcon, setHideStartDateClearIcon] = useState(true);
  const [hideEndDateClearIcon, setHideEndDateClearIcon] = useState(true);
  const [open, setOpen] = useState(false);
  // const [openStatus, setOpenStatus] = useState(false);
  const [openShortlistRemarks, setOpenShortlistRemarks] = useState(false);
  const [openConfirmActionTerminateJob, setOpenConfirmActionTerminateJob] =
    useState(false);
  const handleCloseTerminateJob = () => setOpenConfirmActionTerminateJob(false);
  const [openConfirmActionCompleteJob, setOpenConfirmActionCompleteJob] =
    useState(false);
  const [shortlistingRemarks, setShortlistingRemarks] = useState("");
const [resendOfferHeading, setResendOfferHeading] = useState(false);
  const [viewOfferDrawer, setViewOfferDrawer] = useState(false);
  const [isResendOffer, setIsResendOffer] = useState(false);
  const [currency, setCurrency] = useState("USD");
  const [rejectReason, setRejectReason] = useState("");

  const [candidateApplication, setCandidateApplication] = React.useState(
    candidateApplicationData
  );
  const [openScreeningQuestionsModal, setOpenScreeningQuestionsModal] = useState(false);

  const [ratings, setRatings] = useState(ratingsSkeleton);
  const [reviewLenError, setReviewLenError] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const handleCloseShortlistRemarks = () => setOpenShortlistRemarks(false);
  const [currentOption, setCurrentOption] = useState(false);
  const [sendOfferEmailChecked, setSendOfferEmailChecked] = useState(true);

  const [openOffer, setOpenOffer] = useState(false);
  const handleEndDate = (date, duration) => {
    let valueOFDuration = getDurationValue(duration);
    let durationTime =  getDurationPeriodForDropDown(duration);
      const updatedDate = moment(date).add(valueOFDuration, durationTime.toLowerCase()).subtract(1, 'day').toDate();
      return updatedDate;
  };
  const handleOpenOffer = () => {
if (props.status) {
      props.setIsDrawerVisible(false);
    }
    setPayRate(props?.jobDetails?.hourlyRate);
    setCurrency(props?.jobDetails?.currency || "USD");
    setJobStartDate(props.jobDetails?.validFrom);
    setJobCompletionDate(formatDate(
      new Date(
        JSON.parse(
          JSON.stringify(props.jobDetails?.validTill).replace("23:59:59", "00:00:00")
        )
      )
    ));
    setOpenOffer(true);
  };
  const getDrawer = () => {
    if (props.status) {
      props.setIsDrawerVisible(true);
    }
  };
  const handleCloseOffer = () => {
    if (props.status) {
      props.setIsDrawerVisible(true);
    }
    setIsResendOffer(false);
    setOpenOffer(false);
    setdocumentFile("");
    setValues(moment().add(2, "d").toDate());
    setJobStartDate(null);
    setJobCompletionDate(null);
    setPayRate("");
    setEndDateErr(null);
    setPayRateErr(null);
  };
  const [openRejectReason, setOpenRejectionReason] = useState(false);
  const handleCloseRejectReason = () => setOpenRejectionReason(false);
  const { activeModal } = useModal();

  const [documentFile, setdocumentFile] = useState();
  const [documentIndex, setdocumentIndex] = useState();
  const [openDocumentIndex, setOpenDocumentIndex] = useState(false);
  const [openShortlistModal, setOpenShortlistModal] = useState(false);
  const handleOpenShortlistModal = (e) => setOpenShortlistModal(true);
  const handleCloseShortlistModal = () => setOpenShortlistModal(false);

  const [openExclusiveWorkPermissionModal, setOpenExclusiveWorkPermissionModal] = useState(false);
  const [restrictedAction, setRestrictedAction] = useState(null);
  const handleRestrictAction = (action) => {
    setRestrictedAction(action);
    setOpenExclusiveWorkPermissionModal(true);
  };

  const [OpenRatingCandidate, setRatingCandidate] = useState(false);
  const handleCloseRating = () => {
    setRatings(ratingsSkeleton);
    setRatingCandidate(false);
  };
  const handleOpenRating = async (e) => {
    e.preventDefault();
    await CandidateService.checkPriorityCandidate(
      params.candidate_id ? params.candidate_id : props.candidateId
    )
      .then((res) => {
        setStatus(res);
        setCurrentOption(false);
        setRatingCandidate(true);
      })
      .catch(function (error) {
        props.setIsLoading(false);
      });
  };
  const handleOptionChange = (event) => {
    setCurrentOption(event.target.checked);
  };
  const [status, setStatus] = useState("");
  const handleOpenRejection = async (e) => {
    e.preventDefault();
    await CandidateService.checkPriorityCandidate(
      params.candidate_id ? params.candidate_id : props.candidateId
    )
      .then((res) => {
        setStatus(res);
        setCurrentOption(false);
        setOpenRejectionReason(true);
      })
      .catch(function (error) {
        props.setIsLoading(false);
      });
  };
  const handleOpenTerminateJob = async (e) => {
    e.preventDefault();
    await CandidateService.checkPriorityCandidate(
      params.candidate_id ? params.candidate_id : props.candidateId
    )
      .then((res) => {
        setStatus(res);
        setCurrentOption(false);
        setOpenConfirmActionTerminateJob(true);
      })
      .catch(function (error) {
        props.setIsLoading(false);
      });
  };

const handleInputChange = (event) => {
    if (event.target.value.length > 13) {
        event.target.value = event.target.value.slice(0, 13);
    }
};
const getCandidateId =() => {
  props.candidate.candidateId;
};
const getCandidatesByStatus=(tabStatus) => {
  console.log("coming here");
        if (!props.status) {
          navigate(
            "/enterprise/jobs/" +
              params.job_id +
              "/matching-candidates?tab=" +
              params.status
          );
        } else {
          if (props.status === "shortlisted") {
            props.getCandidatesByStatus("S", true);
            props.closeMatchingCandidateDrawer();
            props.updatedstatus();
          } else if (props.status === "offered") {
            props.updatedstatus();
            props.getCandidatesByStatus("F", true);
            props.closeMatchingCandidateDrawer();
          } else {
            props.updatedstatus();
            props.getCandidatesByStatus("A", true);
            props.closeMatchingCandidateDrawer();
          }
        }
};
  const handleOpenRejectionReason = () => {
    props.setIsLoading(true);
    var payload = {
      jobId: params.job_id ? params.job_id : props.JobId,
      candidateId: params.candidate_id
        ? params.candidate_id
        : props.candidateId,
      rejectionReason: rejectReason ? rejectReason : null,
    };
    CandidateService.rejectApplication(payload)
      .then((response) => {
        if (currentOption) {
          TalentPoolManagementServiceClass.candidateUnbindRequests("S", [
            params.candidate_id ? params.candidate_id : props.candidateId,
          ]).then((res) => {
            success(
              `Candidate ${
                props.candidateApplicationData.firstName +
                " " +
                props.candidateApplicationData.lastName
              } is released to the shared pool.`
            );
          });
        } else {
          success("You have rejected this job application.");
        }
        if (props.status) {
          props.updatedstatus();
          props.getCandidatesByStatus("A", true);
          props.closeMatchingCandidateDrawer();
        }
        setOpenRejectionReason(false);
        setCurrentOption(false);
        setStatus("");
        setCandidateApplication(response);
        navigate(
          "/enterprise/jobs/" +
            params.job_id +
            "/matching-candidates?tab=applied"
        );
        props.setIsLoading(false);
      })
      .catch(function (error) {
        setOpenRejectionReason(false);
        props.setIsLoading(false);
      });
  };

  const handleOnboard = async () => {
    props.setIsLoading(true);
    var payload = {
      jobId: params.job_id ? params.job_id : props.JobId,
      candidateId: params.candidate_id
        ? params.candidate_id
        : props.candidateId,
    };
    await CandidateService.onBoard(payload)
      .then((response) => {
        setCandidateApplication(response);
        success("Candidate is onboarded.");
        props.setIsLoading(false);
        if (props.status === "offered") {
          props.updatedstatus();
          props.getCandidatesByStatus("F", true);
          props.closeMatchingCandidateDrawer();
        }
        if (!props.status) {
          navigate(
            "/enterprise/jobs/" +
              params.job_id +
              "/matching-candidates?tab=" +
              params.status
          );
        }
      })
      .catch(function (error) {
        props.setIsLoading(false);
      });
  };

  const handleCompleteJob = async (skipRatingAndReview) => {
    props.setIsLoading(true);
    var payload = {};
    if (skipRatingAndReview) {
      payload = {
        jobId: params.job_id ? params.job_id : props.JobId,
        candidateId: params.candidate_id
          ? params.candidate_id
          : props.candidateId,
        offboardingStatus: "S",
      };
    } else {
      payload = {
        ...ratings,
        jobId: params.job_id ? params.job_id : props.JobId,
        candidateId: params.candidate_id
          ? params.candidate_id
          : props.candidateId,
        offboardingStatus: "S",
      };
    }
    await CandidateService.Offboard(payload)
      .then((response) => {
        setCandidateApplication(response);
        success("Candidate's job is completed and offboarded successfully.");
        if (props.status) {
          props.updatedstatus();
          props.getCandidatesByStatus("O", true);
          props.closeMatchingCandidateDrawer();
        }
        if (!props.status) {
          navigate(
            "/enterprise/jobs/" +
              params.job_id +
              "/matching-candidates?tab=onboarded"
          );
        }

        props.setIsLoading(false);
      })
      .catch(function (error) {
        props.setIsLoading(false);
      });
    handleCloseRating();
  };

  const handleCloseCompleteJob = async () => {
    handleCompleteJob(true);
    setOpen(false);
  };
  const handleSkipAndSendOffer = () => {
    if (jobStartDate != null) {
      if (new Date(values).setHours(0, 0, 0, 0) > new Date(jobStartDate).setHours(0, 0, 0, 0)) {
      danger("Offer expiry date cannot be greater than job start date.");
      return;
    };
  };
    if (process.env.REACT_APP_INTEGRATE_CREDENTIALING === "Y" && props.jobDetails?.workflowUniqueIdentifier) {
      callcredentaling();
    } else {
      createOfferHandlerUpload();
    }
  };
  const callcredentaling = async () => {
    try {
      const data = await getCredentialingtoken();
      const firstName = props.candidateApplicationData.firstName;
      const lastName = props.candidateApplicationData.firstName;
      if (data) {
        const payload = {
          "workflowId": parseInt(props.jobDetails?.workflowUniqueIdentifier),
          "externalRefId": params.job_id ? params.job_id : props.JobId,
          "candidateDetail": {
            "firstName": firstName,
            "lastName": lastName ? lastName : "",
            "displayName": firstName + " " + (lastName ? lastName : ""),
            "emailId": "",
            "phoneNo": 0,
            "authType": "link",
            "password": "link"
          }
        };
        const response = await axios.post(`${process.env.REACT_APP_CREDENTIALING_MDM_SERVICE_URL}/credentialing/v1/api/assign/candidate/workflow`, payload, {
          headers: {
            Authorization: `Bearer ${data}`,
          },
        });
         createOfferHandlerUpload((response.data?.payload?.invitationCode));
      } else {
        // Handle the case where data is not available or falsy
        console.error("Credentialing token data is empty or falsy.");
        props.setIsLoading(false);
      }
    } catch (error) {
      // Handle errors from getCredentialingtoken
      console.error("Error while fetching credentialing token:", error);
      props.setIsLoading(false);
    }
  };
  const createOfferHandlerUpload = async (invitationCode) => {
    props.setIsLoading(true);
    var formData = new FormData();
    if (process.env.REACT_APP_INTEGRATE_CREDENTIALING === "Y") {
      formData.append(
            "request-body",
            new Blob(
              [
                JSON.stringify({
                  jobId: params.job_id ? params.job_id : props.JobId,
                  candidateId: params.candidate_id ? params.candidate_id : props.candidateId,
                  workflowUniqueIdentifier: (invitationCode ? invitationCode : null),
                  offerExpiryDate: values,
                  jobStartDate: jobStartDate,
                  jobCompletionDate: jobCompletionDate,
                  payRate: payRate ? parseFloat(payRate) : null,
                  currency: currency,
                }),
              ],
              {
                type: "application/json",
              }
            )
          );
    } else {
      formData.append(
        "request-body",
        new Blob(
          [
            JSON.stringify({
              jobId: params.job_id ? params.job_id : props.JobId,
              candidateId: params.candidate_id ? params.candidate_id : props.candidateId,
              offerExpiryDate: values,
              jobStartDate: jobStartDate,
              jobCompletionDate: jobCompletionDate,
              payRate: payRate ? parseFloat(payRate) : null,
              currency: currency,
            }),
          ],
          {
            type: "application/json",
          }
        )
      );
    }
    await CandidateService.createOffer(formData, sendOfferEmailChecked)
    .then((response) => {
    success("Candidate is offered");
    getCandidatesByStatus();
    setOpenOffer(false);
    props.setIsLoading(false);
    })
    .catch(function (error) {
      if (error.statuscode == 500) {
        danger("Oops! Something went wrong. Please try again later.");
    } else {
        danger("Something went Wrong");
    }
    if (props?.status) {
      props.closeMatchingCandidateDrawer();
    }
    setOpenOffer(false);
    props.setIsLoading(false);
    });
  };
  const handleCheckboxChange = (event) => {
    setSendOfferEmailChecked(event.target.checked);
  };
  const toggleCheckbox = () => {
    setSendOfferEmailChecked((prevChecked) => !prevChecked);
  };

  const handleTerminateOnboard = async () => {
    props.setIsLoading(true);
    var payload = {
      jobId: params.job_id ? params.job_id : props.JobId,
      candidateId: params.candidate_id
        ? params.candidate_id
        : props.candidateId,
      offboardingStatus: "X",
    };
    await CandidateService.Offboard(payload)
      .then((response) => {
        if (currentOption) {
          TalentPoolManagementServiceClass.candidateUnbindRequests("S", [
            params.candidate_id ? params.candidate_id : props.candidateId,
          ]).then((res) => {
            success(
              `Candidate ${
                props.candidateApplicationData.firstName +
                " " +
                props.candidateApplicationData.lastName
              } is released to the shared pool.`
            );
          });
        } else {
          success("You’ve terminated the candidate from this job.");
        }
        if (props.status) {
          props.updatedstatus();
          props.getCandidatesByStatus("A", true);
          props.closeMatchingCandidateDrawer();
        }
        setOpenConfirmActionTerminateJob(false);
        setStatus();
        setCurrentOption(false);
        setCandidateApplication(response);
        props.setIsLoading(false);
        if (props.status) {
          props.updatedstatus();
          props.getCandidatesByStatus("O", true);
          props.closeMatchingCandidateDrawer();
        }
        if (!props.status) {
          navigate(
            "/enterprise/jobs/" +
              params.job_id +
              "/matching-candidates?tab=onboarded"
          );
        }
      })
      .catch(function (error) {
        props.setIsLoading(false);
      });
  };

  const handleOffboard = async () => {
    props.setIsLoading(true);
    var payload = {
      jobId: params.job_id ? params.job_id : props.JobId,
      candidateId: params.candidate_id
        ? params.candidate_id
        : props.candidateId,
      offboardingStatus: "R",
    };
    await CandidateService.Offboard(payload)
      .then((response) => {
        setCandidateApplication(response);
        success("Candidate is Offboarded.");
        props.setIsLoading(false);
      })
      .catch(function (error) {
        props.setIsLoading(false);
      });
  };

  const createOfferHandler = async (isResendOffer) => {
    // if ((!jobStartDate && jobCompletionDate) || (jobStartDate && !jobCompletionDate)) {
      //   if (!jobStartDate) {
        //     danger("Job start date is required");
      //   } else {
        //     danger("Job end date is required");
      //   }
    //   return;
    // }
    if (jobStartDate != null) {
      if (new Date(values).setHours(0, 0, 0, 0) > new Date(jobStartDate).setHours(0, 0, 0, 0)) {
      danger("Offer expiry date cannot be greater than job start date.");
      return;
    };
  };
  if (isResendOffer) {
    setResendOfferHeading(true);
  }
  setOpenOffer(false);
  setViewOfferDrawer(true);
  // if (props.status) {
  //   props.closeMatchingCandidateDrawer();
  // }
    // props.setIsLoading(true);
    // var formData = new FormData();
    // formData.append("offer-document", documentFile ? documentFile.file : "");
    // if (process.env.REACT_APP_INTEGRATE_CREDENTIALING === "Y") {
      //   formData.append(
        //     "request-body",
        //     new Blob(
          //       [
    //         JSON.stringify({
              //           jobId: params.job_id ? params.job_id : props.JobId,
              //           candidateId: params.candidate_id
                //             ? params.candidate_id
                //             : props.candidateId,
              //           offerExpiryDate: values,
              //           jobStartDate: jobStartDate,
              //           jobCompletionDate: jobCompletionDate,
              //           payRate: Number(payRate),
              //           workflowUniqueIdentifier: (invitationCode ? invitationCode : null),
            //         }),
          //       ],
          //       {
    //         type: "application/json",
          //       }
    //     )
    //   );
    // } else {
    //   formData.append(
    //     "request-body",
    //     new Blob(
    //       [
    //         JSON.stringify({
    //           jobId: params.job_id ? params.job_id : props.JobId,
              //           candidateId: params.candidate_id
                //             ? params.candidate_id
                //             : props.candidateId,
              //           offerExpiryDate: values,
              //           jobStartDate: jobStartDate,
              //           jobCompletionDate: jobCompletionDate,
              //           payRate: Number(payRate),
    //         }),
          //       ],
          //       {
    //         type: "application/json",
          //       }
    //     )
    //   );
    // }
    // await CandidateService.createOffer(formData)
      //   .then((response) => {
        //     setCandidateApplication(response);
        //     success("Candidate is offered");
        //     handleCloseOffer();
        //     props.setIsLoading(false);
        //     if (props.status === "shortlisted") {
          //       props.getCandidatesByStatus("S", true);
          //       props.closeMatchingCandidateDrawer();

          //       props.updatedstatus();
        //     }
    //     if (!props.status) {
          //       navigate(
            //         "/enterprise/jobs/" +
              //           params.job_id +
              //           "/matching-candidates?tab=" +
              //           params.status
          //       );
        //     }
    //   })
      //   .catch(function (error) {
        //     props.setIsLoading(false);
      //   });
  };

  const handleChangeShortListRemarks = (event) => {
    setShortlistingRemarks(event.target.value);
  };

  const handleChangeRejectReason = (event) => {
    setRejectReason(event.target.value);
  };
  const unshortListHandler = async () => {
    props.setIsLoading(true);
    var payload = {
      jobId: params.job_id ? params.job_id : props.JobId,
      candidateId: params.candidate_id
        ? params.candidate_id
        : props.candidateId,
    };
    await CandidateService.unshortList(payload)
      .then((response) => {
        setCandidateApplication(response);
        success("This candidate is unshortlisted.");
        props.setIsLoading(false);
        if (props.status) {
          props.updatedstatus();
          props.getCandidatesByStatus("S", true);
          props.closeMatchingCandidateDrawer();
        }
        if (!props.status) {
          navigate(
            "/enterprise/jobs/" +
              params.job_id +
              "/matching-candidates?tab=" +
              params.status
          );
        }
      })
      .catch(function (error) {
        props.setIsLoading(false);
        if (error) {
          danger(error.errorMessage);
          // if (error.response.status !== 403) {
          //   danger(error.response.data.errorDescription);
          // }
        }
      });
  };
  const shortListHandler = async (shortlistingRemarks, answers) => {
    props.setIsLoading(true);
    var payload = {
      jobId: params.job_id ? params.job_id : props.JobId,
      candidateId: params.candidate_id
        ? params.candidate_id
        : props.candidateId,
      shortlistingRemarks: shortlistingRemarks ? shortlistingRemarks : null,
      answers: JSON.stringify(answers),
    };
    await CandidateService.shortList(payload)
      .then(async (response) => {
        setCandidateApplication(response);
        let jobData = {};
        await AccessService.getJobDetails(
          params.job_id ? params.job_id : props.JobId
        ).then((resp) => {
          jobData = resp;
        });
        if (jobData?.externalJobId) {
          await ApiCalls.get(
            process.env.REACT_APP_API_URL +
              `candidates/missingFields/${
                params.candidate_id
                ? params.candidate_id
                : props.candidateId
              }?forExternal=true&jobId=${params.job_id ? params.job_id : props.JobId}`
          ).then((res) => {
            let response = [];
            console.log(res);
            response = JSON.parse(res);
            response = response.filter((i) => {
              return i != "availableDate";
            });
            console.log(response);
            if (response?.length) {
              // success(
              //   `Candidate is shortlisted, but their details cannot be forwarded to VMS due to missing information.`
              // );
              success("This candidate is shortlisted.");
            } else {
              success("This candidate is shortlisted.");
            }
          });
        } else {
          success("This candidate is shortlisted.");
        }
        props.setIsLoading(false);
        if (props.setIsScreeningQsnsLoading) {
          props.setIsScreeningQsnsLoading(false);
        }
        setOpenShortlistModal(false);
        setOpenScreeningQuestionsModal(false);
        setOpen(false);
        if (props.status) {
          if (props.status === "matching-candidate") {
            props.getMatchingCandidates(params.job_id, false);
          } else {
            props.getCandidatesByStatus("A", false);
          }
          props.updatedstatus();
          props.closeMatchingCandidateDrawer();
        }
        if (!props.status) {
          navigate(
            "/enterprise/jobs/" +
              params.job_id +
              "/matching-candidates?tab=" +
              params.status
          );
        }
      })
      .catch(function (error) {
        props.setIsLoading(false);
        if (props.setIsScreeningQsnsLoading) {
          props.setIsScreeningQsnsLoading(false);
        }
        if (error) {
          if (error.statusCode == 500 && (error.errorMessage != "Number of shortlisted candidates exceeded the limit")) {
            danger("Oops! Something went wrong. Please try again later.");
          } else {
            danger(error.errorMessage);
          }
          // if (error.response.status !== 403) {
          //   danger(error.response.data.errorDescription);
          // }
        }
      });
  };

  const handleHideCandidate = async () => {
    props.setIsLoading(true);
    var payload = {
      jobId: params.job_id ? params.job_id : props.JobId,
      candidateId: params.candidate_id
        ? params.candidate_id
        : props.candidateId,
    };
    await CandidateService.hideCandidate(payload)
      .then((response) => {
        setCandidateApplication(response);
        success("This candidate is hidden.");
        props.setIsLoading(false);
        navigate(
          "/enterprise/jobs/" +
            params.job_id +
            "/matching-candidates?tab=" +
            params.status
        );
      })
      .catch(function (error) {
        props.setIsLoading(false);
        if (error) {
          danger(error.errorMessage);
          // if (error.response.status !== 403) {
          //   danger(error.response.data.errorDescription);
          // }
        }
      });
  };

  const ratingChanged = (e, fieldName) => {
    if (fieldName === "ratingRemarksByEnterprise") {
      if (e.length > 256) {
        setReviewLenError(true);
      } else if (reviewLenError) {
        setReviewLenError(false);
      }
    }
    setRatings({ ...ratings, ...{ [fieldName]: e } });
  };

  function ValidateSingleInput(event) {
    var validFileExtensions = ["pdf", "doc", "docx", "zip"];
    var file = event.target.files[0];
    if (file) {
      if (file.name.length <= 80) {
        var extension = file.name.split(".").pop();
        if (validFileExtensions.includes(extension)) {
          setdocumentFile({
            filename: file.name,
            extension: extension,
            file: file,
          });
        } else {
          danger("Please upload only DOC, DOCX, PDF and ZIP file formats!");
          event.target.value = "";
        }
      } else {
        danger("Filename is too long!");
        event.target.value = "";
      }
    }
    return true;
  }

  const handleDeleteDocumentWithIndex = () => {
    setOpenDocumentIndex(true);
  };
  const deleteDocumentFromIndex = () => {
    setdocumentFile();
    setdocumentIndex();
    setOpenDocumentIndex(false);
    success("Document is deleted successfully.");
  };

  const anchorRef = React.useRef(null);
  const handleToggle = () => {
    setOpenStatus((prevOpen) => !prevOpen);
    getChecklist();
    getAssessments();
  };
  const handleCloseStatus = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }
    setOpenStatus(false);
  };
  function handleListKeyDown(event) {
    if (event.key === "Tab") {
      event.preventDefault();
      setOpenStatus(false);
    } else if (event.key === "Escape") {
      setOpenStatus(false);
    }
  }
  const handleChangeStartDate = (date) => {
    setJobStartDate(date);
    let endDate = props.jobDetails?.duration ?  handleEndDate(date, props.jobDetails?.duration) : jobCompletionDate;
    setJobCompletionDate(endDate);
   let startTo = new Date(endDate);
    let startFrom = new Date(formatDate(date));
    if (jobCompletionDate) {
      if (startFrom > startTo) {
        setEndDateErr("Job end date cannot be lesser than job start date");
      } else {
        setEndDateErr(null);
      }
    } else if (!date) {
      setEndDateErr(null);
    }
  };
  const handleChangeEndDate = (date) => {
    setJobCompletionDate(date);
    if (!date) {
      setEndDateErr(null);
      return;
    }
    let today = new Date();
    let startFrom = new Date(jobStartDate);
    let startTo = new Date(formatDate(date));
    if (jobStartDate) {
      if (startFrom > startTo) {
        setEndDateErr("Job end date cannot be lesser than job start date");
      } else {
        setEndDateErr(null);
      }
    } else {
      if (today > startTo) {
        setEndDateErr("Job end date cannot be lesser than today's date");
      } else {
        setEndDateErr(null);
      }
    }
  };
  const handleChangePayRate = (event) => {
    const inputValue = event.target.value;
    if (inputValue.length > 14) {
      return;
    }
    if (inputValue.trimStart() === "") {
      setPayRate(inputValue.trim());
      setPayRateErr(null);
    } else if (!inputValue.match(/^(\d{1,10}|\d{1,10}\.\d{1,3}|\d{1,9}\.\d{1,3}|\.\d{1,3})$/)) {
      setPayRateErr("Please enter a valid pay rate");
      setPayRate(inputValue);
    } else {
      setPayRate((inputValue || inputValue == 0) ? inputValue : null);
      setPayRateErr(null);
    }
  };

  return (
    <>
      <Grid item>
        <>
          <Box sx={{ ml: 2 }} className="responsive-checklist">
            {(params.status === "matching-candidate" ||
              props.status === "matching-candidate") && (
              <Button
                className="btn primary-button"
                onClick={()=>{
                  if (props.allowedToWorkInOtherJobs === "N" && candidateApplicationData.occupiedCandidate) {
                    handleRestrictAction("shortlist");
                  } else {
                    // handleOpenShortlistModal();
                    setOpenScreeningQuestionsModal(true);
                  }
                }}
                onKeyPress={()=>{
                  if (props.allowedToWorkInOtherJobs === "N" && candidateApplicationData.occupiedCandidate) {
                    handleRestrictAction("shortlist");
                  } else {
                    // handleOpenShortlistModal();
                    setOpenScreeningQuestionsModal(true);
                  }
                }}
                // onClick={shortListHandler}
                tabIndex={0}
                disableRipple="true"
              >
                Shortlist
              </Button>
            )}
          </Box>
          {(params.status === "onboarded" || props.status === "onboarded") && (
            <Typography sx={{ ml: 0 }} className="d-content jobDetail-user">
              <Button
                onClick={handleOpenTerminateJob}
                className="btn secondary-button font-weight-normal f-14 descrip-status mr-10"
                disableRipple="true"
                style={{ padding: "5px 13px" }}
              >
                Terminate Job
              </Button>
              <Button
                onClick={handleOpenRating}
                className="btn primary-button font-weight-normal f-14 descrip-status"
                disableRipple="true"
                style={{ padding: "5px 13px" }}
              >
                Complete Job
              </Button>
            </Typography>
          )}
          {(params.status === "shortlisted" ||
            props.status === "shortlisted") && (
            <Typography sx={{ ml: 3 }} className="responsive-checklist">
              <Button
                disabled={
                  candidateApplicationData.dormantFlag &&
                  candidateApplicationData.dormantFlag === "N"
                    ? true
                    : false
                }
                onClick={()=>{
                  if (props.allowedToWorkInOtherJobs === "N" && candidateApplicationData.occupiedCandidate) {
                    handleRestrictAction("offer");
                  } else {
setJobStartDate(null);
    setJobCompletionDate(null);
    setPayRate("");
    setEndDateErr(null);
    setPayRateErr(null);
                                    setResendOfferHeading(false);
                    handleOpenOffer();
                  }
                }}
                className="btn primary-button font-weight-normal f-14 descrip-status"
                disableRipple="true"
              >
                Offer
              </Button>
              {candidateApplicationData.dormantFlag &&
                candidateApplicationData.dormantFlag === "N" && (
                  <Tooltip
                    title="Currently, the candidate is inactive. You will receive a notification when the candidate is active"
                    position="top"
                    trigger="mouseenter"
                    animation="fade"
                    arrow
                  >
                    <IconButton aria-label="Info icon">
                      <InfoIcon aria-label="Info icon" />
                    </IconButton>
                  </Tooltip>
                )}
            </Typography>
          )}
{(params.status === "offered" ||
            props.status === "offered") && (candidateApplicationData.offerExpiryDate != null && checkDateRangeForOfferExpiry(candidateApplicationData.offerExpiryDate) && (candidateApplicationData.candidateProcessStatus == "A" || candidateApplicationData.candidateProcessStatus == null) && candidateApplicationData.enterpriseProcessStatus != "R" && candidateApplicationData.enterpriseProcessStatus === "F") && (
            <Typography sx={{ ml: 3 }} className="responsive-checklist">
              <Button
                disabled={
                  candidateApplicationData.dormantFlag &&
                  candidateApplicationData.dormantFlag === "N"
                    ? true
                    : false
                }
                onClick={()=>{
                  if (props.allowedToWorkInOtherJobs === "N" && candidateApplicationData.occupiedCandidate) {
                    handleRestrictAction("offer");
                  } else {
                    setValues(moment().add(2, "d").toDate());
                                    setIsResendOffer(true);
                                    setResendOfferHeading(false);
                                    setJobStartDate(null);
    setJobCompletionDate(null);
    setPayRate("");
    setEndDateErr(null);
    setPayRateErr(null);
                    handleOpenOffer();
                  }
                }}
                className="btn primary-button font-weight-normal f-14 descrip-status"
                disableRipple="true"
              >
                Resend Offer
              </Button>
              {candidateApplicationData.dormantFlag &&
                candidateApplicationData.dormantFlag === "N" && (
                  <Tooltip
                    title="Currently, the candidate is inactive. You will receive a notification when the candidate is active"
                    position="top"
                    trigger="mouseenter"
                    animation="fade"
                    arrow
                  >
                    <IconButton aria-label="Info icon">
                      <InfoIcon aria-label="Info icon" />
                    </IconButton>
                  </Tooltip>
                )}
            </Typography>
          )}

          {(params.status == "applied" || props.status === "applied") &&
            props.candidateApplicationData.enterpriseProcessStatus !== "R" && (
              <Typography sx={{ ml: 0 }}>
                <Button
                  onClick={handleOpenRejection}
                  className="btn secondary-button font-weight-normal f-14 descrip-status"
                  disableRipple="true"
                >
                  Reject
                </Button>
                <Button
                  onClick={()=>{
                    if (props.allowedToWorkInOtherJobs === "N" && candidateApplicationData.occupiedCandidate) {
                      handleRestrictAction("shortlist");
                    } else {
                      // handleOpenShortlistModal();
                      setOpenScreeningQuestionsModal(true);
                    }
                  }}
                  onKeyPress={()=>{
                    if (props.allowedToWorkInOtherJobs === "N" && candidateApplicationData.occupiedCandidate) {
                      handleRestrictAction("shortlist");
                    } else {
                      // handleOpenShortlistModal();
                      setOpenScreeningQuestionsModal(true);
                    }
                  }}
                  className="btn primary-button font-weight-normal f-14 ml-10 descrip-status"
                  disableRipple="true"
                >
                  Shortlist
                </Button>
                {candidateApplicationData.dormantFlag &&
                  candidateApplicationData.dormantFlag === "N" && (
                    <Tooltip
                      title="Currently, the candidate is inactive. You will receive a notification when the candidate is active"
                      position="top"
                      trigger="mouseenter"
                      animation="fade"
                      arrow
                    >
                      <IconButton>
                        <InfoIcon />
                      </IconButton>
                    </Tooltip>
                  )}
              </Typography>
            )}

          {/* show onboard button only if candidate has accepted offer i.e P */}
          {(params.status === "offered" || props.status === "offered") &&
            candidateApplicationData.enterpriseProcessStatus === "F" &&
            candidateApplicationData.candidateProcessStatus === "P" && (
              <Typography className="descrip-offer matching-details-align">
                <Button
                  onClick={()=>{
                    if (props.allowedToWorkInOtherJobs === "N" && candidateApplicationData.occupiedCandidate) {
                      handleRestrictAction("onboard");
                    } else {
                      handleOnboard();
                    }
                  }}
                  className="btn primary-button font-weight-normal f-14 descrip-offer matching-details-align"
                  disableRipple="true"
                >
                  Onboard
                </Button>
              </Typography>
            )}
        </>
      </Grid>
      <Modal
        open={openShortlistModal}
        onClose={handleCloseShortlistModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box className="modal-content inputcontrasts">
          <Typography className="align-left">
            <Typography id="modal-title" className="d-flex align-between">
              <Typography variant="h5" className="f-20 font-weight-600 heading-text-color">
                Shortlist Candidate
              </Typography>
              <Typography
                align="right"
                className="cursor-hover gray7 icon-button"
              >
                <CloseIcon
                  onClick={handleCloseShortlistModal}
                  onKeyPress={handleCloseShortlistModal}
                  aria-label="Close icon"
                  tabIndex={0}
                />
              </Typography>
            </Typography>
            <Typography id="modal-description">
              <Box>
                <p
                  className="f-14 gray7 mb-0 font-weight-500 mt-0 gray7"
                  align="left"
                >
                  Remarks
                </p>
                <TextField
                  type="text"
                  variant="outlined"
                  multiline
                  rows={2}
                  rowsmax={6}
                  onChange={handleChangeShortListRemarks}
                  onKeyPress={handleSpaces}
                  className="form-control input-group mb-0 inputcontrasts textarea-input"
                  placeholder="Enter Remarks"
                  autoFocus
                />
              </Box>
            </Typography>
            <Typography className="justify-center d-flex mt-20">
              <Button
                onClick={shortListHandler}
                onKeyPress={shortListHandler}
                className="btn primary-button"
                aria-label="Shortlist"
                disableRipple="true"
              >
                Shortlist
              </Button>
            </Typography>
          </Typography>
        </Box>
      </Modal>
      <Modal
        open={openOffer}
        onClose={handleCloseOffer}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box className="modal-content inputcontrasts">
          <Typography className="align-left">
            <Typography id="modal-title" className="d-flex align-between">
              <Typography variant="h5" className="f-20 font-weight-600 heading-text-color">
                {isResendOffer? "Resend Offer" : "Offer"}
                <Typography className="f-14 font-weight-500 m-t-5">
                You can generate an offer letter or send the offer directly.
                </Typography>
              </Typography>
              <Typography
                align="right"
                className="cursor-hover gray7 icon-button"
              >
                <CloseIcon
                  onClick={handleCloseOffer}
                  onKeyPress={handleCloseOffer}
                  aria-label="Close icon"
                  tabIndex={0}
                />
              </Typography>
            </Typography>
            <Typography id="modal-description">
              <Grid container spacing={1}>
                <Grid item lg={6} md={6} xs={12}>
                  <Box className="offerPayRateAlign">
                    <p
                      className="f-14 gray9 mb-0 font-weight-500 mt-0"
                      align="left"
                    >
                      Expiry Date<span className="text-black">*</span>
                    </p>
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                      <DatePicker
                        className="form-control input-group mb-0 borderRadius-l inputcontrasts position-issue payRatePaddingBottom"
                        value={values}
                        onChange={(newValue) => {
                          setValues(newValue);
                        }}
                        disablePast
                        format="MMM dd, yyyy"
                        placeholder="Offer expiry date"
                        disableFuture={false}
                      />
                    </MuiPickersUtilsProvider>
                  </Box>
                </Grid>
                <Grid item lg={6} md={6} xs={12}>
                  <Box className="offerPayRateAlign">
                    <p
                      className="f-14 gray9 mb-0 font-weight-500 mt-0"
                      align="left"
                    >
                      Pay Rate
                    </p>
<Grid container spacing={1} lg={12} md={12} xs={12} className='m-0'>
                    <Grid item lg={3} md={3} xs={4} className='pl-0 pt-7'>
                    <Typography
                      className="d-flex align-center"
                                >
                                    <Box className="p-0 w-100 payrate-closure">
                                        <Select
                                            // disabled={value}
                                            inputProps={{
                                                name: "curreny",
                                                id: "uncontrolled-native",
                                            }}
                                            className="mb-0 w-100 inputcontrasts form-control input-group discipline-input pay-rate-input pb-7"
                                            name="curreny"
                                            value={currency}
                                            onChange={(e) => {
                                                setCurrency( e.target.value);
                                            }}
                                            displayEmpty
                                        >
                                            <MenuItem value="USD" className='selectBox'>USD</MenuItem>
                                            <MenuItem value="GBP" className='selectBox'>GBP</MenuItem>
                                            <MenuItem value="EUR" className='selectBox'>EUR</MenuItem>
                                            <MenuItem value="CHF" className='selectBox'>CHF</MenuItem>
                                            <MenuItem value="CZK" className='selectBox'>CZK</MenuItem>
                                            <MenuItem value="INR" className='selectBox'>INR</MenuItem>
                                        </Select>
                                    </Box>
                                </Typography>
                            </Grid>
                            <Grid item lg={9} md={9} xs={8} className="pt-7">
                    <Typography
                      className={`d-flex align-center payrate-closure ${payRateErr ? "is-invalid-payRate" : ""
                      }`}
                    >
                    <Input
                      type="text"
                      name="hourlyRateNumber"
                                        id="hourlyRateNumber"
                      className="mb-0 inputcontrasts form-control input-group w-100 pb-6"
                      margin="normal"
                      placeholder="Enter pay rate"
// disabled={value}
                      value={payRate}
                      onChange={(e) => handleChangePayRate(e)}
                    // onInput={handleInputChange}
                                        endAdornment={
                                            <InputAdornment position="end">
                    <Typography className="f-16 font-weight-600 gray11">/ hour</Typography>
                    </InputAdornment>
                                        }
                                    />
                    </Typography>
                    <span className="danger-color error-msg">
                      {" "}
                      {payRateErr}
                    </span>
</Grid>
                    </Grid>
                  </Box>
                </Grid>
                <Grid item lg={6} md={6} xs={12}>
                  <Box className="galaxy-width-input">
                    <p
                      className="f-14 gray9 mb-0 font-weight-500 mt-0"
                      align="left"
                    >
                      Job Start Date<span className="text-black">*</span>
                    </p>
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                      <DatePicker
                        className="form-control input-group mb-0 borderRadius-l inputcontrasts position-issue"
                        value={jobStartDate}
                        onChange={(date) => handleChangeStartDate(date)}
                        disablePast
                        format="MMM dd, yyyy"
                        placeholder="Job start date"
                        id="start-date"
                        inputProps={{ role: "textbox" }}
minDate={
                          values ? new Date(values) : null
                        }
                        TextFieldComponent={(params) => (
                          <div
                            style={{ position: "relative" }}
                            onMouseEnter={() =>
                              setHideStartDateClearIcon(false)
                            }
                            onMouseLeave={() => setHideStartDateClearIcon(true)}
                          >
                            <Input {...params} readOnly />
                            {params.value && !hideStartDateClearIcon && (
                              <IconButton
                                style={{
                                  position: "absolute",
                                  top: "80%",
                                  right: "6px",
                                  transform: "translateY(-80%)",
                                }}
                                onClick={() => handleChangeStartDate(null)}
                              >
                                <ClearIcon
                                  style={{ fontSize: "5px" }}
                                  aria-label="Clear icon"
                                  className="Clearicon-issue"
                                />
                              </IconButton>
                            )}
                          </div>
                        )}
                      />
                    </MuiPickersUtilsProvider>
                  </Box>
                </Grid>
                <Grid item lg={6} md={6} xs={12}>
                  <Box className="galaxy-width-input">
                    <p
                      className="f-14 gray9 mb-0 font-weight-500 mt-0"
                      align="left"
                    >
                      Job End Date
                    </p>
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                      <DatePicker
                        id="refreshEndDate"
                        className={`form-control input-group mb-0 borderRadius-l inputcontrasts position-issue ${endDateErr ? "is-invalid" : ""
                        }`}
                        value={jobCompletionDate}
                        onChange={(date) => handleChangeEndDate(date)}
                        format="MMM dd, yyyy"
                        placeholder="Job end date"
                        disablePast
                        minDate={
                          new Date(new Date(jobStartDate).getTime() + 86400000)
                        }
                        error={false}
                        helperText={null}
                        inputProps={{ role: "textbox" }}
                        TextFieldComponent={(params) => (
                          <div
                            style={{ position: "relative" }}
                            onMouseEnter={() => setHideEndDateClearIcon(false)}
                            onMouseLeave={() => setHideEndDateClearIcon(true)}
                          >
                            <Input {...params} readOnly />
                            {params.value && !hideEndDateClearIcon && (
                              <IconButton
                                style={{
                                  position: "absolute",
                                  top: "80%",
                                  right: "6px",
                                  transform: "translateY(-80%)",
                                }}
                                onClick={() => handleChangeEndDate(null)}
                              >
                                <ClearIcon
                                  style={{ fontSize: "5px" }}
                                  aria-label="Clear icon"
                                  className="Clearicon-issue"
                                />
                              </IconButton>
                            )}
                          </div>
                        )}
                      />
                    </MuiPickersUtilsProvider>
                    <span className="danger-color error-msg">
                      {" "}
                      {endDateErr}
                    </span>
                  </Box>
                </Grid>
              </Grid>
              {/* <Grid item lg={12} md={12} xs={12} className="mt-20 mb-10">
                <Grid
                  item
                  className="justify-between pb-5 border-bottom-1 d-flex"
                >
                  <span className="f-14 gray9 font-weight-400">
                    Document
                    <Tooltip
                      position="right"
                      title="You can upload pdf, doc, docx or zip file"
                      trigger="mouseenter"
                      animation="fade"
                      arrow
                    >
                      <IconButton aria-label="Info icon">
                        <InfoIcon aria-label="Info icon" />
                      </IconButton>
                    </Tooltip>
                  </span>
                  {!documentFile && (
                    <Link
                      className="link-color f-14 text-decoration-none cursor-hover m-r-10"
                      variant="contained"
                      component="label"
                      style={{ marginTop: "6px" }}
                      tabIndex={0}
                    >
                      {" "}
                      <input
                        type="file"
                        onChange={ValidateSingleInput}
                        hidden
                      />
                      Attach File +
                    </Link>
                  )}
                </Grid>
                <Grid item className="justify-between mt-10 d-flex">
                  <Grid item className="d-flex workDocument align-center">
                    {documentFile && documentFile.extension === "pdf" && (
                      <img
                        src={Resumepdf}
                        alt="img"
                        className="m-r-10 file-extension-image wth-50"
                        width="40px"
                        height="40px"
                        style={{ objectFit: "cover" }}
                      />
                    )}
                    {documentFile && documentFile.extension === "doc" && (
                      <img
                        src={docIcon}
                        alt="img"
                        className="m-r-10 file-extension-image wth-50"
                        width="50px"
                        height="50px"
                        style={{ objectFit: "cover" }}
                      />
                    )}
                    {documentFile && documentFile.extension === "zip" && (
                      <img
                        src={zipIcon}
                        alt="img"
                        className="m-r-10 file-extension-image wth-50"
                        width="50px"
                        height="50px"
                        style={{ objectFit: "cover" }}
                      />
                    )}
                    {documentFile && documentFile.extension === "docx" && (
                      <img
                        src={docIcon}
                        alt="img"
                        className="m-r-10 file-extension-image wth-50"
                        width="50px"
                        height="50px"
                        style={{ objectFit: "cover" }}
                      />
                    )}
                    {documentFile && (
                      <p className="textEllipse w-500">
                        {documentFile.filename}
                      </p>
                    )}
                  </Grid>
                  {documentFile && (
                    <Grid
                      item
                      className="d-flex sub-text-color font-13 align-center d-flex justify-center"
                    >
                      <span
                        onClick={() => handleDeleteDocumentWithIndex()}
                        style={{ cursor: "pointer" }}
                        onKeyPress={() => handleDeleteDocumentWithIndex()}
                        tabIndex={0}
                      >
                        <img
                          className="sub-text-font m-r-10"
                          src={DeleteSignedDoc}
                          alt="delete icon"
                          tabIndex={0}
                        />
                      </span>
                    </Grid>
                  )}
                </Grid>
              </Grid> */}
              <Box className="galaxy-width-input mt-10">
            <Typography className="mt-2 display-flex align-center offer-letter-div">
                  <Checkbox
                    {...label}
                    checked={sendOfferEmailChecked}
                    disableRipple={true}
                    sx={{
                      paddingLeft: "0px",
                      color: "#98A2B3",
                      "&.Mui-checked": {
                        color: getComputedStyle(
                          document.documentElement
                        ).getPropertyValue(
                          "--primary-color"
                        ),
                      },
                    }}
                  onClick={handleCheckboxChange}
                  onKeyPress={handleCheckboxChange}
                  />
                <span
                  className="cursor-hover f-14 font-weight-400"
                  style={{ marginTop: "-2px" }}
                  onClick={toggleCheckbox}
                  onKeyPress={toggleCheckbox}
                >
                  By unchecking this box, the offer email will not be sent to the candidate.
                </span>
              </Typography>
              </Box>
            </Typography>
            <Typography className="justify-center d-flex mt-20">
              <Button
                onClick={() => {
                  createOfferHandler(isResendOffer);
                }}
                className="btn secondary-button"
                disableRipple="true"
                disabled={endDateErr || payRateErr || values == null|| jobStartDate == null || (isResendOffer&& values == null)}
              >
                Prepare Offer Letter
              </Button>
              <Button
                onClick={handleSkipAndSendOffer}
                className="btn primary-button ml-15"
                disableRipple="true"
                disabled={endDateErr || payRateErr || values == null || jobStartDate == null || (isResendOffer && values == null)}
              >
                {sendOfferEmailChecked ? "Send Offer Email" : "Send Offer"}
              </Button>
            </Typography>
          </Typography>
        </Box>
      </Modal>

      {/* Submit Screening Questions Modal */}
      <SubmitScreeningQuestionsModal
        openScreeningQuestionsModal={openScreeningQuestionsModal}
        setOpenScreeningQuestionsModal={setOpenScreeningQuestionsModal}
        questionsAnswers={props.jobDetails?.questions}
        jobDetails={props.jobDetails}
        candidate={candidateApplication}
        shortListHandler={shortListHandler}
        setIsLoading={props.setIsScreeningQsnsLoading || props.setIsLoading}
        tabValue={params.status ? params.status : props.status}
        isFromDrawer={props.isFromDrawer}
      />

      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box className="modal-content inputcontrasts">
          <Typography className="align-left">
            <Typography id="modal-title" className="d-flex align-between">
              <Typography variant="h5" className="f-24 font-weight-600 heading-text-color">
                Shortlist Candidate
              </Typography>
              <Typography
                align="right"
                className="cursor-hover sub-text-color icon-button"
              >
                <CloseIcon
                  onClick={handleClose}
                  onKeyPress={handleClose}
                  tabIndex={0}
                  aria-label="Close icon"
                />
              </Typography>
            </Typography>
            <Typography id="modal-description">
              <Box>
                <p
                  className="sub-text-font mb-0 font-weight-500 mt-0"
                  align="left"
                >
                  Remarks
                </p>
                <TextField
                  variant="outlined"
                  multiline
                  rows={2}
                  rowsmax={6}
                  onChange={handleChangeShortListRemarks}
                  onKeyPress={handleSpaces}
                  className="form-control input-group mb-0 inputcontrasts textarea-input"
                  placeholder="Enter Remarks"
                />
              </Box>
            </Typography>
            <Typography className="justify-center d-flex mt-20">
              <Button
                onClick={shortListHandler}
                className="btn primary-button"
                disableRipple="true"
              >
                Shortlist
              </Button>
            </Typography>
          </Typography>
        </Box>
      </Modal>
      {/* Rejection Reason */}
      <Modal
        open={openRejectReason}
        onClose={handleCloseRejectReason}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box className="modal-content inputcontrasts">
          <Typography className="align-left">
            <Typography id="modal-title" className="d-flex align-between">
              <Typography variant="h5" className="f-24 font-weight-600 heading-text-color">
                Rejection Reason
              </Typography>
              <Typography
                align="right"
                className="cursor-hover sub-text-color icon-button"
              >
                <CloseIcon
                  onClick={handleCloseRejectReason}
                  onKeyPress={handleCloseRejectReason}
                  aria-label="Close icon"
                  tabIndex={0}
                />
              </Typography>
            </Typography>
            <Typography id="modal-description">
              {status === "Z" && (
                <>
                  <Typography>
                    <Box className="f-16 font-weight-400">
                      Do you want to reject this candidate?
                    </Box>
                    {currentOption &&
                    <Box className="f-14 font-weight-400 sub-text-color mt-5">
                      Note: Once you reject the application, candidate will be
                      released to the shared talent pool.
                    </Box>}
                  </Typography>
                  <Typography className="switch-case-section d-flex align-center mt-20">
                    <Switch
                      checked={currentOption}
                      onChange={handleOptionChange}
                      inputProps={{ "aria-label": "controlled" }}
                    />
                    <Box className="f-14 font-weight-400 ml-10">
                      Release to Shared Talent Pool
                    </Box>
                  </Typography>
                  <Box className="f-12 font-weight-400 sub-text-color mt-10 d-flex">
                    <InfoIcon
                      aria-label="Info icon"
                      className="sub-text-color mr-5 mt-4"
                    />
                    {/* <img src={Infoicon} alt="info icon" className="mr-5" /> */}
                    By enabling shared talent pool, your exclusive talent will
                    be shared with other enterprises in the platform
                  </Box>
                </>
              )}
              <Box className="mt-5">
                <TextField
                  variant="outlined"
                  aria-label="minimum height"
                  multiline
                  rows={2}
                  rowsmax={6}
                  type="text"
                  onChange={handleChangeRejectReason}
                  onKeyPress={handleSpaces}
                  margin="normal"
                  // autoFocus={true}
                  name="applicationRemarks"
                  className="form-control input-group textarea-description reject-reason inputcontrasts"
                  placeholder="Add your reason for rejection here"
                  autoFocus
                />
              </Box>
            </Typography>
            <Grid item className="d-flex align-center justify-end mt-20 pr-5">
              <Typography
                onClick={handleCloseRejectReason}
                className="f-16 sub-text-color font-weight-600 mr-30 cursor-hover"
              >
                No
              </Typography>
              <Typography
                onClick={handleOpenRejectionReason}
                onKeyPress={handleOpenRejectionReason}
                className="f-16 brand-color font-weight-600 cursor-hover"
              >
                Yes
              </Typography>
            </Grid>
          </Typography>
        </Box>
      </Modal>
      {/*Terminate Job*/}
      <Modal
        open={openConfirmActionTerminateJob}
        onClose={handleCloseTerminateJob}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          className={`inputcontrasts ${
            status === "Z" ? "modal-content" : "modalStyle"
          }`}
        >
          <Typography className="align-left">
            <Typography id="modal-title" className="d-flex align-between">
              <Typography variant="h5" className="f-24 font-weight-600 heading-text-color">
                Terminate Contract
              </Typography>
              <Typography
                align="right"
                className="cursor-hover sub-text-color icon-button"
              >
                <CloseIcon
                  onClick={handleCloseTerminateJob}
                  onKeyPress={handleCloseTerminateJob}
                  aria-label="Close icon"
                  tabIndex={0}
                />
              </Typography>
            </Typography>
            <Typography id="modal-description">
              {status === "Z" ? (
                <>
                  <Typography>
                    <Box className="f-16 font-weight-400">
                      Do you want to terminate this candidate’s contract?
                    </Box>
                    {currentOption &&
                    <Box className="f-14 font-weight-400 sub-text-color mt-5">
                      Note: Once you terminate the contract, the candidate will
                      be released to shared pool
                    </Box>}
                  </Typography>
                  <Typography className="switch-case-section d-flex align-center mt-20">
                    <Switch
                      checked={currentOption}
                      onChange={handleOptionChange}
                      inputProps={{ "aria-label": "controlled" }}
                    />
                    <Box className="f-14 font-weight-400 ml-10">
                      Release to Shared Talent Pool
                    </Box>
                  </Typography>
                  <Box className="f-12 font-weight-400 sub-text-color mt-10 d-flex">
                    <InfoIcon
                      aria-label="Info icon"
                      className="sub-text-color mr-5"
                    />
                    {/* <img src={Infoicon} alt="info icon" className="mr-5" /> */}
                    By enabling shared talent pool, your exclusive talent will
                    be shared with other enterprises in the platform
                  </Box>
                </>
              ) : (
                <Typography>
                  <Box className="f-16 font-weight-400">Are you sure?</Box>
                </Typography>
              )}
            </Typography>
            <Grid item className="d-flex align-center justify-end mt-20 pr-5">
              <Typography
                onClick={handleCloseTerminateJob}
                className="f-16 sub-text-color font-weight-600 mr-30 cursor-hover"
              >
                No
              </Typography>
              <Typography
                onClick={handleTerminateOnboard}
                onKeyPress={handleTerminateOnboard}
                className="f-16 brand-color font-weight-600 cursor-hover"
              >
                Yes
              </Typography>
            </Grid>
          </Typography>
        </Box>
      </Modal>

      <ConfirmDialog
        open={openConfirmActionCompleteJob}
        setOpen={setOpenConfirmActionCompleteJob}
        dialogTitle="Complete Job"
        description={"Are you sure?"}
        handleConfirmYes={() => handleCompleteJob()}
      />

      <Modal
        open={openShortlistRemarks}
        onClose={handleCloseShortlistRemarks}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box className="modal-content inputcontrasts">
          <Typography className="align-left">
            <Typography id="modal-title" className="d-flex align-between">
              <Typography variant="h5" className="f-24 font-weight-600 heading-text-color">
                Shortlisted Remarks
              </Typography>
            </Typography>
            <Typography id="modal-description">
              <Box>
                <p className="sub-text-font mb-0 font-weight-500" align="left">
                  {candidateApplicationData?.shortlistRemarks}
                </p>
              </Box>
            </Typography>
            <Typography className="justify-center d-flex mt-20">
              <Button
                onClick={handleCloseShortlistRemarks}
                className="btn primary-button"
                disableRipple="true"
              >
                Close
              </Button>
            </Typography>
          </Typography>
        </Box>
      </Modal>

      {/*Rate Candidate*/}
      <Modal
        open={OpenRatingCandidate && !activeModal}
        onClose={(e) => handleCloseRating()}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box className="modal-content inputcontrasts">
          <Typography className="align-left notification-align">
            <Typography id="modal-title" className="d-flex align-between">
              <Typography variant="h5" className="f-20 font-weight-600 heading-text-color">
                Rate your experience with {candidateApplication?.firstName}{" "}
                {candidateApplication?.lastName} to complete this job
              </Typography>
              <Typography
                align="right"
                className="cursor-hover sub-text-color icon-button"
              >
                <CloseIcon
                  onClick={handleCloseRating}
                  onKeyPress={handleCloseRating}
                  aria-label="Close icon"
                  tabIndex={0}
                />
              </Typography>
            </Typography>
            <Typography>
              {status === "Z" && (
                <>
                  <Grid item lg={12} md={12} xs={12} className="mt-15">
                    <Typography className="switch-case-section d-flex align-center">
                      <Switch />
                      <Box className="ml-10">Release to Shared Talent Pool</Box>
                    </Typography>
                    <Box className="f-12 font-weight-400 sub-text-color mt-10 d-flex">
                      {/* <img src={Infoicon} alt="info icon" className="mr-5" /> */}
                      <InfoIcon
                        aria-label="Info icon"
                        className="sub-text-color mr-5"
                      />
                      By enabling shared talent pool, your exclusive talent will
                      be shared with other enterprises in the platform
                    </Box>
                  </Grid>
                </>
              )}
            </Typography>
            <Grid id="modal-description">
              <Grid container item spacing={1} className="mt-5">
                <Grid item lg={12} md={12} xs={12}>
                  <Box>
                    <Typography className="font-weight-500 f-16 text-black">
                      Overall Rating<span className="text-black">*</span>
                    </Typography>
                    <Rating
                      count={5}
                      onChange={(e, newValue) =>
                        ratingChanged(newValue, "rating_overall")
                      }
                      value={ratings.rating_overall}
                      name="half-rating"
                      icon={
                        <img
                          src={starFilled}
                          style={{ width: "25px", height: "25px" }}
                          alt="img"
                          tabIndex={0}
                        />
                      }
                      emptyIcon={
                        <img
                          src={starEmpty}
                          style={{ width: "25px", height: "25px" }}
                          alt="img"
                          tabIndex={0}
                        />
                      }
                      tabIndex={0}
                    />
                  </Box>
                </Grid>

                <Grid item lg={12} md={12} xs={12}>
                  <Box>
                    <Typography className="font-weight-500 f-16">
                      Delivery Quality
                    </Typography>
                    <Rating
                      count={5}
                      onChange={(e, newValue) =>
                        ratingChanged(newValue, "ratingDeliveryQuality")
                      }
                      value={ratings.ratingDeliveryQuality}
                      name="half-rating"
                      icon={
                        <img
                          src={starFilled}
                          style={{ width: "25px", height: "25px" }}
                          alt="img"
                          tabIndex={0}
                        />
                      }
                      emptyIcon={
                        <img
                          src={starEmpty}
                          style={{ width: "25px", height: "25px" }}
                          alt="img"
                          tabIndex={0}
                        />
                      }
                      tabIndex={0}
                    />
                  </Box>
                </Grid>

                <Grid item lg={12} md={12} xs={12}>
                  <Box>
                    <Typography className="font-weight-500 f-16">
                      Schedule Adherence
                    </Typography>
                    <Rating
                      count={5}
                      onChange={(e, newValue) =>
                        ratingChanged(newValue, "ratingScheduleAdherence")
                      }
                      value={ratings.ratingScheduleAdherence}
                      name="half-rating"
                      icon={
                        <img
                          src={starFilled}
                          style={{ width: "25px", height: "25px" }}
                          alt="img"
                          tabIndex={0}
                        />
                      }
                      emptyIcon={
                        <img
                          src={starEmpty}
                          style={{ width: "25px", height: "25px" }}
                          alt="img"
                          tabIndex={0}
                        />
                      }
                      tabIndex={0}
                    />
                  </Box>
                </Grid>

                <Grid item lg={12} md={12} xs={12}>
                  <Box>
                    <Typography className="font-weight-500 f-16">
                      Sticking to Commitment
                    </Typography>
                    <Rating
                      count={5}
                      onChange={(e, newValue) =>
                        ratingChanged(newValue, "ratingStickingToCommitment")
                      }
                      value={ratings.ratingStickingToCommitment}
                      name="half-rating"
                      icon={
                        <img
                          src={starFilled}
                          style={{ width: "25px", height: "25px" }}
                          alt="img"
                          tabIndex={0}
                        />
                      }
                      emptyIcon={
                        <img
                          src={starEmpty}
                          style={{ width: "25px", height: "25px" }}
                          alt="img"
                          tabIndex={0}
                        />
                      }
                      tabIndex={0}
                    />
                  </Box>
                </Grid>

                <Grid item lg={12} md={12} xs={12}>
                  <Box>
                    <Typography className="font-weight-500 f-16">
                      Value for Money
                    </Typography>
                    <Rating
                      count={5}
                      onChange={(e, newValue) =>
                        ratingChanged(newValue, "ratingValueForMoney")
                      }
                      value={ratings.ratingValueForMoney}
                      name="half-rating"
                      icon={
                        <img
                          src={starFilled}
                          style={{ width: "25px", height: "25px" }}
                          alt="img"
                          tabIndex={0}
                        />
                      }
                      emptyIcon={
                        <img
                          src={starEmpty}
                          style={{ width: "25px", height: "25px" }}
                          alt="img"
                          tabIndex={0}
                        />
                      }
                      tabIndex={0}
                    />
                  </Box>
                </Grid>
                <Grid item lg={12} md={12} xs={12}>
                  <Box className="review-candidate">
                    <Typography className="font-weight-500 f-16 mt-10">
                      Your Review
                    </Typography>
                    <TextField
                      variant="outlined"
                      aria-label="minimum height"
                      multiline
                      rows={5}
                      m={10}
                      type="text"
                      margin="normal"
                      // autoFocus={true}
                      name="applicationRemarks"
                      className={`form-control input-group textarea-description inputcontrasts ${
                        reviewLenError ? "is-invalid" : ""
                      }`}
                      placeholder="Enter your review about the candidate."
                      onChange={(e) =>
                        ratingChanged(
                          e.target.value,
                          "ratingRemarksByEnterprise"
                        )
                      }
                      value={ratings.ratingRemarksByEnterprise}
                      tabIndex={0}
                    />
                    <span className="danger-color error-msg">
                      {" "}
                      {reviewLenError &&
                        "Exceeded maximum character length of 256"}
                    </span>
                  </Box>
                </Grid>
              </Grid>
            </Grid>

            <Grid
              item
              id="modal-footer"
              className="justify-center d-flex mt-20"
            >
              <Typography className="pr-30 align-item-center">
                <span
                  className="f-14 brand-color-link font-weight-600 cursor-hover"
                  onClick={() => handleCloseCompleteJob()}
                  tabIndex={0}
                  onKeyPress={() => handleCloseCompleteJob()}
                >
                  Skip and complete job
                </span>
              </Typography>

              <Button
                type="submit"
                className="btn primary-button profileButton ml-5"
                variant="contained"
                onClick={() => handleCompleteJob()}
                disabled={
                  ratings.rating_overall && !reviewLenError ? false : true
                }
                tabIndex={0}
                disableRipple="true"
              >
                Submit
              </Button>
            </Grid>
          </Typography>
        </Box>
      </Modal>
      <ConfirmDialog
        open={openDocumentIndex}
        setOpen={setOpenDocumentIndex}
        dialogTitle="Delete Document "
        description={"Are you sure?"}
        handleConfirmYes={deleteDocumentFromIndex}
      />
      <ExclusiveWorkPermissionModal
        openExclusiveWorkPermissionModal={openExclusiveWorkPermissionModal}
        setOpenExclusiveWorkPermissionModal={setOpenExclusiveWorkPermissionModal}
        action={restrictedAction}
        jobDetails={props.jobDetails}
      />
<OfferLetter
        setIsLoading={!props.status ? props.setIsLoading: props.parentLoading}
        setViewOfferDrawer={setViewOfferDrawer}
        viewOfferDrawer={viewOfferDrawer}
        jobId={params.job_id}
        candidateApplicationData={candidateApplicationData}
        jobStartDate={jobStartDate}
        jobCompletionDate={jobCompletionDate}
        payRate={payRate}
        expiryDate={values}
        currency={currency}
        updatedstatus={props.updatedstatus}
        candidate={getCandidateId}
        status={!props.status ? params.status : props.status}
        getCandidatesByStatus={getCandidatesByStatus}
        isFromMatchingCandidates={true}
        resendOfferHeading={resendOfferHeading}
        setResendOfferHeading={setResendOfferHeading}
        isDetailsPage={true}
        closeMatchingCandidateDrawer={props.closeMatchingCandidateDrawer}
        setIsDrawerVisible={getDrawer}
        sendOfferEmailChecked={sendOfferEmailChecked}
      />
    </>
  );
}
